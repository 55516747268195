import { useAccountSelector } from 'src/hooks';
import { FontWeight } from 'src/styles';
import { getAccountFundingInstructionsTitle } from 'src/utils';

export const DepositByWireInstructions = () => {
  const { account } = useAccountSelector();

  return (
    <>
      <p>
        Incoming wires are free and must be initiated with your financial institution. Wire transfers typically require
        1 business day to process. Please instruct your bank to include the sender name on the wire. This information
        must be included for the transfer to be credited to your account. A rejected wire may incur a bank fee.
      </p>

      <strong>Third-party Wires:</strong>
      <p>
        <span className={FontWeight.bold}>My IPO does not accept “third party” funding of any kind</span>. This means
        that the name on your bank account must match the name on your My IPO account exactly. For example, money
        transfers from a trust or business bank account (owned by you) to an individual or joint My IPO account are
        considered third-party and <span className={FontWeight.bold}>will not be accepted.</span>
      </p>

      <strong>Please send USD incoming wires as follows:</strong>
      <p>
        <span>BMO Harris Bank</span> <br />
        <span>111 W. Monroe St.</span> <br />
        <span>Chicago, IL 60603</span> <br />
      </p>

      <p>
        <span>ABA: 071000288 (for non-domestic USD wires, use SWIFT Code: HATRUS44)</span>
        <br />
        <span>Acct: 3713286</span>
        <br />
        <span>FBO: Apex Clearing Corporation</span>
        <br />
        <span>FCC: {getAccountFundingInstructionsTitle(account)}</span>
        <br />
      </p>

      <strong>IMPORTANT NOTE:</strong>
      <p>
        Without FFC Information, your wire may be rejected. If your bank does not have a specific FFC instruction field,
        enter the Account Holder’s Name and Account Number in the &quot;Message to recipient&quot; or &quot;Message to
        recipient bank&quot; field.
      </p>

      <strong>Please include the address for Apex Clearing on incoming wires:</strong>
      <p>
        <span>Apex Clearing Corporation</span>
        <br />
        <span>350 N St Paul, Ste. 1400</span>
        <br />
        <span>Dallas, TX 75201</span>
        <br />
      </p>
    </>
  );
};
