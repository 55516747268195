import { AccountBeneficiaryDto, AccountTypeDto, JointAccountTypeDto, RetirementAccountTypeDto } from 'src/dtos';
import { Maybe } from 'src/utils';

import { AccountHolder } from './accountHolders.models';

export enum AccountTypeLabel {
  Individual = 'Individual',
  Retirement = 'Retirement',
  Joint = 'Joint',
}

export class AccountType {
  private _value: AccountTypeDto;
  private _label: AccountTypeLabel;

  constructor(type: AccountTypeDto) {
    this._value = type;
    this._label = this.findLabel();
  }

  private findLabel(): AccountTypeLabel {
    switch (this._value) {
      case AccountTypeDto.Individual:
        return AccountTypeLabel.Individual;
      case AccountTypeDto.Joint:
        return AccountTypeLabel.Joint;
      case AccountTypeDto.Retirement:
        return AccountTypeLabel.Retirement;
    }
  }

  get value(): AccountTypeDto {
    return this._value;
  }

  get label(): AccountTypeLabel {
    return this._label;
  }

  get isIndividual(): boolean {
    return this._value === AccountTypeDto.Individual;
  }

  get isJoint(): boolean {
    return this._value === AccountTypeDto.Joint;
  }

  get isRetirement(): boolean {
    return this._value === AccountTypeDto.Retirement;
  }
}

export enum JointAccountTypeLabel {
  Community = 'Community Property',
  Survivor = 'Rights of Survivorship',
  TenantsInCommon = 'Tenants in Common',
  TenantsInEntirety = 'Tenants in Entirety',
}

export class JointAccountType {
  private _value?: JointAccountTypeDto;
  private _label?: JointAccountTypeLabel;

  constructor(type?: JointAccountTypeDto) {
    this._value = type;
    this._label = this.findLabel();
  }

  private findLabel(): Maybe<JointAccountTypeLabel> {
    switch (this._value) {
      case JointAccountTypeDto.Community:
        return JointAccountTypeLabel.Community;
      case JointAccountTypeDto.Survivor:
        return JointAccountTypeLabel.Survivor;
      case JointAccountTypeDto.TenantsInCommon:
        return JointAccountTypeLabel.TenantsInCommon;
      case JointAccountTypeDto.TenantsInEntirety:
        return JointAccountTypeLabel.TenantsInEntirety;
    }
  }

  get value(): Maybe<JointAccountTypeDto> {
    return this._value;
  }

  get label(): Maybe<JointAccountTypeLabel> {
    return this._label;
  }

  get isCommunity(): boolean {
    return this._value === JointAccountTypeDto.Community;
  }

  get isSurvivor(): boolean {
    return this._value === JointAccountTypeDto.Survivor;
  }

  get isTenantsInCommon(): boolean {
    return this._value === JointAccountTypeDto.TenantsInCommon;
  }

  get isTenantsInEntirety(): boolean {
    return this._value === JointAccountTypeDto.TenantsInEntirety;
  }
}

export enum RetirementAccountTypeLabel {
  Traditional = 'Traditional IRA',
  Rollover = 'IRA Rollover',
  Roth = 'Roth IRA',
  SEP = 'SEP IRA',
  Simple = 'Simple IRA',
  Beneficiary = 'Beneficiary IRA',
  CoverDell = 'Coverdell IRA',
  RothBeneficiary = 'Roth Beneficiary IRA',
}

export class RetirementAccountType {
  private _value?: RetirementAccountTypeDto;
  private _label?: RetirementAccountTypeLabel;

  constructor(type?: RetirementAccountTypeDto) {
    this._value = type;
    this._label = this.findLabel();
  }

  private findLabel(): Maybe<RetirementAccountTypeLabel> {
    switch (this._value) {
      case RetirementAccountTypeDto.Traditional:
        return RetirementAccountTypeLabel.Traditional;
      case RetirementAccountTypeDto.Simple:
        return RetirementAccountTypeLabel.Simple;
      case RetirementAccountTypeDto.SEP:
        return RetirementAccountTypeLabel.SEP;
      case RetirementAccountTypeDto.CoverDell:
        return RetirementAccountTypeLabel.CoverDell;
      case RetirementAccountTypeDto.Roth:
        return RetirementAccountTypeLabel.Roth;
      case RetirementAccountTypeDto.Rollover:
        return RetirementAccountTypeLabel.Rollover;
      case RetirementAccountTypeDto.RothBeneficiary:
        return RetirementAccountTypeLabel.RothBeneficiary;
      case RetirementAccountTypeDto.Beneficiary:
        return RetirementAccountTypeLabel.Beneficiary;
    }
  }

  get value(): Maybe<RetirementAccountTypeDto> {
    return this._value;
  }

  get label(): Maybe<RetirementAccountTypeLabel> {
    return this._label;
  }

  get isSimple(): boolean {
    return this._value === RetirementAccountTypeDto.Simple;
  }

  get isBeneficiary(): boolean {
    return this._value === RetirementAccountTypeDto.Beneficiary;
  }

  get isRoth(): boolean {
    return this._value === RetirementAccountTypeDto.Roth;
  }

  get isRothBeneficiary(): boolean {
    return this._value === RetirementAccountTypeDto.RothBeneficiary;
  }

  get isRollover(): boolean {
    return this._value === RetirementAccountTypeDto.Rollover;
  }
}

export interface Account {
  id: string;
  type: AccountType;
  accountId?: number;
  accountNumber?: string;
  primaryAccountHolder?: AccountHolder;
  secondaryAccountHolder?: AccountHolder;
  jointAccountType?: JointAccountType;
  jointCommunityPropertyState?: { label?: string; value: string };
  retirementAccountType?: RetirementAccountType;
  simpleIraPlanName?: string;
  beneficiaryIraDecedentName?: string;
  beneficiaries: Array<AccountBeneficiaryDto>;
}
