import {
  OfferOrderRefundDto,
  OfferOrderDetailsDto,
  OfferOrderDto,
  OfferOrderStatusDto,
} from '../dtos/offerOrders.dtos';
import {
  OfferOrder,
  OfferOrderCardPaymentIntentStatus,
  OfferOrderRefund,
  OfferOrderRefundMechanism,
  OfferOrderRefundReason,
  OfferOrderRefundStatus,
  OfferOrderDetails,
  OfferOrderPaymentType,
  OfferOrderStatus,
  OfferOrderRetirementAccountFundingType,
} from '../models/offer-orders.model';

export const mapOfferOrderRefundDtoListToModel = (list: OfferOrderRefundDto[]): OfferOrderRefund[] =>
  list.map(aDto => ({
    id: aDto.id,
    externalId: aDto.externalId,
    amount: aDto.amount,
    reason: new OfferOrderRefundReason(aDto.reason),
    status: new OfferOrderRefundStatus(aDto.status),
    description: aDto.description,
    failureReason: aDto.failureReason,
    mechanism: new OfferOrderRefundMechanism(aDto.mechanism),
    bankName: aDto.bankName,
    createdAt: aDto.createdAt,
    updatedAt: aDto.updatedAt,
  }));

export const mapOfferOrderDtoToModel = (dto: OfferOrderDto): OfferOrder => ({
  key: dto.id,
  id: dto.id,
  accountId: dto.accountId,
  offerName: dto.offerName,
  offerId: dto.offerId,
  offerCloseDate: dto.offerCloseDate,
  quantity: dto.quantity,
  purchasePrice: dto.price,
  isExternal: dto.isExternal,
  isAccreditedInvestor: dto.isAccreditedInvestor,
  paymentType: new OfferOrderPaymentType(dto.paymentType),
  value:
    dto.conditional && ![OfferOrderStatusDto.Approved, OfferOrderStatusDto.Complete].includes(dto.status)
      ? dto.conditional.totalInvestment
      : dto.totalCost,
  status: new OfferOrderStatus(dto.status),
  amountRefunded: dto.amountRefunded,
  refunds: mapOfferOrderRefundDtoListToModel(dto.refunds),
  cardPayment: dto.cardPayment
    ? {
        ...dto.cardPayment,
        intentStatus: new OfferOrderCardPaymentIntentStatus({
          status: dto.cardPayment.intentStatus,
          totalCost: dto.totalCost,
          amountRefunded: dto.cardPayment.amountRefunded,
        }),
      }
    : undefined,
  conditional: dto.conditional
    ? {
        minSharePrice: dto.conditional.minSharePrice,
        maxSharePrice: dto.conditional.maxSharePrice,
        totalInvestment: dto.conditional.totalInvestment,
      }
    : undefined,
  externalDetails: dto.externalDetails,
  retirementAccountFundingType: dto.retirementAccountFundingType
    ? new OfferOrderRetirementAccountFundingType(dto.retirementAccountFundingType)
    : undefined,
  localeDate: dto.localeDate,
  createdAt: dto.createdAt,
  updatedAt: dto.updatedAt,
});

export const mapOfferOrderDetailsDtoToModel = (dto: OfferOrderDetailsDto): OfferOrderDetails => ({
  ...mapOfferOrderDtoToModel(dto),
  paymentInstructions: dto.paymentInstructions,
  analytics: dto.analytics,
});

export const mapGetOfferOrdersResponseDtoToModel = (dtos: OfferOrderDto[]): OfferOrder[] =>
  dtos.map(mapOfferOrderDtoToModel);
