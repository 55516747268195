import { RetirementAccountTypeDto } from 'src/dtos';

interface AccountTypeDescriptionProps {
  value?: RetirementAccountTypeDto;
}

export const RetirementAccountTypeDescription = ({ value }: AccountTypeDescriptionProps) => {
  if (value === RetirementAccountTypeDto.Traditional) {
    return (
      <>
        For individuals to invest tax-deferred earned income annually until the owner turns 70½ years old. Distributions
        from an IRA can be taken without penalty starting at age 59½, although they are taxable. Distributions must
        begin by the April 1st once the account owner has reached 70½ years of age.
      </>
    );
  }

  if (value === RetirementAccountTypeDto.Rollover) {
    return (
      <>
        Open a Rollover IRA for funds distributed from a previous employer&apos;s qualified retirement plan [i.e. a
        401(k) or 403(b)]. A Rollover IRA allows you to return the funds to another qualified retirement plan at some
        time in the future.
      </>
    );
  }

  if (value === RetirementAccountTypeDto.Roth) {
    return (
      <>
        For individuals building after-tax retirement assets to receive qualified withdrawals tax-free (federal). After
        5 years, tax-free withdrawals can be made if the owner turns 59½, becomes disabled or dies; up to $10,000 can be
        used for first-time home purchase
      </>
    );
  }

  if (value === RetirementAccountTypeDto.SEP) {
    return (
      <>
        For self-employed individuals and small companies and their qualified employees. Contribution and compensation
        caps may apply.
      </>
    );
  }

  if (value === RetirementAccountTypeDto.Simple) {
    return (
      <>
        For small business owners establishing a beneficial savings or pension plan for the exclusive benefit of
        employees or their beneficiaries.
      </>
    );
  }

  if (value === RetirementAccountTypeDto.Beneficiary) {
    return (
      <>
        Also known as an &quot;Inherited IRA&quot;, this account type is opened when an individual inherits an IRA or
        employer-sponsored retirement plan after the original owner dies. Additional contributions are not allowed, and
        specific tax laws apply regarding distributions.
      </>
    );
  }

  if (value === RetirementAccountTypeDto.CoverDell) {
    return (
      <>
        A tax-advantaged investment account to help cover education expenses, including tuition, books, and related
        expenses. Distributions are limited to same-year expenses only.
      </>
    );
  }

  if (value === RetirementAccountTypeDto.RothBeneficiary) {
    return (
      <>
        An inherited account distributing a deceased individual&apos;s Roth IRA assets tax-free to beneficiaries for any
        purpose, including education. Distributions follow IRS guidelines, as a lump sum or spread over life expectancy.
      </>
    );
  }

  return null;
};
