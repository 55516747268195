import { useEffect, useState } from 'react';

import { Col, Collapse, Row } from 'antd';
import { isUndefined } from 'lodash';
import { useSelector } from 'react-redux';
import { OfferConstant } from 'src/constants';
import { OfferOrderPaymentTypeDto, OfferOrderRetirementAccountFundingTypeDto } from 'src/dtos';
import { useAccountSelector } from 'src/hooks';
import { SecuritiesHeldAndFundedOutsideDisclaimerModal } from 'src/lib';
import { OfferOrderIntent } from 'src/models';
import { FontWeight } from 'src/styles';

import { AcatPayment } from './AcatPayment';
import { AchPayment } from './AchPayment';
import { CardPayment } from './CardPayment';
import { CheckPayment } from './CheckPayment';
import { IraContributionPayment } from './IraContributionPayment';
import { PaymentTypeHeader } from './PaymentTypeHeader';
import * as Styles from './PaymentTypeSelector.styles';
import { WirePayment } from './WirePayment';

const { Panel } = Collapse;

export interface PaymentTypeSelectorProps {
  value?: OfferOrderPaymentTypeDto | OfferOrderRetirementAccountFundingTypeDto;
  isValid: (value: boolean) => void;
  onChange: (
    value?: OfferOrderPaymentTypeDto | OfferOrderRetirementAccountFundingTypeDto,
    meta?: {
      iraContributionPaymentType?: OfferOrderPaymentTypeDto;
    },
  ) => void;
}

export const PaymentTypeSelector = ({ isValid, onChange, value }: PaymentTypeSelectorProps) => {
  const offerOrderIntent: OfferOrderIntent = useSelector((state: any) => state.offerOrders.intent);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [selectedPaymentTypeDisclaimer, setSelectedPaymentTypeDisclaimer] = useState<Exclude<
    OfferOrderPaymentTypeDto,
    OfferOrderPaymentTypeDto.Ach
  > | null>(null);

  const { account } = useAccountSelector();
  const shouldAcceptInvestmentByAch = () =>
    !account?.type.isRetirement && (offerOrderIntent.isExternal ? false : offerOrderIntent.allowAchTransfers);

  const shouldAcceptInvestmentByWire = () => !account?.type.isRetirement && offerOrderIntent.allowWireTransfers;

  const shouldAcceptInvestmentByCheck = () =>
    !account?.type.isRetirement &&
    (offerOrderIntent.isExternal
      ? !isUndefined(offerOrderIntent.checkInstructions) && offerOrderIntent.allowCheckTransfers
      : offerOrderIntent.allowCheckTransfers);

  const shouldAcceptInvestmentByCard = () =>
    !account?.type.isRetirement &&
    offerOrderIntent.allowCardPayments &&
    offerOrderIntent.cardPaymentAccountStatus &&
    !offerOrderIntent.isConditional &&
    OfferConstant.ACCEPTED_CARD_PAYMENT_ACCOUNT_STATUS_LIST.includes(offerOrderIntent.cardPaymentAccountStatus);

  const shouldAcceptIraContribution = () =>
    offerOrderIntent.account.type.isRetirement &&
    (offerOrderIntent.allowIraContributionCheckTransfers || offerOrderIntent.allowIraContributionWireTransfers);

  const shouldAcceptAcatTransfer = () =>
    offerOrderIntent.account.type.isRetirement && offerOrderIntent.allowAcatTransfers;

  const getDefaultValue = () => {
    if (shouldAcceptInvestmentByAch()) {
      return OfferOrderPaymentTypeDto.Ach;
    }

    if (shouldAcceptInvestmentByWire()) {
      return OfferOrderPaymentTypeDto.Wire;
    }

    if (shouldAcceptInvestmentByCheck()) {
      return OfferOrderPaymentTypeDto.Check;
    }

    if (shouldAcceptInvestmentByCard()) {
      return OfferOrderPaymentTypeDto.Card;
    }

    if (shouldAcceptIraContribution()) {
      return OfferOrderRetirementAccountFundingTypeDto.IraContribution;
    }

    if (shouldAcceptAcatTransfer()) {
      return OfferOrderPaymentTypeDto.Acat;
    }
  };

  const getIraContributionDefaultPaymentType = () => {
    if (offerOrderIntent.allowIraContributionCheckTransfers) {
      return OfferOrderPaymentTypeDto.Check;
    }

    if (offerOrderIntent.allowIraContributionWireTransfers) {
      return OfferOrderPaymentTypeDto.Wire;
    }
  };

  const getDefaultActiveKeyIfExists = () => {
    if (value) {
      return [value];
    }

    const defaultValue = getDefaultValue();

    if (defaultValue) {
      return [defaultValue];
    }

    return;
  };

  const onPaymentTypeChange = (key?: string | string[]) => {
    if (!key) {
      return;
    }
    onChange(key as OfferOrderPaymentTypeDto, { iraContributionPaymentType: getIraContributionDefaultPaymentType() });
  };

  const onViewMoreWire = () => {
    setSelectedPaymentTypeDisclaimer(OfferOrderPaymentTypeDto.Wire);
    setIsVisible(true);
  };

  const onViewMoreCheck = () => {
    setSelectedPaymentTypeDisclaimer(OfferOrderPaymentTypeDto.Check);
    setIsVisible(true);
  };

  const onViewMoreCard = () => {
    setSelectedPaymentTypeDisclaimer(OfferOrderPaymentTypeDto.Card);
    setIsVisible(true);
  };

  useEffect(() => {
    if (!value) {
      onChange(getDefaultValue(), { iraContributionPaymentType: getIraContributionDefaultPaymentType() });
    }
  }, []);

  return (
    <>
      <Row>
        <Col span={24} data-testid={'offer-order-payment'}>
          <span className={Styles.title}>Payment</span>
        </Col>
        <Col span={24}>
          <span className={Styles.subtitle}>All transactions are secure and encrypted.</span>
        </Col>
        <Col span={24}>
          <p className={Styles.subtitle}>
            {offerOrderIntent.isExternal ? (
              <>
                <span className={FontWeight.bold}>
                  The Escrow Agent does not accept “third party” funding of any kind
                </span>
                . This means that the name on your bank account must match the name on your investment exactly. For
                example, money transfers from a trust or business bank account (owned by you) to an individual or joint
                investment name are considered third-party and{' '}
                <span className={FontWeight.bold}>will not be accepted.</span>
              </>
            ) : (
              <>
                <span className={FontWeight.bold}>My IPO does not accept “third party” funding of any kind</span>. This
                means that the name on your bank account must match the name on your My IPO account exactly. For
                example, money transfers from a trust or business bank account (owned by you) to an individual or joint
                My IPO account are considered third-party and{' '}
                <span className={FontWeight.bold}>will not be accepted.</span>
              </>
            )}
          </p>
        </Col>
      </Row>
      <Collapse
        defaultActiveKey={getDefaultActiveKeyIfExists()}
        onChange={onPaymentTypeChange}
        accordion
        destroyInactivePanel
        className={Styles.paymentCollapse}>
        {shouldAcceptInvestmentByAch() && (
          <Panel
            className={Styles.paymentPanel}
            showArrow={false}
            header={
              <PaymentTypeHeader
                checked={value === OfferOrderPaymentTypeDto.Ach}
                title='ACH (Bank-to-Bank)'
                subtitle='Instantly connect and no fees'
                dataTestId={'confirm-order-payment-ach'}
              />
            }
            key={OfferOrderPaymentTypeDto.Ach}>
            <AchPayment isValid={isValid} />
          </Panel>
        )}
        {shouldAcceptInvestmentByWire() && (
          <Panel
            collapsible={account?.accountNumber ? 'header' : 'disabled'}
            className={Styles.paymentPanel}
            showArrow={false}
            header={
              <PaymentTypeHeader
                checked={value === OfferOrderPaymentTypeDto.Wire}
                title='Wire transfer'
                subtitle='Initiated from your bank and fees may apply'
                disclaimer={offerOrderIntent.isExternal ? 'Securities held & funded outside My IPO account.' : null}
                onDisclaimer={onViewMoreWire}
                dataTestId={'confirm-order-payment-wire'}
              />
            }
            key={OfferOrderPaymentTypeDto.Wire}>
            <WirePayment isValid={isValid} />
          </Panel>
        )}
        {shouldAcceptInvestmentByCheck() && (
          <Panel
            collapsible={account?.accountNumber ? 'header' : 'disabled'}
            className={Styles.paymentPanel}
            showArrow={false}
            header={
              <PaymentTypeHeader
                title='Check transfer'
                checked={value === OfferOrderPaymentTypeDto.Check}
                onDisclaimer={onViewMoreCheck}
                disclaimer={offerOrderIntent.isExternal ? 'Securities held & funded outside My IPO account.' : null}
                dataTestId={'confirm-order-payment-check'}
              />
            }
            key={OfferOrderPaymentTypeDto.Check}>
            <CheckPayment isValid={isValid} />
          </Panel>
        )}
        {shouldAcceptInvestmentByCard() && (
          <Panel
            collapsible={account?.accountNumber ? 'header' : 'disabled'}
            className={Styles.paymentPanel}
            showArrow={false}
            header={
              <PaymentTypeHeader
                title='Card Payment'
                checked={value === OfferOrderPaymentTypeDto.Card}
                disclaimer='Securities held & funded outside My IPO account.'
                onDisclaimer={onViewMoreCard}
                dataTestId={'confirm-order-payment-card'}
              />
            }
            key={OfferOrderPaymentTypeDto.Card}>
            <CardPayment isValid={isValid} />
          </Panel>
        )}
        {shouldAcceptIraContribution() && (
          <Panel
            collapsible={account?.accountNumber ? 'header' : 'disabled'}
            className={Styles.paymentPanel}
            showArrow={false}
            header={
              <PaymentTypeHeader
                title='IRA Contribution'
                checked={value === OfferOrderRetirementAccountFundingTypeDto.IraContribution}
                dataTestId={'confirm-order-ira-contribution'}
              />
            }
            key={OfferOrderRetirementAccountFundingTypeDto.IraContribution}>
            <IraContributionPayment isValid={isValid} defaultPaymentType={getIraContributionDefaultPaymentType()} />
          </Panel>
        )}
        {shouldAcceptAcatTransfer() && (
          <Panel
            collapsible={account?.accountNumber ? 'header' : 'disabled'}
            className={Styles.paymentPanel}
            showArrow={false}
            header={
              <PaymentTypeHeader
                title='Transfer from another IRA Account'
                checked={value === OfferOrderPaymentTypeDto.Acat}
                dataTestId={'confirm-order-acat-transfer'}
              />
            }
            key={OfferOrderPaymentTypeDto.Acat}>
            <AcatPayment isValid={isValid} />
          </Panel>
        )}
      </Collapse>
      {selectedPaymentTypeDisclaimer && (
        <SecuritiesHeldAndFundedOutsideDisclaimerModal
          type={selectedPaymentTypeDisclaimer}
          isVisible={isVisible}
          onClose={() => setIsVisible(false)}
          onOK={() => setIsVisible(false)}
        />
      )}
    </>
  );
};
