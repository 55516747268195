import * as Styles from './ClosedOffersTag.styles';

export const ClosedOffersTag = () => {
  return (
    <div className={Styles.closedOfferBadge}>
      <p className={Styles.closedOfferTitle}>*Closed Offers</p>
      <p>
        Closed Offer may include capital raised by other selling group members or placement agents, unless My IPO is the
        sole placement agent.
      </p>
      <p>All capital amounts above reflect the total amount each Company raised in their offering.</p>
      <p>
        Through December 31, 2024, offers were available through Cambria Capital, LLC dba My IPO. As of January 2, 2025,
        offers were available through AOS, Inc. dba My IPO, a FINRA member broker-dealer.
      </p>
    </div>
  );
};
