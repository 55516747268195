import { MFormCheckbox, MFormTextField } from 'src/lib';
import { AccountHolder } from 'src/models';

export interface MailingAddressViewProps {
  accountHolder?: AccountHolder;
  testId: string;
}

export const MailingAddressView = ({ accountHolder, testId }: MailingAddressViewProps) => {
  if (accountHolder?.mailingAddress?.sameAsPhysical) {
    return (
      <MFormCheckbox
        label='Mailing address is the same as my physical address'
        checked
        disabled
        testId={testId + '-account-mailing-as-physical'}
      />
    );
  }

  return (
    <>
      <MFormTextField
        label='Address Line 1'
        value={accountHolder?.mailingAddress?.address1}
        testId={`${testId}-account-mailing-address1`}
      />
      <MFormTextField
        label='Address Line 2 (Opt.)'
        value={accountHolder?.mailingAddress?.address2}
        testId={`${testId}-account-mailing-address2`}
      />
      <MFormTextField
        label='Country'
        value={accountHolder?.mailingAddress?.country?.label}
        testId={`${testId}-account-mailing-country`}
      />
      <MFormTextField
        label='City'
        value={accountHolder?.mailingAddress?.city}
        testId={`${testId}-account-mailing-city`}
      />
      <MFormTextField
        label='State'
        value={accountHolder?.mailingAddress?.state?.label}
        testId={`${testId}-account-mailing-state`}
      />
      <MFormTextField
        label='Postal Code'
        value={accountHolder?.mailingAddress?.postalCode}
        testId={`${testId}-account-mailing-postal-code`}
      />
    </>
  );
};
