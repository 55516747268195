import { Col, Row } from 'antd';
import { type NumberFormatValues, NumericFormat } from 'react-number-format';

import { MFormItemError } from '../MFormItemError/MFormItemError';

import * as Styles from './MCurrencyInput.styles';

export interface CurrencyInputProps {
  placeholder?: string;
  value?: string | number;
  defaultValue?: string;
  error?: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  className?: string;
  size?: 'small' | 'middle' | 'large';
  weight?: 'light' | 'medium';
  testId?: string;
}

export const CurrencyInput = ({
  placeholder,
  defaultValue,
  value,
  disabled,
  error,
  onChange,
  testId,
}: CurrencyInputProps) => {
  const _onChange = (input: NumberFormatValues) => {
    onChange(input.value);
  };

  return (
    <Row className={Styles.inputContainer}>
      <Col span={24}>
        <NumericFormat
          placeholder={placeholder}
          defaultValue={defaultValue}
          value={value}
          disabled={disabled}
          displayType='input'
          prefix='$'
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          allowNegative={false}
          onValueChange={_onChange}
          className={Styles.input}
          data-testid={testId + '-input'}
        />
      </Col>
      <Col span={24} data-testid={testId + '-' + 'error'}>
        <MFormItemError value={error} />
      </Col>
    </Row>
  );
};
