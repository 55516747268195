import { Col, Row } from 'antd';

import { MCheckboxGroupOption } from '../MCheckboxGroup/MCheckboxGroup';
import { MTooltip } from '../MTooltip/MTooltip';

import * as Styles from './MFormCheckboxGroupField.styles';

interface MFormCheckboxGroupItem {
  title: string;
  options: MCheckboxGroupOption[];
}

interface MFormCheckboxGroupFieldProps {
  label: string;
  groups: MFormCheckboxGroupItem[];
  tooltip?: {
    type: 'modal' | 'popover';
    title?: string;
    content?: string | JSX.Element;
  };
  testId?: string;
}

export const MFormCheckboxGroupField = ({ label, groups, tooltip, testId }: MFormCheckboxGroupFieldProps) => {
  const renderGroups = () => {
    return groups.map((aGroup: MFormCheckboxGroupItem) => {
      return (
        <Row key={aGroup.title} className={Styles.groupContainer}>
          <Col
            span={24}
            className={Styles.title}
            data-testid={aGroup.title.toLowerCase().replace(/ /g, '-') + '-label'}>
            {aGroup.title}
          </Col>
          {aGroup.options.map(option => (
            <Row key={option.value} className={Styles.option}>
              <Col span={10} data-testid={option.label.toLowerCase().replace(/ /g, '-') + '-label'}>
                {option.label}
              </Col>
              <Col
                span={14}
                className={Styles.optionValue}
                data-testid={option.label.toLowerCase().replace(/ /g, '-') + '-value'}>
                {option.value}
              </Col>
            </Row>
          ))}
        </Row>
      );
    });
  };

  return (
    <Row className={Styles.container}>
      <Col span={24} className={Styles.label} data-testid={testId + '-group-title'}>
        {label}
        {tooltip && (
          <MTooltip title={tooltip.title} type='popover' icon='info' showIcon>
            {tooltip.content}
          </MTooltip>
        )}
      </Col>
      {renderGroups()}
    </Row>
  );
};
