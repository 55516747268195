import { cx } from '@emotion/css';
import { Col, Row, Tabs } from 'antd';
import { isUndefined } from 'lodash';
import MediaQuery from 'react-responsive';
import { useLocation, useNavigate } from 'react-router';
import { useAccountSelector } from 'src/hooks';
import { MDivider } from 'src/lib/MDivider/MDivider';
import { AccountType, JointAccountType } from 'src/models';
import {
  isAccountAgreementsComplete,
  isAccountDisclosureComplete,
  isAccountFinancialInformationComplete,
  isAccountPersonalInformationComplete,
  isAccountSuitabilityInformationComplete,
  isAccountTrustedContactComplete,
} from 'src/utils';

import { Heading, ScreenBreakpoint, Spacing } from '../../../styles';
import { Logger } from '../../../utils/Logger';

import { MyAccountProgressBar } from './MyAccountProgressBar';
import * as Styles from './MyAccountSidebar.styles';

export enum MyAccountSidebarMainMenuItemKey {
  PersonalInformation = 'PersonalInformation',
  TrustedContact = 'TrustedContact',
  SuitabilityInformation = 'SuitabilityInformation',
  FinancialInformation = 'FinancialInformation',
  Disclosures = 'Disclosures',
  AccountAgrements = 'AccountAgreements',
  SignApplication = 'SignApplication',
}

export enum MyAccountSidebarOtherMenuItemKey {
  MoneyTransfers = 'MoneyTransfers',
  AccountStatus = 'AccountStatus',
  AccountStatements = 'AccountStatements',
  IndicationsOfInterest = 'IndicationsOfInterest',
  UserSettings = 'UserSettings',
}

export interface MyAccountSidebarMainMenuItem {
  key: MyAccountSidebarMainMenuItemKey;
  name: (param: { type?: AccountType; jointAccountType?: JointAccountType }) => string;
  path: (accountId?: string) => string;
  icon?: JSX.Element | null;
  isOptional?: (param: { type?: AccountType; jointAccountType?: JointAccountType }) => boolean;
}

export interface MyAccountSidebarOtherMenuItem {
  key: MyAccountSidebarOtherMenuItemKey;
  name: (param: { type?: AccountType; jointAccountType?: JointAccountType }) => string;
  path: (accountId: string) => string;
  isOptional?: (param: { type?: AccountType; jointAccountType?: JointAccountType }) => boolean;
}

export type MyAccountSidebarMenuItemKey = MyAccountSidebarMainMenuItemKey | MyAccountSidebarOtherMenuItemKey;

const completedAccountMenuItems: MyAccountSidebarMainMenuItem[] = [
  {
    key: MyAccountSidebarMainMenuItemKey.PersonalInformation,
    name: ({ type }) => (isUndefined(type) ? 'Account Type' : 'Personal'),
    path: () => `/accounts/personal-information`,
  },
  {
    key: MyAccountSidebarMainMenuItemKey.TrustedContact,
    name: ({ type, jointAccountType }: { type?: AccountType; jointAccountType?: JointAccountType }) => {
      if (type?.isJoint && jointAccountType?.isTenantsInCommon) {
        return 'User / Trusted Contact';
      }

      if (type?.isRetirement) {
        return 'Trusted Contact / Beneficiary (Optional)';
      }

      return 'Trusted Contact Person (Optional)';
    },
    path: () => `/accounts/trusted-contact`,
    isOptional: ({ type, jointAccountType }: { type?: AccountType; jointAccountType?: JointAccountType }) =>
      type?.isJoint && jointAccountType?.isTenantsInCommon ? false : true,
  },
  {
    key: MyAccountSidebarMainMenuItemKey.SuitabilityInformation,
    name: () => 'Suitability Information',
    path: () => `/accounts/suitability-information`,
  },
  {
    key: MyAccountSidebarMainMenuItemKey.FinancialInformation,
    name: () => 'Financial Information',
    path: () => `/accounts/financial-information`,
  },
  {
    key: MyAccountSidebarMainMenuItemKey.Disclosures,
    name: () => 'Disclosures',
    path: () => `/accounts/disclosures`,
  },
];

const incompleteAccountMenuItems: MyAccountSidebarMainMenuItem[] = completedAccountMenuItems.concat([
  {
    key: MyAccountSidebarMainMenuItemKey.AccountAgrements,
    name: () => 'Account Agreements',
    path: () => `/accounts/account-agreements`,
  },
  {
    key: MyAccountSidebarMainMenuItemKey.SignApplication,
    name: () => 'Sign Application',
    path: () => `/accounts/sign-application`,
  },
]);

const accountSettingMenuItemList: MyAccountSidebarOtherMenuItem[] = [
  {
    key: MyAccountSidebarOtherMenuItemKey.MoneyTransfers,
    name: () => 'Money Transfers & Preferences',
    path: (accountId: string) => `/accounts/${accountId}/money-transfers`,
  },
  {
    key: MyAccountSidebarOtherMenuItemKey.AccountStatus,
    name: () => 'Account Status',
    path: (accountId: string) => `/accounts/${accountId}/account-status`,
  },
  {
    key: MyAccountSidebarOtherMenuItemKey.AccountStatements,
    name: () => 'Statements & Documents',
    path: (accountId: string) => `/accounts/${accountId}/statements`,
  },
];

const userSettingMenuItemList: MyAccountSidebarOtherMenuItem[] = [
  {
    key: MyAccountSidebarOtherMenuItemKey.UserSettings,
    name: () => 'Change Email Address & Phone Number',
    path: () => `/user/settings`,
  },
  {
    key: MyAccountSidebarOtherMenuItemKey.IndicationsOfInterest,
    name: () => 'Indications of Interest',
    path: () => `/user/indications-of-interest`,
  },
];

export interface MyAccountSidebarProps {
  menuItemKey: MyAccountSidebarMenuItemKey;
}

export const MyAccountSidebar = ({ menuItemKey }: MyAccountSidebarProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { account } = useAccountSelector();

  const isAccountSidebarMenuItemComplete = (key: MyAccountSidebarMainMenuItemKey): boolean => {
    if (!account) {
      return false;
    }

    switch (key) {
      case MyAccountSidebarMainMenuItemKey.PersonalInformation: {
        return isAccountPersonalInformationComplete(account);
      }

      case MyAccountSidebarMainMenuItemKey.TrustedContact: {
        return isAccountTrustedContactComplete(account);
      }

      case MyAccountSidebarMainMenuItemKey.SuitabilityInformation: {
        return isAccountSuitabilityInformationComplete(account);
      }

      case MyAccountSidebarMainMenuItemKey.FinancialInformation: {
        return isAccountFinancialInformationComplete(account);
      }

      case MyAccountSidebarMainMenuItemKey.Disclosures: {
        return isAccountDisclosureComplete(account);
      }

      case MyAccountSidebarMainMenuItemKey.AccountAgrements: {
        return isAccountAgreementsComplete(account);
      }

      case MyAccountSidebarMainMenuItemKey.SignApplication: {
        return false;
      }
    }
  };

  const getMyAccountMenuItems = (): MyAccountSidebarMainMenuItem[] => {
    if (!account) {
      return [
        {
          ...completedAccountMenuItems[0],
          icon: completedAccountMenuItems[0].isOptional ? null : (
            <i className={`ri-time-line ${Styles.menuItemIcon({ isComplete: false })}`} />
          ),
        },
      ];
    }

    if (account?.accountId) {
      return completedAccountMenuItems.map(aMenuItem => ({
        ...aMenuItem,
        icon: !aMenuItem.isOptional?.({ type: account.type, jointAccountType: account.jointAccountType }) ? (
          <i className={`ri-checkbox-circle-fill ${Styles.menuItemIcon({ isComplete: true })}`} />
        ) : null,
      }));
    }

    return incompleteAccountMenuItems.map(aMenuItem => {
      let isComplete = false;

      try {
        isComplete = isAccountSidebarMenuItemComplete(aMenuItem.key);
      } catch (error) {
        Logger.error(error);
      }

      return {
        ...aMenuItem,
        icon: aMenuItem.isOptional?.({
          type: account.type,
          jointAccountType: account.jointAccountType,
        }) ? null : isComplete ? (
          <i className={`ri-checkbox-circle-fill ${Styles.menuItemIcon({ isComplete: true })}`} />
        ) : (
          <i className={`ri-time-line ${Styles.menuItemIcon({ isComplete: false })}`} />
        ),
      };
    });
  };

  const getAccountSettingMenuItems = (): MyAccountSidebarOtherMenuItem[] => {
    if (!account?.accountId) {
      return [];
    }

    return accountSettingMenuItemList;
  };

  const getUserSettingMenuItems = (): MyAccountSidebarOtherMenuItem[] => {
    if (!account?.accountId) {
      return [
        {
          key: MyAccountSidebarOtherMenuItemKey.IndicationsOfInterest,
          name: () => 'Indications of Interest',
          path: () => `/user/indications-of-interest`,
        },
      ];
    }

    return userSettingMenuItemList;
  };

  const getProgressBarSteps = () => {
    if (!account) {
      return [];
    }

    if (account?.accountId) {
      return [];
    }

    return incompleteAccountMenuItems
      .filter(
        aMenuItem =>
          !aMenuItem.isOptional?.({
            type: account?.type,
            jointAccountType: account?.jointAccountType,
          }),
      )
      .map(aMenuItem => ({
        isComplete: isAccountSidebarMenuItemComplete(aMenuItem.key),
      }));
  };

  const renderAccountMenuItems = () => {
    return getMyAccountMenuItems().map(aMenuItem => {
      return (
        <Col
          key={aMenuItem.key}
          span={22}
          className={Styles.menuItem({ isActive: aMenuItem.key === menuItemKey })}
          onClick={() => {
            navigate(aMenuItem.path(account?.id));
          }}>
          <div data-testid={'account-' + aMenuItem.key}>
            {aMenuItem.name({ type: account?.type, jointAccountType: account?.jointAccountType })}
          </div>
          <div className={Styles.menuItemIconContainer}>{aMenuItem.icon}</div>
        </Col>
      );
    });
  };

  const renderAccountSettings = () => {
    return getAccountSettingMenuItems().map(anOtherMenuItem => {
      return (
        <Col
          key={anOtherMenuItem.key}
          span={22}
          className={Styles.menuItem({ isActive: anOtherMenuItem.key === menuItemKey })}
          onClick={() => {
            navigate(anOtherMenuItem.path(account!.id));
          }}>
          <div>
            {anOtherMenuItem.name({
              type: account?.type,
              jointAccountType: account?.jointAccountType,
            })}
          </div>
        </Col>
      );
    });
  };

  const renderUserSettings = () => {
    return getUserSettingMenuItems().map(anOtherMenuItem => {
      return (
        <Col
          key={anOtherMenuItem.key}
          span={22}
          className={Styles.menuItem({ isActive: anOtherMenuItem.key === menuItemKey })}
          onClick={() => {
            navigate(anOtherMenuItem.path(account!.id));
          }}>
          <div>
            {anOtherMenuItem.name({
              type: account?.type,
              jointAccountType: account?.jointAccountType,
            })}
          </div>
        </Col>
      );
    });
  };

  const getActiveMenuItemKey = () => {
    if (account?.id) {
      const activeTab = getMyAccountMenuItems().find(aMenuItem =>
        aMenuItem.path(account?.id).includes(location.pathname),
      );

      return activeTab?.key;
    }
  };

  const getMobileTabItems = () => {
    return getMyAccountMenuItems().map(aMenuItem => {
      return {
        key: aMenuItem.key,
        label: (
          <div className={Styles.tabLabelContainer}>
            {aMenuItem.name({
              type: account?.type,
              jointAccountType: account?.jointAccountType,
            })}{' '}
            {aMenuItem.icon}
          </div>
        ),
        children: null,
      };
    });
  };

  const onTabChange = (key: string) => {
    const selectedMenuItem = getMyAccountMenuItems().find(aMenuItem => aMenuItem.key === key);

    if (!selectedMenuItem) {
      throw new Error(
        `Unable to find selected menu item. Expected type "MyAccountSidebarMainMenuItemKey". Received key: ${key}`,
      );
    }

    navigate(selectedMenuItem.path(account!.id));
  };

  const isAnyMainMenuItemActive = () => {
    if (!account) {
      return false;
    }

    if (account.id) {
      return incompleteAccountMenuItems.some((aMenuItem: MyAccountSidebarMainMenuItem) =>
        aMenuItem.path(account.id).includes(location.pathname),
      );
    }

    return true;
  };

  return (
    <Col className={Styles.mainContainer} md={8} sm={24} xs={24}>
      <MediaQuery maxWidth={ScreenBreakpoint.mobile.max}>
        {isAnyMainMenuItemActive() && (
          <>
            <Row>
              <Col span={22} className={cx(Heading.title, Styles.pageName)}>
                <span>My Account</span>
              </Col>
            </Row>
            <Row>
              <MyAccountProgressBar steps={getProgressBarSteps()} />
            </Row>
            <Row>
              <Col span={24} className={Styles.tabsContainer}>
                <Tabs
                  items={getMobileTabItems()}
                  onChange={onTabChange}
                  type='line'
                  centered
                  activeKey={getActiveMenuItemKey()}
                />
              </Col>
            </Row>
          </>
        )}
      </MediaQuery>
      <MediaQuery minWidth={ScreenBreakpoint.laptop.min}>
        <Row>
          <Col span={22} className={cx(Heading.title, Styles.pageName)}>
            <span>My Account</span>
          </Col>
        </Row>
        <Row>
          <MyAccountProgressBar steps={getProgressBarSteps()} />
        </Row>
        <Row>{renderAccountMenuItems()}</Row>

        {account?.accountId && (
          <>
            <Row style={{ width: '90%' }} className={cx(Spacing.my0, Spacing.mxAuto)}>
              <MDivider />
            </Row>
            <Row>
              <Col span={22} className={cx(Heading.title, Spacing.ml8, Spacing.mt8, Spacing.mb16)}>
                <span>Account Settings</span>
              </Col>
            </Row>
            <Row>{renderAccountSettings()}</Row>
            <Row style={{ width: '90%' }} className={cx(Spacing.my0, Spacing.mxAuto)}>
              <MDivider />
            </Row>
            <Row>
              <Col span={22} className={cx(Heading.title, Spacing.ml8, Spacing.mt8, Spacing.mb16)}>
                <span>User Settings</span>
              </Col>
            </Row>
            <Row>{renderUserSettings()}</Row>
          </>
        )}
      </MediaQuery>
    </Col>
  );
};
