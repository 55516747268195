import { useRef, useState } from 'react';

import { Col, Row } from 'antd';
import SignatureCanvas from 'react-signature-canvas';
import { MModal } from 'src/lib';
import { MFormItemError } from 'src/lib/MFormItemError/MFormItemError';
import { Color } from 'src/styles';

import * as Styles from './UserSignatureOptionList.styles';

export const SIGNATURE_FONT_STYLES = ['Pacifico', 'Satisfy', 'Yellowtail'];
const CUSTOM_SIGNATURE_INDEX = 3;

export type UserSignatureOptionListProps = {
  title?: string;
  value?: HTMLElement;
  error?: string;
  fullName?: string;
  onChange: (signature: HTMLElement) => void;
  testId?: 'primary' | 'joint';
};

export const UserSignatureOptionList = ({
  title,
  fullName,
  value,
  error,
  onChange,
  testId,
}: UserSignatureOptionListProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [customSignatureImage, setCustomerSignatureImage] = useState<string>();
  const sigPad = useRef<SignatureCanvas | null>(null);
  const signatureRef = useRef<HTMLElement[]>([]);

  return (
    <>
      <Row>
        <Col span={24} className={Styles.pleaseSelectSignature}>
          {title ?? 'Please select a signature to use'}
        </Col>
        <Col span={24}>
          {SIGNATURE_FONT_STYLES.map((font, i) => (
            <div
              data-testid={`${testId}-account-signature-` + i}
              key={`${font}_key`}
              className={Styles.fontSignatureContainer}
              style={{
                fontFamily: font,
                border:
                  value && value === signatureRef.current[i] ? `1px solid ${Color.BRAND.BRAND6}` : '1px solid #DFE5F0',
              }}>
              <div
                className={Styles.signatureContainer}
                onClick={() => {
                  onChange(signatureRef.current[i]);
                }}
                ref={ref => {
                  if (ref) {
                    signatureRef.current[i] = ref;
                  }
                }}>
                {fullName}
              </div>
            </div>
          ))}
          {customSignatureImage && (
            <div
              className={Styles.drawnSignatureContainer}
              style={{
                border:
                  value && value === signatureRef.current[CUSTOM_SIGNATURE_INDEX]
                    ? `1px solid ${Color.BRAND.BRAND6}`
                    : '1px solid #DFE5F0',
              }}>
              <div
                className={Styles.signatureContainer}
                ref={ref => {
                  if (ref) {
                    signatureRef.current[CUSTOM_SIGNATURE_INDEX] = ref;
                  }
                }}
                onClick={() => onChange(signatureRef.current[CUSTOM_SIGNATURE_INDEX])}>
                <img src={customSignatureImage} alt='signature' style={{ height: 40, width: 150 }} />
              </div>
            </div>
          )}
        </Col>
        <Col span={24}>{error && <MFormItemError value={error} />}</Col>
      </Row>

      {Boolean(customSignatureImage) && (
        <div
          onClick={() => {
            setIsModalVisible(true);
          }}
          className={Styles.modifySignatureButton}>
          <i style={Styles.penStyle} className='ri-pen-nib-line' />
          Modify Signature
        </div>
      )}

      <Row>
        <Col span={24} className={Styles.drawSignature}>
          OR
        </Col>
        <Col
          span={24}
          onClick={() => {
            setIsModalVisible(true);
          }}
          className={Styles.drawSignature}
          style={{ cursor: 'pointer' }}>
          <i style={Styles.quillPen} className='ri-quill-pen-line' />
          Draw your signature
        </Col>
      </Row>

      <MModal
        title='Draw your signature'
        visible={isModalVisible}
        secondaryButtonText='Clear Signature'
        primaryButtonText='Save Signature'
        onClose={() => {
          setIsModalVisible(false);
        }}
        onPrimaryButtonClick={() => {
          setIsModalVisible(false);
          const trimmedCanvas = sigPad.current?.getTrimmedCanvas();
          const signature = trimmedCanvas?.toDataURL('image/png');
          setCustomerSignatureImage(signature);
        }}
        onSecondaryButtonClick={() => {
          sigPad.current?.clear();
        }}>
        <div className={Styles.signaturePadContainer}>
          <SignatureCanvas ref={sigPad} penColor='black' canvasProps={{ width: 450, height: 200 }} />
        </div>
      </MModal>
    </>
  );
};
