import { useEffect, useState } from 'react';

import { isUndefined } from 'lodash';
import { useAccountSelector } from 'src/hooks';
import { MFormSection } from 'src/lib';

import { DisclosuresForm } from './DisclosuresForm';
import { DisclosuresView } from './DisclosuresView';

export const AccountHolderDisclosureSection = () => {
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const { account } = useAccountSelector();

  const onEdit = () => {
    setIsEditMode(true);
  };

  const onCancel = () => {
    setIsEditMode(false);
  };

  const isDisclosureAlreadySaved = () =>
    !isUndefined(account?.primaryAccountHolder?.disclosure?.statementDelivery?.value);

  useEffect(() => {
    if (isDisclosureAlreadySaved()) {
      setIsEditable(true);
    }
  }, [account?.primaryAccountHolder?.disclosure]);

  return (
    <MFormSection
      title={account?.type.isJoint ? 'Disclosures' : undefined}
      onEdit={onEdit}
      isEditable={isEditable}
      isEditMode={isEditMode}
      tooltip={
        account?.type.isJoint
          ? {
              type: 'modal',
              icon: 'info',
              title: 'Joint Account Statement Delivery',
              label: 'Statement Delivery applies to both primary and joint account holders.',
              content: 'Statement Delivery applies to both primary and joint account holders.',
            }
          : undefined
      }
      testId={'primary-disclosures'}>
      {isDisclosureAlreadySaved() && !isEditMode ? (
        <DisclosuresView accountHolder={account?.primaryAccountHolder} isPrimary />
      ) : (
        <DisclosuresForm
          accountUuid={account?.id}
          accountHolder={account?.primaryAccountHolder}
          isPrimary
          onCancel={onCancel}
          testId={'primary'}
        />
      )}
    </MFormSection>
  );
};
