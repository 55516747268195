import { MFormRadioGroupField } from 'src/lib';
import { Account } from 'src/models';

import { AccountTypeDescription } from './AccountTypeDescription';
import { JointAccountTypeDescription } from './JointAccountTypeDescription';
import { RetirementAccountTypeDescription } from './RetirementAccountTypeDescription';

export interface AccountTypeViewProps {
  account?: Account;
}

export const AccountTypeView = ({ account }: AccountTypeViewProps) => {
  return (
    <>
      <MFormRadioGroupField
        label='What type of account do you wish to open?'
        value={account?.type.label}
        description={<AccountTypeDescription value={account?.type.value} />}
        alignment='vertical'
        testId={'account-type'}
      />
      {account?.type.isJoint && (
        <MFormRadioGroupField
          label='Type of Joint Account'
          value={account?.jointAccountType?.label}
          description={<JointAccountTypeDescription value={account?.jointAccountType?.value} />}
          alignment='vertical'
          testId={'joint-account-type'}
        />
      )}
      {account?.type.isJoint && account?.jointAccountType?.isCommunity && account?.jointCommunityPropertyState && (
        <MFormRadioGroupField
          label='Property State'
          value={account?.jointCommunityPropertyState?.label}
          alignment='vertical'
          testId={'joint-property-state'}
        />
      )}
      {account?.type.isRetirement && (
        <MFormRadioGroupField
          label='Type of Retirement Account'
          value={
            account?.retirementAccountType?.isRollover
              ? 'IRA Roll-over 401(k), 403(b), etc.'
              : account?.retirementAccountType?.label
          }
          description={<RetirementAccountTypeDescription value={account?.retirementAccountType?.value} />}
          alignment='vertical'
        />
      )}
      {account?.type.isRetirement && account?.retirementAccountType?.isSimple && (
        <MFormRadioGroupField label='Simple IRA Plan Name' value={account.simpleIraPlanName} alignment='vertical' />
      )}
      {account?.type.isRetirement &&
        (account?.retirementAccountType?.isBeneficiary || account?.retirementAccountType?.isRothBeneficiary) && (
          <MFormRadioGroupField
            label='Beneficiary IRA Decedent Name'
            value={account.beneficiaryIraDecedentName}
            alignment='vertical'
          />
        )}
    </>
  );
};
