import { useEffect } from 'react';

import { Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearConfirmCardPayment,
  clearCreateOfferOrder,
  clearDeleteOfferOrder,
  clearSubmitCardPayment,
  deleteOfferOrder,
  removeOfferOrderIntent,
} from 'src/actions';
import { ConfirmCardPaymentErrorCause } from 'src/constants';
import { ConfirmCardPaymentError } from 'src/errors';
import { useAccountSelector } from 'src/hooks';
import {
  MFormCurrencyField,
  MFormCurrencyRangeField,
  MFormDateField,
  MFormTextField,
  SecurityDeliveryOption,
  SubmissionResult,
} from 'src/lib';
import { OfferDetails, OfferOrder, OfferOrderIntent } from 'src/models';
import { BaseStyle, Images } from 'src/styles';

import { NewOfferOrderLayout } from '../../../../../lib/Layout/NewOfferOrderLayout/NewOfferOrderLayout';
import CurrencyField from '../../../../../lib/Miscellaneous/FormattedFields/CurrencyField/CurrencyField';
import * as Styles from '../NewOfferOrders.styles';

export const NewOfferOrderFailed = () => {
  const dispatch = useDispatch();
  const offerDetails: OfferDetails = useSelector((state: any) => state.offers.offerDetails?.data);
  const offerOrderIntent: OfferOrderIntent = useSelector((state: any) => state.offerOrders.intent);
  const offerOrder: OfferOrder | undefined = useSelector((state: any) => state.offerOrders.create.data);
  const createOfferOrderSucceeded = useSelector((state: any) => Boolean(state.offerOrders.create.__succeeded));
  const createOfferOrderError = useSelector((state: any) => state.offerOrders.create.message);

  const confirmCardPaymentError: ConfirmCardPaymentError | undefined = useSelector(
    (state: any) => state.offerOrders.confirmCardPayment.error,
  );

  const { account } = useAccountSelector();

  const renderErrorMessage = (error: ConfirmCardPaymentError) => {
    if (error.cause === ConfirmCardPaymentErrorCause.AUTHENTICATION_FAILURE) {
      return (
        <>
          Your credit card payment was declined due to failed authentication. If you would like to try again, please
          re-enter an Order with the same payment information or try a different payment method. Please contact your
          credit card company and/or My IPO at <a href='mailto:clientservices@myipo.com'>clientservices@myipo.com</a> if
          you continue to experience problems.
        </>
      );
    }

    return error.message;
  };

  useEffect(() => {
    if (createOfferOrderSucceeded && offerOrder?.paymentType.isCard) {
      dispatch(deleteOfferOrder({ params: { id: offerOrder.id } }));
    }
  }, [createOfferOrderSucceeded, offerOrder]);

  useEffect(() => {
    window.gtag('event', 'offer_order_error', {
      offer_id: offerDetails.id,
      offer_name: offerDetails.name,
      account_id: offerOrderIntent.account.accountId,
      offer_order_payment_type: offerOrderIntent.paymentType?.value,
      offer_order_quantity: offerOrderIntent.quantity,
      offer_order_value: offerOrderIntent.totalInvestment, // TODO: add the error message
    });

    return () => {
      dispatch(removeOfferOrderIntent());
      dispatch(clearCreateOfferOrder());
      dispatch(clearDeleteOfferOrder());
      dispatch(clearConfirmCardPayment());
      dispatch(clearSubmitCardPayment());
    };
  }, []);

  return (
    <NewOfferOrderLayout step='create-failed' offerId={offerDetails.id}>
      <SubmissionResult
        type='error'
        message={confirmCardPaymentError ? renderErrorMessage(confirmCardPaymentError) : createOfferOrderError}
      />
      <Col span={24} className={BaseStyle.level}>
        <img
          className={Styles.offerLogo}
          src={offerDetails.logoUrl || Images.DefaultOffering}
          alt={`${offerDetails.name}_logo`}
        />
        <span className={Styles.offerName}>{offerDetails.name}</span>
      </Col>
      <Col span={24}>
        <MFormTextField
          label='Investor Name'
          value={`${account?.primaryAccountHolder?.firstName} ${account?.primaryAccountHolder?.lastName}`}
        />
      </Col>
      <Col span={24}>
        <MFormTextField
          label='Address'
          value={`${account?.primaryAccountHolder?.physicalAddress?.address1}, ${account?.primaryAccountHolder?.physicalAddress?.city}, ${account?.primaryAccountHolder?.physicalAddress?.postalCode}, ${account?.primaryAccountHolder?.physicalAddress?.country?.value}`}
        />
      </Col>
      <Col span={24}>
        <MFormDateField label='Date' value={new Date().toISOString()} />
      </Col>
      <Col span={24}>
        {offerOrderIntent.isConditional ? (
          <MFormCurrencyRangeField
            label='Share Range'
            min={offerOrderIntent.minSharePrice}
            max={offerOrderIntent.maxSharePrice}
          />
        ) : (
          <>
            <MFormCurrencyField label='Share Price' value={offerOrderIntent.price} />
            <MFormTextField label='Share Quantity' value={offerOrderIntent.quantity} />
          </>
        )}
      </Col>
      {offerOrderIntent.isExternal && offerOrderIntent.securityDeliveryOption && (
        <Col span={24}>
          <MFormTextField
            label='Security Delivery Option'
            value={<SecurityDeliveryOption {...offerOrderIntent.securityDeliveryOption} />}
          />
        </Col>
      )}
      <Col span={24}>
        <div className={Styles.totalInvestment}>
          <span>Total Investment</span>
          <CurrencyField value={offerOrderIntent.totalInvestment} className={Styles.totalInvestmentValue} />
        </div>
      </Col>
    </NewOfferOrderLayout>
  );
};
