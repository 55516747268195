import { PropsWithChildren } from 'react';

import { useSelector } from 'react-redux';
import { CardLayout, IdentityVerificationInstructions } from 'src/lib';
import { FontWeight } from 'src/styles';

import * as Styles from './BaseLayout.styles';

export interface SignUpWithOfferInvestReferrerProps {
  cardTitle?: string;
  cardSubtitle?: string | JSX.Element;
  activeTab?: string;
  tabs?: string[];
}

export const SignUpWithOfferInvestReferrer = ({
  cardTitle,
  cardSubtitle,
  activeTab,
  tabs,
  children,
}: PropsWithChildren<SignUpWithOfferInvestReferrerProps>) => {
  const offerInvestReferrer: { id: string; name: string; logoUrl?: string } | undefined = useSelector(
    (state: any) => state.app.offerInvestReferrer,
  );

  const renderTitle = () => {
    if (offerInvestReferrer) {
      return;
    }

    return 'Welcome!';
  };

  const renderSubtitle = () => {
    if (offerInvestReferrer) {
      return (
        <>
          <p
            className={Styles.referrerSubtitleParagraph}
            data-testid={
              'register-order-invest-paragraph'
            }>{`In order to Invest in ${offerInvestReferrer?.name}, you will need to complete the following steps:`}</p>
          <p className={Styles.referrerStep} data-testid={'register-step-1'}>
            <span className={FontWeight.semibold}>Step 1: &quot;Sign Up&quot;</span> or{' '}
            <span className={FontWeight.semibold}>&quot;Log in&quot;</span> if you have already signed up on My IPO.
          </p>
        </>
      );
    }

    return 'In order to Invest in an Offer, you will first need to “Sign up” or “Log in” to My IPO';
  };

  const renderFooter = () => {
    if (offerInvestReferrer) {
      return (
        <>
          <p className={Styles.referrerStep} data-testid={'register-step-2'}>
            <span className={FontWeight.semibold}>Step 2:</span> Complete My IPO Account.
          </p>
          <p className={Styles.referrerStep} data-testid={'register-step-3'}>
            <span className={FontWeight.semibold}>Step 3:</span> Place your {offerInvestReferrer?.name} investment order
            and select payment method.
          </p>
        </>
      );
    }

    return;
  };

  const renderRightSlot = () => {
    if (activeTab === 'Register') {
      return <IdentityVerificationInstructions />;
    }
  };

  return (
    <CardLayout
      logoUrl={offerInvestReferrer?.logoUrl ?? undefined}
      logoPath={offerInvestReferrer?.id ? `/offers/${offerInvestReferrer?.id}` : undefined}
      title={renderTitle()}
      subtitle={renderSubtitle()}
      subtitleClassName={offerInvestReferrer ? Styles.referrerSubtitle : undefined}
      footer={renderFooter()}
      cardTitle={cardTitle}
      cardSubtitle={cardSubtitle}
      cardClassName={offerInvestReferrer ? Styles.referrerCard : undefined}
      activeTab={activeTab}
      tabs={tabs}
      rightSlot={renderRightSlot()}>
      {children}
    </CardLayout>
  );
};
