export type OfferAssetDto = {
  url: string;
  type: string;
  previewImageUrl?: string;
};

export type OfferDocumentDto = {
  id: string;
  type: string;
  url: string;
};

export enum OfferOptionTypeDto {
  Status = 'offerStatus',
  Type = 'offerType',
  Category = 'offerCategory',
  Industry = 'offerIndustry',
  SecurityType = 'offerSecurityType',
}

export interface OfferOptionDto {
  id: string;
  name: string;
}

export enum OfferCardPaymentAccountStatusDto {
  Restricted = 'Restricted',
  Pending = 'Pending',
  Enabled = 'Enabled',
  Complete = 'Complete',
  Rejected = 'Rejected',
  Unknown = 'Unknown',
}

export interface PublicOfferCardPaymentAccountDto {
  externalId: string;
  status: OfferCardPaymentAccountStatusDto;
  email?: string;
}

export interface OfferExternalWireInstructionsDto {
  bankName: string;
  abaNumber: string;
  accountNumber: string;
  accountName: string;
  attentionLine: string;
}

export interface OfferExternalCheckInstructionsDto {
  payableTo: string;
  bankName: string;
  address: {
    address1: string;
    address2?: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
  };
}

export interface OfferExternalSecurityDeliveryOptionDto {
  hasTooltip: boolean;
  isReadOnly: boolean;
  label: string;
  initialValue: boolean;
  tooltip?: string;
}

export interface OfferExternalDetailsDto {
  wireInstructions: OfferExternalWireInstructionsDto;
  securityDeliveryOptions: OfferExternalSecurityDeliveryOptionDto[];
  disclaimer?: string;
  completedOfferOrderDisclaimer: string;
  checkInstructions?: OfferExternalCheckInstructionsDto;
}

export interface OfferDto {
  id: string;
  name: string;
  new: boolean;
  isComingSoon: boolean;
  closeDate: string | null;
  closeDates: string[];
  recurring: boolean;
  yield: string;
  statusId: string;
  categoryId: string;
  industryId: string;
  offerTypeId: string;
  securityTypeId: string;
  status: string;
  category: string;
  industry: string;
  type: string;
  security: string;
  logoUrl: string | null;
  videoThumbnailUrl: string | null;
  shortUrlRoute: string | null;
  assets: OfferAssetDto[];
  featured: boolean;
  ordersSummation: string | null;
  allowAchTransfers: boolean;
  allowWireTransfers: boolean;
  allowCheckTransfers: boolean;
  allowCardPayments: boolean;
  allowAcatTransfers: boolean;
  allowIraContributionWireTransfers: boolean;
  allowIraContributionCheckTransfers: boolean;
  minimumInvestCurrencyAmt: number;
  totalCapitalRaised?: number;
}

export interface OfferDetailsDto extends OfferDto {
  isExternalOffer: boolean;
  description: string;
  disclaimer: string;
  yieldDescription: string;
  exitDescription: string;
  subtextOnClosed: string;
  pricePerShare: number;
  maximumInvestCurrencyAmt: number;
  minTotalRaiseCurrencyAmt: number;
  maxTotalRaiseCurrencyAmt: number;
  minSharePrice?: number;
  maxSharePrice?: number;
  startDate?: string;
  sector: string;
  videoUrl: string | null;
  documents: OfferDocumentDto[];
  anticipatedStartDate: string | null;
  shortUrlRoute: string | null;
  investmentRanges: string[];
  allowRestrictedPersons: boolean;
  nonVisibleAppFields: string[] | null;
  cardPaymentAccount?: PublicOfferCardPaymentAccountDto;
  externalDetails?: OfferExternalDetailsDto;
  subscriptionAgreementId?: string;
}
