import { PropsWithChildren } from 'react';

import { Checkbox, Col, Row } from 'antd';
import { type CheckboxChangeEvent } from 'antd/lib/checkbox';

import { MFormItemError } from '../MFormItemError/MFormItemError';

import * as Styles from './MCheckbox.styles';

export interface MCheckboxProps {
  value?: string | number;
  defaultChecked?: boolean;
  checked?: boolean;
  onChange?: (param: { checked: boolean; value?: string | number }) => void;
  disabled?: boolean;
  error?: string;
  className?: string;
  testId?: string;
}

export const MCheckbox = ({
  value,
  defaultChecked,
  checked,
  disabled,
  className = '',
  error,
  onChange,
  children,
  testId,
}: PropsWithChildren<MCheckboxProps>) => {
  const _onChange = (event: CheckboxChangeEvent) => {
    if (onChange) {
      onChange({ value, checked: event.target.checked });
    }
  };

  return (
    <Checkbox
      data-testid={testId + '-checkbox'}
      disabled={disabled}
      defaultChecked={defaultChecked}
      checked={checked}
      className={Styles.checkbox.concat(' ').concat(className).trim()}
      onChange={_onChange}>
      <Row>
        <Col span={24} data-testid={testId + '-text'}>
          {children}
        </Col>
        <Col span={24}>
          <MFormItemError value={error} testId={testId + '-error-text'} />
        </Col>
      </Row>
    </Checkbox>
  );
};
