import React, { useCallback, useEffect, useState } from 'react';

import { PlusCircleOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import _ from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import { getACHRelationships, getTransfers } from 'src/actions';
import { cancelDeposit, cancelWithdraw } from 'src/actions/cashiering';
import { FilterBarConstant } from 'src/constants';
import { useAccountSelector } from 'src/hooks';
import {
  CancelTransferButton,
  ConfirmActionModal,
  EmptyTableState,
  FilterBar,
  FilterBarSample,
  LinkText,
  MTable,
  TransferDetailsModal,
  TransferStatusTag,
  TransferTypeCol,
  NewAchRelationshipModal,
  MButton,
  MTooltip,
} from 'src/lib';
import { CheckboxGroup } from 'src/lib/Filters/CheckboxFilter/CheckboxFilter';
import { FixedTimelineFilterValueType } from 'src/lib/Filters/TimelineFilter/TimelineFilter';
import { MyAccountLayout } from 'src/lib/Layout/MyAccountLayout/MyAccountLayout';
import { MyAccountSidebarOtherMenuItemKey } from 'src/lib/Layout/MyAccountLayout/MyAccountSidebar';
import CurrencyField from 'src/lib/Miscellaneous/FormattedFields/CurrencyField/CurrencyField';
import Spinner from 'src/lib/Miscellaneous/Spinner';
import { Transfer, TransferStatus, TransferStatusLabel } from 'src/models';
import { ScreenBreakpoint } from 'src/styles';
import { getTransferSearch, getTransferStatusTagColor, onFixedTimelineFilterSelected } from 'src/utils';

import AchRelationships from './components/AchRelationships/AchRelationships';
import { DepositByCheckInstructions } from './components/DepositByCheckInstructions/DepositByCheckInstructions';
import { DepositByWireInstructions } from './components/DepositByWireInstructions/DepositByWireInstructions';
import { EditNickNameModal } from './components/EditNickname/EditNickNameModal';
import { NewDepositModal } from './components/NewDepositModal/NewDepositModal';
import { NewWithdrawalModal } from './components/NewWithdrawalModal/NewWithdrawalModal';
import * as Styles from './NewTransfer.styles';
import { RetirementAccountFunding } from './RetirementAccountFunding';

export interface TransferViewModel {
  key: React.Key;
  date: string;
  type: JSX.Element;
  amount: JSX.Element;
  status: TransferStatus;
  cancel: JSX.Element | null;
  view: JSX.Element;
}

const transferListCols: ColumnsType<Omit<TransferViewModel, 'status'>> = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    width: '20%',
    render: (date: string) => <LinkText value={date} />,
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    width: '35%',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    width: '15%',
    align: 'right',
  },
  {
    key: 'cancel',
    dataIndex: 'cancel',
    width: '15%',
  },
  {
    key: 'view',
    dataIndex: 'view',
    width: '15%',
  },
];

export const BankAccountsAndFunding = (): JSX.Element => {
  const defaultTimeline = FixedTimelineFilterValueType.THIS_YEAR;
  const defaultSortBy = FilterBarConstant.SortByCategory.Newest;
  const defaultValues: FilterBarSample = {
    sortBy: defaultSortBy,
    timeline: defaultTimeline,
    customTimeline: undefined,
    filterBy: [],
    show: [],
  };

  const location: any = useLocation();
  const achRelationships = useSelector((state: any) => state.cashiering.achRelationships.data);
  const loadingAchRelationships: boolean = useSelector((state: any) => state.cashiering.achRelationships.isLoading);
  const bankTransactions: Transfer[] = useSelector((state: any) => state.cashiering.transfers?.data);
  const isLoadingBankTransactions = useSelector((state: any) => Boolean(state.cashiering.transfers?.__requested));

  const [selectedBankLink, setSelectedBankLink] = useState<any>({});
  const [isNewAchRealtionshipModalOpen, setIsNewAchRealtionshipModalOpen] = useState<boolean>(false);

  const [isEditNickNameModalOpen, setIsEditNickNameModalOpen] = useState<boolean>(false);

  const [transactionList, setTransactionList] = useState<TransferViewModel[]>([]);
  const [filteredTransactions, setFilteredTransactions] = useState<TransferViewModel[]>([]);
  const [filters, setFilters] = useState<FilterBarSample>({ ...defaultValues });

  const [selectedTransfer, setSelectedTransfer] = useState<Transfer | null>(null);
  const [isNewDepositModalOpen, setIsNewDepositModalOpen] = useState<boolean>(false);
  const [isNewWithdrawalModalOpen, setIsNewWithdrawalModalOpen] = useState<boolean>(false);
  const [isCancelConfirmationModalOpen, setIsCancelConfirmationModalOpen] = useState<boolean>(false);
  const [isViewTransferDetailsModalOpen, setIsViewTransferDetailsModalOpen] = useState<boolean>(false);

  const { account } = useAccountSelector();
  const isMobile = useMediaQuery({ query: `(max-width: ${ScreenBreakpoint.mobile.max})` });

  const dispatch = useDispatch();

  const canAddAchRelationship = () => achRelationships?.length < 2 && !account?.type.isRetirement;

  const onOpenCancelTransferModal = (transfer: Transfer) => {
    setSelectedTransfer(transfer);
    setIsCancelConfirmationModalOpen(true);
  };

  const onOpenTransferDetailsModal = (transfer: Transfer) => {
    setSelectedTransfer(transfer);
    setIsViewTransferDetailsModalOpen(true);
  };

  const onDeposit = () => {
    setIsNewDepositModalOpen(true);
  };

  const onWithdraw = () => {
    setIsNewWithdrawalModalOpen(true);
  };

  const onEdit = () => {
    setIsEditNickNameModalOpen(true);
  };

  const onApproveFilters = useCallback((newFilters: FilterBarSample) => {
    setFilters({ ...newFilters });
  }, []);

  const mapTransferListToViewModelList = (): TransferViewModel[] => {
    return Array.isArray(bankTransactions)
      ? bankTransactions?.map((transfer: Transfer) => {
          const historyTransaction: TransferViewModel = {
            date: moment(transfer.updatedAt).local().utcOffset(7).utcOffset(0, true).format(),
            amount: (
              <CurrencyField
                value={transfer.amount}
                prefix={`${transfer.direction.isWithdrawal ? '-' : ''}$`}
                className={Styles.amountValue({ isNegative: transfer.direction.isWithdrawal })}
              />
            ),
            key: transfer.id,
            type: <TransferTypeCol value={transfer} testId='column-type-id' />,
            cancel: <CancelTransferButton value={transfer} onClick={onOpenCancelTransferModal} />,
            view: (
              <MButton
                type='tertiary'
                className={Styles.viewDetailsButton}
                onClick={() => onOpenTransferDetailsModal(transfer)}>
                View Details
              </MButton>
            ),
            status: transfer.status,
          };

          return historyTransaction;
        })
      : [];
  };

  const mapStatusLabelListToByFilterGroup = (): CheckboxGroup[] => {
    const options = Object.values(TransferStatusLabel).map(value => ({
      label: <TransferStatusTag value={value} />,
      value: `${value}_${FilterBarConstant.ByFilterCategory.MoneyTransfer}`,
      tag: {
        text: value,
        ...getTransferStatusTagColor(value),
      },
    }));

    return [{ title: 'Money Transaction(s)', options }];
  };

  const filterByStatus = (list: TransferViewModel[]) => {
    return list.filter((transfer: TransferViewModel) =>
      filters.filterBy?.some(aFilterValue => {
        const [value] = aFilterValue.toString().split('_');
        const label = transfer.status.label;

        return value === label;
      }),
    );
  };

  const applyFilters = () => {
    let filteredTransactionList = [...transactionList];

    if (filters.filterBy && filters.filterBy.length > 0)
      filteredTransactionList = filterByStatus(filteredTransactionList);

    if (filters.timeline !== undefined) {
      const timelineDate = onFixedTimelineFilterSelected(filters.timeline);
      filteredTransactionList = filteredTransactionList.filter(
        history =>
          moment(history.date).isSameOrBefore(timelineDate.endDate) &&
          moment(history.date).isSameOrAfter(timelineDate.startDate),
      );
    }

    if (filters.customTimeline !== undefined) {
      const [startDate, endDate] = filters.customTimeline;
      filteredTransactionList = filteredTransactionList.filter(
        history => moment(history.date).isSameOrBefore(endDate) && moment(history.date).isSameOrAfter(startDate),
      );
    }

    filteredTransactionList = _.orderBy(
      filteredTransactionList,
      ['date'],
      [filters.sortBy === FilterBarConstant.SortByCategory.Newest ? 'desc' : 'asc'],
    );

    return filteredTransactionList;
  };

  useEffect(() => {
    if (!isLoadingBankTransactions) dispatch(getTransfers(account?.accountId, getTransferSearch(filters)));
  }, [filters.timeline, filters.customTimeline, account?.accountId]);

  useEffect(() => {
    const transactionNewList = applyFilters();
    setFilteredTransactions(
      transactionNewList.map((item: TransferViewModel) => ({
        ...item,
        date: moment(item.date).format('MM/DD/yyyy'),
      })),
    );
  }, [filters, transactionList]);

  useEffect(() => {
    if (!bankTransactions || isLoadingBankTransactions) return;
    const historyTransactions = mapTransferListToViewModelList();
    const orderedHistoryTransactions = _.orderBy(historyTransactions, ['date'], ['desc']);
    setTransactionList(orderedHistoryTransactions as any);
  }, [bankTransactions, isLoadingBankTransactions]);

  useEffect(() => {
    dispatch(getACHRelationships(account?.accountId));
  }, []);

  return (
    <MyAccountLayout
      sidebarMenuItemKey={MyAccountSidebarOtherMenuItemKey.MoneyTransfers}
      title='Bank Accounts & Funding'
      backlink={
        location?.state?.offerId && location?.state?.path
          ? { to: `/${location?.state?.path}/${location?.state?.offerId}`, label: 'Back to Offer' }
          : undefined
      }>
      {loadingAchRelationships ? (
        <Spinner />
      ) : (
        <>
          {canAddAchRelationship() && (
            <Col span={24}>
              <div className={Styles.label}> LINKED BANK ACCOUNTS</div>
              <AchRelationships
                achRelationships={achRelationships}
                selectedBankLink={selectedBankLink}
                setSelectedBankLink={setSelectedBankLink}
                loadingAchRelationships={loadingAchRelationships}
                onDeposit={onDeposit}
                onWithdraw={onWithdraw}
                onEdit={onEdit}
                testId={'link-bank-accounts'}
              />
              {achRelationships?.length < 2 && (
                <MButton
                  testId={'money-transfers-btn-link-bank-account'}
                  onClick={() => {
                    setIsNewAchRealtionshipModalOpen(true);
                  }}>
                  <PlusCircleOutlined style={{ fontSize: 16, marginRight: 4 }} />
                  Link a new bank account
                </MButton>
              )}
            </Col>
          )}

          {account?.type.isRetirement ? (
            <RetirementAccountFunding />
          ) : (
            <>
              <Col span={24}>
                <MTooltip type='modal' title='Deposit Funds By Wire' label='Deposit Funds By Wire' icon='info' showIcon>
                  <DepositByWireInstructions />
                </MTooltip>
              </Col>
              <Col span={24}>
                <MTooltip
                  type='modal'
                  title='Deposit Funds By Check'
                  label='Deposit Funds By Check'
                  icon='info'
                  showIcon>
                  <DepositByCheckInstructions />
                </MTooltip>
              </Col>
              <Col span={24}>
                <p>
                  If you need to withdrawal funds by wire or check please contact{' '}
                  <a href='mailto:clientservices@myipo.com' target='_top'>
                    clientservices@myipo.com
                  </a>
                </p>
              </Col>{' '}
            </>
          )}

          <Row align='middle'>
            <Col span={24} sm={18} xs={18} className={Styles.label}>
              DEPOSITS & WITHDRAWALS
            </Col>
            <Col span={24} sm={6} xs={6} md={24}>
              <FilterBar
                onApprove={onApproveFilters}
                defaultValue={defaultValues}
                initValue={filters}
                byFilter={mapStatusLabelListToByFilterGroup()}
              />
            </Col>
            <Col span={24} className={Styles.tableContainer}>
              <MTable
                testId={'items-table'}
                loading={isLoadingBankTransactions}
                columns={transferListCols}
                data={filteredTransactions}
                pagination={{ position: ['bottomRight'] }}
                scroll={isMobile ? { x: 180 } : {}}
                emptyState={<EmptyTableState type='money transfers' />}
              />
            </Col>
          </Row>

          <NewAchRelationshipModal
            open={isNewAchRealtionshipModalOpen}
            onClose={() => setIsNewAchRealtionshipModalOpen(false)}
            onExit={() => setIsNewAchRealtionshipModalOpen(false)}
            onFinish={() => setIsNewAchRealtionshipModalOpen(false)}
          />

          {isEditNickNameModalOpen && (
            <EditNickNameModal
              achRelationship={selectedBankLink}
              open={isEditNickNameModalOpen}
              onClose={() => setIsEditNickNameModalOpen(false)}
            />
          )}

          {isNewDepositModalOpen && (
            <NewDepositModal
              isOpen={isNewDepositModalOpen}
              onClose={() => setIsNewDepositModalOpen(false)}
              selectedBankAccount={selectedBankLink}
            />
          )}

          {isNewWithdrawalModalOpen && (
            <NewWithdrawalModal
              isOpen={isNewWithdrawalModalOpen}
              onClose={() => setIsNewWithdrawalModalOpen(false)}
              selectedBankAccount={selectedBankLink}
            />
          )}

          {selectedTransfer && (
            <TransferDetailsModal
              value={selectedTransfer}
              isOpen={isViewTransferDetailsModalOpen}
              onClose={() => {
                setIsViewTransferDetailsModalOpen(false);
                setSelectedTransfer(null);
              }}
            />
          )}

          {selectedTransfer && (
            <ConfirmActionModal
              title='Cancel Confirmation'
              message='Are you sure you want to cancel this transfer?'
              isOpen={isCancelConfirmationModalOpen}
              onCancel={() => {
                setIsCancelConfirmationModalOpen(false);
                setSelectedTransfer(null);
              }}
              onOk={() => {
                if (selectedTransfer.direction.isDeposit) {
                  dispatch(cancelDeposit(selectedTransfer.id, account?.accountId));
                  setIsCancelConfirmationModalOpen(false);
                  setSelectedTransfer(null);

                  return;
                }
                dispatch(cancelWithdraw(selectedTransfer.id, account?.accountId));
                setIsCancelConfirmationModalOpen(false);
                setSelectedTransfer(null);
              }}
            />
          )}
        </>
      )}
    </MyAccountLayout>
  );
};
