import { useEffect, useRef, useState } from 'react';

import { Col } from 'antd';
import { Formik, FormikProps } from 'formik';
import { isEmpty, isEqual, sortBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { doClearPatchAccountHolder, doPatchAccountHolder } from 'src/actions';
import { US_PHONE_NUMBER_PREFIX } from 'src/constants';
import { AccountHolderMaritalStatusDto, PhoneNumberTypeDto, UserDto } from 'src/dtos';
import {
  DateOfBirthPicker,
  MFormInput,
  MFormInputPassword,
  MFormPhoneNumberInput,
  MFormSaveButton,
  MFormSelect,
  MModal,
} from 'src/lib';
import { AccountHolder, AccountHolderMaritalStatusLabel } from 'src/models';
import { assertNonNullable, stripPhoneNumberPrefix } from 'src/utils';

import { SocialSecurityNumberTooltipContent } from './SocialSecurityNumberTooltipContent';
import {
  createAccountHolderValidationSchema,
  createJointAccountHolderValidationSchema,
  patchAccountHolderValidationSchema,
  patchJointAccountholderValidationSchema,
} from './validations';

export const MARITAL_STATUS_OPTION_LIST = [
  { value: AccountHolderMaritalStatusDto.Single, label: AccountHolderMaritalStatusLabel.Single },
  { value: AccountHolderMaritalStatusDto.Married, label: AccountHolderMaritalStatusLabel.Married },
  { value: AccountHolderMaritalStatusDto.Divorced, label: AccountHolderMaritalStatusLabel.Divorced },
  { value: AccountHolderMaritalStatusDto.Widowed, label: AccountHolderMaritalStatusLabel.Widowed },
];

interface AccountHolderFormValues {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  suffix?: string;
  dateOfBirth?: string;
  ssid?: string;
  email?: string;
  confirmEmail?: string;
  phones?: { type: PhoneNumberTypeDto; value: string; prefix: string }[];
  maritalStatus?: AccountHolderMaritalStatusDto;
  numberOfDependents?: string;
}

export interface PersonalInformationFormProps {
  accountId?: number;
  accountUuid?: string;
  accountHolder?: AccountHolder;
  isPrimary: boolean;
  onCancel?: () => void;
  onSave?: () => void;
  testId?: string;
}

export const PersonalInformationForm = ({
  accountHolder,
  accountUuid,
  accountId,
  onCancel,
  onSave,
  isPrimary,
}: PersonalInformationFormProps) => {
  const dispatch = useDispatch();

  const authUser: UserDto = useSelector((state: any) => state.user.authenticated.data);

  const isPatchLoading = useSelector((state: any) => Boolean(state.accountHolders.patch.__requested));
  const succeededPatch = useSelector((state: any) => state.accountHolders.patch?.__succeeded);
  const requestData = useSelector((state: any) => state.accountHolders.patch?.data);

  const [shouldPatch, setShouldPatch] = useState<boolean>(false);
  const formRef = useRef<FormikProps<AccountHolderFormValues> | null>(null);

  const [isConfirmCreateModalVisible, setIsConfirmCreateModalVisible] = useState<boolean>(false);

  const isRequestMatched = () => accountHolder?.id === requestData?.params?.accountHolderId;

  const isUpsertLoading = isPatchLoading && isRequestMatched();

  const isAlreadySaved = () => Boolean(accountHolder?.dateOfBirth);

  const _onCancel = (form: FormikProps<AccountHolderFormValues>) => {
    form.resetForm();

    if (onCancel) {
      onCancel();
    }
  };

  const _onSave = (form: FormikProps<AccountHolderFormValues>) => {
    form.submitForm();

    if (onSave) {
      onSave();
    }
  };

  useEffect(() => {
    if (isAlreadySaved()) {
      setShouldPatch(true);
    }
  }, [accountHolder]);

  useEffect(() => {
    if (succeededPatch && isRequestMatched()) {
      if (onCancel) {
        onCancel();
      }
    }
  }, [succeededPatch]);

  useEffect(() => {
    return () => {
      dispatch(doClearPatchAccountHolder());
    };
  }, []);

  return (
    <>
      <Formik<AccountHolderFormValues>
        validateOnChange
        innerRef={ref => {
          formRef.current = ref;
        }}
        onSubmit={values => {
          assertNonNullable(accountHolder, 'AccountHolder');
          assertNonNullable(accountUuid, 'accountUuid');

          if (accountId) {
            // TODO: sort the phones arrays before comparing
            const arePhonesEqual = isEqual(
              sortBy(values.phones, 'type')?.map(anItem => ({
                type: anItem.type,
                phoneNumber: anItem.value,
              })),
              sortBy(accountHolder.phones, 'type'),
            );

            dispatch(
              doPatchAccountHolder({
                params: {
                  id: accountUuid,
                  accountHolderId: accountHolder?.id,
                },
                body: {
                  numberOfDependents: Number(values.numberOfDependents),
                  maritalStatus: values.maritalStatus,
                  email: isPrimary ? undefined : isEqual(values.email, accountHolder.email) ? undefined : values.email,
                  phones: isPrimary
                    ? undefined
                    : arePhonesEqual
                    ? undefined
                    : values.phones?.map(anItem => ({
                        type: anItem.type,
                        phoneNumber: `${anItem.prefix}${anItem.value}`,
                      })),
                },
              }),
            );

            return;
          }

          if (!accountId && shouldPatch) {
            dispatch(
              doPatchAccountHolder({
                params: {
                  id: accountUuid,
                  accountHolderId: accountHolder?.id,
                },
                body: {
                  firstName: values.firstName,
                  middleName: values.middleName ? values.middleName : null,
                  lastName: values.lastName,
                  suffix: values.suffix ? values.suffix : null,
                  dateOfBirth: values.dateOfBirth,
                  ssid: values.ssid,
                  email: isPrimary ? authUser.email : values.email,
                  phones: isPrimary
                    ? [{ type: PhoneNumberTypeDto.Mobile, phoneNumber: authUser.phoneNumber }]
                    : values.phones?.map(anItem => ({
                        type: anItem.type,
                        phoneNumber: `${anItem.prefix}${anItem.value}`,
                      })),
                  numberOfDependents: Number(values.numberOfDependents),
                  maritalStatus: values.maritalStatus,
                },
              }),
            );

            return;
          }
          setIsConfirmCreateModalVisible(true);
        }}
        initialValues={{
          firstName: isPrimary ? accountHolder?.firstName ?? authUser.firstName : accountHolder?.firstName,
          middleName: isPrimary ? accountHolder?.middleName ?? authUser.middleName : accountHolder?.middleName,
          lastName: isPrimary ? accountHolder?.lastName ?? authUser.lastName : accountHolder?.lastName,
          suffix: isPrimary ? accountHolder?.suffix ?? authUser.suffix : accountHolder?.suffix,
          dateOfBirth: accountHolder?.dateOfBirth,
          numberOfDependents: accountHolder?.numberOfDependents?.toString(),
          email: accountHolder?.email,
          confirmEmail: accountHolder?.email,
          phones: isEmpty(accountHolder?.phones)
            ? [
                {
                  type: PhoneNumberTypeDto.Mobile,
                  value: '',
                  prefix: US_PHONE_NUMBER_PREFIX,
                },
              ]
            : accountHolder?.phones?.map(anItem => ({
                prefix: US_PHONE_NUMBER_PREFIX,
                type: anItem.type,
                value: stripPhoneNumberPrefix({ value: anItem.phoneNumber, prefix: US_PHONE_NUMBER_PREFIX }),
              })),
          ssid: accountHolder?.ssid,
          maritalStatus: accountHolder?.maritalStatus?.value,
        }}
        validationSchema={
          Boolean(accountId)
            ? isPrimary
              ? patchAccountHolderValidationSchema
              : patchJointAccountholderValidationSchema
            : isPrimary
            ? createAccountHolderValidationSchema
            : createJointAccountHolderValidationSchema
        }>
        {form => {
          return (
            <>
              <Col span={24}>
                <MFormInput
                  label='First Name'
                  placeholder={shouldPatch ? undefined : 'Enter'}
                  value={form.values.firstName}
                  defaultValue={accountHolder?.firstName}
                  error={form.errors.firstName}
                  disabled={Boolean(accountId)}
                  onChange={value => {
                    form.setFieldValue('firstName', value);
                  }}
                  testId={`${isPrimary ? 'primary' : 'joint'}-account-first-name`}
                />
              </Col>

              <Col span={24}>
                <MFormInput
                  label='Middle Initial or Middle Name'
                  placeholder={shouldPatch ? undefined : 'Enter'}
                  value={form.values.middleName}
                  defaultValue={accountHolder?.middleName}
                  disabled={Boolean(accountId)}
                  error={form.errors.middleName}
                  onChange={value => {
                    form.setFieldValue('middleName', value);
                  }}
                  testId={`${isPrimary ? 'primary' : 'joint'}-account-middle-name`}
                />
              </Col>

              <Col span={24}>
                <MFormInput
                  label='Last Name'
                  placeholder='Enter'
                  value={form.values.lastName}
                  defaultValue={accountHolder?.lastName}
                  error={form.errors.lastName}
                  disabled={Boolean(accountId)}
                  onChange={value => {
                    form.setFieldValue('lastName', value);
                  }}
                  testId={`${isPrimary ? 'primary' : 'joint'}-account-last-name`}
                />
              </Col>

              <Col span={24}>
                <MFormInput
                  label='Suffix (optional)'
                  placeholder={shouldPatch ? undefined : 'Enter'}
                  value={form.values.suffix}
                  defaultValue={accountHolder?.suffix}
                  error={form.errors.suffix}
                  disabled={Boolean(accountId)}
                  onChange={value => {
                    form.setFieldValue('suffix', value);
                  }}
                  testId={`${isPrimary ? 'primary' : 'joint'}-account-suffix`}
                />
              </Col>

              <Col span={24}>
                <MFormInputPassword
                  label='Social Security Number'
                  tooltip={{
                    type: 'modal',
                    title: 'Why do we need this?',
                    content: <SocialSecurityNumberTooltipContent />,
                  }}
                  placeholder='Enter'
                  value={form.values.ssid}
                  defaultValue={accountHolder?.maskedSsid}
                  error={form.errors.ssid}
                  onChange={value => {
                    form.setFieldValue('ssid', value);
                  }}
                  disabled={Boolean(accountId)}
                  testId={`${isPrimary ? 'primary' : 'joint'}-account-ssn`}
                />
              </Col>

              {!isPrimary && (
                <>
                  <Col span={24}>
                    <MFormInput
                      label='Email'
                      placeholder='Enter'
                      value={form.values.email}
                      defaultValue={accountHolder?.email}
                      error={form.errors.email}
                      onChange={value => {
                        form.setFieldValue('email', value);
                      }}
                      testId={`${isPrimary ? 'primary' : 'joint'}-account-email`}
                    />
                  </Col>
                  <Col span={24}>
                    <MFormInput
                      label='Confirm Email'
                      placeholder='Enter'
                      value={form.values.confirmEmail}
                      defaultValue={accountHolder?.email}
                      error={form.errors.confirmEmail}
                      onChange={value => {
                        form.setFieldValue('confirmEmail', value);
                      }}
                      testId={`${isPrimary ? 'primary' : 'joint'}-account-confirm-email`}
                    />
                  </Col>
                </>
              )}

              {!isPrimary && (
                <Col span={24}>
                  <MFormPhoneNumberInput
                    international={false}
                    options={
                      form.values.phones ?? [
                        {
                          type: PhoneNumberTypeDto.Mobile,
                          value: '',
                          prefix: US_PHONE_NUMBER_PREFIX,
                        },
                      ]
                    }
                    max={1}
                    onChange={value => {
                      form.setFieldValue('phones', value);
                    }}
                    error={form.errors.phones}
                    testId={`${isPrimary ? 'primary' : 'joint'}-account-phone-number`}
                  />
                </Col>
              )}

              <Col span={24}>
                <DateOfBirthPicker
                  defaultValue={accountHolder?.dateOfBirth}
                  error={form.errors.dateOfBirth}
                  disabled={Boolean(accountId)}
                  onChange={value => {
                    form.setFieldValue('dateOfBirth', value);
                  }}
                  testId={`${isPrimary ? 'primary' : 'joint'}-account-dob`}
                />
              </Col>

              <Col span={24}>
                <MFormSelect
                  label='Marital Status'
                  options={MARITAL_STATUS_OPTION_LIST}
                  defaultValue={accountHolder?.maritalStatus?.value}
                  error={form.errors.maritalStatus}
                  onChange={value => {
                    form.setFieldValue('maritalStatus', value);
                  }}
                  testId={`${isPrimary ? 'primary' : 'joint'}-account-marital-status`}
                />
              </Col>

              <Col span={24}>
                <MFormInput
                  label='Number of Dependents'
                  placeholder='Enter'
                  value={form.values.numberOfDependents}
                  defaultValue={accountHolder?.numberOfDependents?.toString()}
                  error={form.errors.numberOfDependents}
                  onChange={value => {
                    form.setFieldValue('numberOfDependents', value);
                  }}
                  testId={`${isPrimary ? 'primary' : 'joint'}-account-number-of-dependents`}
                />
              </Col>

              <Col span={24}>
                <MFormSaveButton<AccountHolderFormValues>
                  loading={isUpsertLoading}
                  onCancel={_onCancel}
                  onSave={_onSave}
                  isEditMode={shouldPatch}
                  form={form}
                  testId={`${isPrimary ? 'primary' : 'joint'}-personal-information`}
                />
              </Col>
            </>
          );
        }}
      </Formik>
      <MModal
        title='Saving Personal Information'
        customWidth={520}
        visible={isConfirmCreateModalVisible}
        onClose={() => setIsConfirmCreateModalVisible(false)}
        primaryButtonText='Continue'
        secondaryButtonText='Edit'
        onPrimaryButtonClick={() => {
          setIsConfirmCreateModalVisible(false);
          assertNonNullable(accountHolder, 'AccountHolder');
          assertNonNullable(accountUuid, 'accountUuid');

          dispatch(
            doPatchAccountHolder({
              params: {
                id: accountUuid,
                accountHolderId: accountHolder.id,
              },
              body: {
                firstName: formRef.current?.values?.firstName,
                middleName: formRef.current?.values?.middleName ? formRef.current?.values?.middleName : null,
                lastName: formRef.current?.values?.lastName,
                suffix: formRef.current?.values.suffix ? formRef.current?.values?.suffix : null,
                dateOfBirth: formRef.current?.values?.dateOfBirth,
                ssid: formRef.current?.values?.ssid,
                email: isPrimary ? authUser.email : formRef.current?.values?.email,
                phones: isPrimary
                  ? [{ type: PhoneNumberTypeDto.Mobile, phoneNumber: authUser.phoneNumber }]
                  : formRef.current?.values?.phones?.map(anItem => ({
                      type: anItem.type,
                      phoneNumber: `${anItem.prefix}${anItem.value}`,
                    })),
                maritalStatus: formRef.current?.values?.maritalStatus,
                numberOfDependents: Number(formRef.current?.values?.numberOfDependents),
              },
            }),
          );
        }}
        onSecondaryButtonClick={() => setIsConfirmCreateModalVisible(false)}>
        <>
          Once your acount is completed and your personal information is saved, you will not be able to edit your social
          security number, date of birth, middle initial or middle name and suffix. Please review your personal
          information to make sure everything is accurate.
          <br />
          <br />
          If the information is correct, please click “Continue”.
        </>
      </MModal>
    </>
  );
};
