import { OfferDto, OfferDetailsDto, OfferOptionDto, OfferOptionTypeDto } from 'src/dtos';
import { Offer, OfferDetails, OfferOption } from 'src/models';

export const mapGetOfferOptionListResponseDtoToModel = ({
  offerOptions,
  type,
}: {
  offerOptions: OfferOptionDto[];
  type: OfferOptionTypeDto;
}): OfferOption[] =>
  offerOptions.map((item: OfferOptionDto) => ({
    id: item.id,
    name: item.name,
    type,
  }));

export const mapOfferDtoToModel = (offers: OfferDto[]) => {
  const response: Offer[] = [];
  offers.map((item: OfferDto) => {
    return response.push({
      featured: item.featured,
      id: item.id,
      name: item.name,
      new: item.new,
      isComingSoon: item.isComingSoon,
      isRecurring: item.closeDates.length > 1,
      closeDate: item.closeDate,
      closeDates: item.closeDates.map(aCloseDateString => new Date(aCloseDateString)),
      minimumInvestCurrencyAmt: item.minimumInvestCurrencyAmt,
      yield: item.yield,
      logoUrl: item.logoUrl,
      videoThumbnailUrl: item.videoThumbnailUrl,
      shortUrlRoute: item.shortUrlRoute,
      totalCapitalRaised: item.totalCapitalRaised,
      status: item.status,
      category: item.category,
      industry: item.industry,
      offerType: item.type,
      securityType: item.security,
      assets: item.assets,
      ordersSummation: item.ordersSummation,
      allowAchTransfers: item.allowAchTransfers,
      allowWireTransfers: item.allowWireTransfers,
      allowCheckTransfers: item.allowCheckTransfers,
      allowCardPayments: item.allowCardPayments,
      allowAcatTransfers: item.allowAcatTransfers,
      allowIraContributionWireTransfers: item.allowIraContributionWireTransfers,
      allowIraContributionCheckTransfers: item.allowIraContributionCheckTransfers,
    });
  });

  return response;
};

export const mapOfferDetailsDtoToModel = (offer: OfferDetailsDto): OfferDetails => {
  return {
    assets: offer.assets,
    closeDate: offer.closeDate,
    closeDates: offer.closeDates.map(aCloseDateString => new Date(aCloseDateString)),
    description: offer.description,
    disclaimer: offer.disclaimer,
    documents: offer.documents,
    exitDescription: offer.exitDescription,
    id: offer.id,
    isComingSoon: offer.isComingSoon,
    isExternalOffer: offer.isExternalOffer,
    isRecurring: offer.closeDates.length > 1,
    logoUrl: offer.logoUrl,
    maxTotalRaiseCurrencyAmt: offer.maxTotalRaiseCurrencyAmt,
    minTotalRaiseCurrencyAmt: offer.minTotalRaiseCurrencyAmt,
    maximumInvestCurrencyAmt: offer.maximumInvestCurrencyAmt,
    minimumInvestCurrencyAmt: offer.minimumInvestCurrencyAmt,
    minSharePrice: offer.minSharePrice,
    maxSharePrice: offer.maxSharePrice,
    isConditional: offer.category === 'Conditional',
    name: offer.name,
    new: offer.new,
    pricePerShare: offer.pricePerShare,
    sector: offer.sector,
    subtextOnClosed: offer.subtextOnClosed,
    videoThumbnailUrl: offer.videoThumbnailUrl,
    videoUrl: offer.videoUrl,
    yield: offer.yield,
    yieldDescription: offer.yieldDescription,
    featured: offer.featured,
    ordersSummation: offer.ordersSummation,
    totalCapitalRaised: offer?.totalCapitalRaised,
    status: offer.status,
    category: offer.category,
    industry: offer.industry,
    offerType: offer.type,
    securityType: offer.security,
    startDate: offer.startDate,
    anticipatedStartDate: offer.anticipatedStartDate,
    shortUrlRoute: offer.shortUrlRoute,
    investmentRanges: offer.investmentRanges,
    allowRestrictedPersons: offer.allowRestrictedPersons,
    nonVisibleAppFields: offer.nonVisibleAppFields ?? [],
    recurring: offer.recurring,
    allowAchTransfers: offer.allowAchTransfers,
    allowWireTransfers: offer.allowWireTransfers,
    allowCheckTransfers: offer.allowCheckTransfers,
    allowCardPayments: offer.allowCardPayments,
    cardPaymentAccount: offer.cardPaymentAccount,
    allowAcatTransfers: offer.allowAcatTransfers,
    allowIraContributionWireTransfers: offer.allowIraContributionWireTransfers,
    allowIraContributionCheckTransfers: offer.allowIraContributionCheckTransfers,
    externalDetails: offer.externalDetails,
    subscriptionAgreementId: offer.subscriptionAgreementId,
  };
};
