import React from 'react';

import { Link, useLocation } from 'react-router-dom';

import quoteMediaLogo from '../../assets/images/quote_media_logo.png';
import { MAIN_PATH } from '../../pages/Main';
import { SUMMARY_PATH } from '../../pages/Main/pages/Summary/Summary';
import { logo } from '../../styles/images';

import * as Styles from './Footer.Styles';
import FooterText from './FooterText';

export const footerPages = [
  {
    path: '/terms-of-use',
    title: 'Terms of Use',
  },
  {
    path: '/static/docs/Privacy_Policy_Revision_2025.pdf',
    title: 'NOTICE OF MY IPO, LLC PRIVACY POLICY',
    footerLinkTitle: 'Privacy Policy',
    openSeparately: true,
  },
  {
    path: 'https://legacy.tradingblock.com/Docs/Agreements/tb/MyIPO_Business_Continuity_Plan_Disclosure.pdf',
    title: 'Business Continuity Plan',
    footerLinkTitle: 'Business Continuity Plan',
    openSeparately: true,
  },
  {
    path: 'https://www.tradingblock.com/account/orderqualityreports.aspx',
    title: 'SEC Rule 606',
  },
  {
    path: 'https://legacy.tradingblock.com/Docs/Agreements/tb/AOS_MyIPO_Form_CRS.pdf',
    title: 'Form CRS',
    openSeparately: true,
  },
];

// update whenever a new path with quote media data is added
const SHOW_QUOTEMEDIA_PATHS = ['/securities', '/security-trade', `/${MAIN_PATH}/${SUMMARY_PATH}`];

export const Footer = () => {
  const { pathname } = useLocation();

  const quoteMediaFooter = (
    <div className={Styles.quoteMediaSection}>
      Market data copyright &copy; 2018 <a href='http://www.quotemedia.com/'>Quote Media</a>. Data delayed 15 minutes
      unless otherwise indicated (view &nbsp;
      <a href='http://www.quotemedia.com/legal/tos/#times'>delay times</a> for all exchanges).
      <br />
      Market data powered by <a href='http://www.quotemedia.com/'>Quote Media</a>.{' '}
      <a href='http://www.quotemedia.com/legal/tos/'>Terms of Use</a>
      <br />
      <img src={quoteMediaLogo} alt='Quote Media' className={Styles.quoteMediaLogo} />
    </div>
  );

  const show = SHOW_QUOTEMEDIA_PATHS.some(item => pathname.indexOf(item) !== -1);

  return (
    <div className={Styles.footerMain}>
      <div className={Styles.sectionContainer}>
        <div className={Styles.footerLogoSection}>
          <img src={logo} alt='My IPO logo' className={Styles.footerLogo} />
          <Link to='/about' className={Styles.footerTopLink} target={'_top'}>
            About
          </Link>

          <Link to='/offers' className={Styles.footerTopLink} target={'_top'}>
            Offers
          </Link>
        </div>
        <div>
          <FooterText />
        </div>
        <div className={Styles.subLinksSection}>
          {footerPages.map(({ path, title, openSeparately, footerLinkTitle }: any) =>
            path.startsWith('http') || openSeparately ? (
              <a key={path} rel='noreferrer' href={path} target='_blank' className={Styles.footerSubLink}>
                {footerLinkTitle || title}
              </a>
            ) : (
              <Link
                key={path}
                to={path}
                target={path.startsWith('http') ? '_blank' : '_top'}
                className={Styles.footerSubLink}>
                {footerLinkTitle || title}
              </Link>
            ),
          )}
        </div>
        <div>{show && quoteMediaFooter}</div>
      </div>
    </div>
  );
};
