import { MFormTextField } from 'src/lib';
import { AccountHolder } from 'src/models';

export interface PhysicalAddressViewProps {
  accountHolder?: AccountHolder;
  testId: string;
}

export const PhysicalAddressView = ({ accountHolder, testId }: PhysicalAddressViewProps) => {
  return (
    <>
      <MFormTextField
        label='Address Line 1'
        value={accountHolder?.physicalAddress?.address1}
        testId={`${testId}-account-physical-address1`}
      />
      <MFormTextField
        label='Address Line 2 (Opt.)'
        value={accountHolder?.physicalAddress?.address2}
        testId={`${testId}-account-physical-address2`}
      />
      <MFormTextField
        label='Country'
        value={accountHolder?.physicalAddress?.country.label}
        testId={`${testId}-account-physical-country`}
      />
      <MFormTextField
        label='City'
        value={accountHolder?.physicalAddress?.city}
        testId={`${testId}-account-physical-city`}
      />
      <MFormTextField
        label='State'
        value={accountHolder?.physicalAddress?.state.label}
        testId={`${testId}-account-physical-state`}
      />
      <MFormTextField
        label='Postal Code'
        value={accountHolder?.physicalAddress?.postalCode}
        testId={`${testId}-account-physical-postal-code`}
      />
      <MFormTextField
        label='Citizenship'
        value={accountHolder?.citizenshipCountry?.label}
        testId={`${testId}-account-physical-citizenship`}
      />
      <MFormTextField
        label='Birth Country'
        value={accountHolder?.birthCountry?.label}
        testId={`${testId}-account-physical-birth-country`}
      />
    </>
  );
};
