import { isEmpty, isUndefined } from 'lodash';
import { AccountConstant } from 'src/constants';
import { Account } from 'src/models';
import { getLocalStorageService } from 'src/services';

const isPrimaryPersonalInformationComplete = (account?: Account) =>
  !isUndefined(account?.primaryAccountHolder?.dateOfBirth);

const isPrimaryPhysicalAddressComplete = (account?: Account) =>
  !isEmpty(account?.primaryAccountHolder?.physicalAddress);

const isPrimaryMailingAddressComplete = (account?: Account) => !isEmpty(account?.primaryAccountHolder?.mailingAddress);

const isJointPersonalInformationComplete = (account?: Account) =>
  !isUndefined(account?.secondaryAccountHolder?.dateOfBirth);

const isJointPhysicalAddressComplete = (account?: Account) =>
  !isEmpty(account?.secondaryAccountHolder?.physicalAddress);

const isJointMailingAddressComplete = (account?: Account) => !isEmpty(account?.secondaryAccountHolder?.mailingAddress);

const isPrimaryAccountHolderPersonalInformationComplete = (account?: Account) =>
  isPrimaryPersonalInformationComplete(account) &&
  isPrimaryPhysicalAddressComplete(account) &&
  isPrimaryMailingAddressComplete(account);

const isJointAccountHolderPersonalInformationComplete = (account?: Account) =>
  isJointPersonalInformationComplete(account) &&
  isJointPhysicalAddressComplete(account) &&
  isJointMailingAddressComplete(account);

export const isAccountPersonalInformationComplete = (account?: Account) => {
  if (account?.type.isJoint) {
    return (
      isPrimaryAccountHolderPersonalInformationComplete(account) &&
      isJointAccountHolderPersonalInformationComplete(account)
    );
  }

  return isPrimaryAccountHolderPersonalInformationComplete(account);
};

export const isAccountTrustedContactComplete = (account?: Account) => {
  if (!account) {
    return false;
  }

  if (account?.type.isJoint && account?.jointAccountType?.isTenantsInCommon) {
    return (
      !isUndefined(account?.primaryAccountHolder?.jointTenantsInCommonInterestPercent) &&
      !isUndefined(account?.secondaryAccountHolder?.jointTenantsInCommonInterestPercent)
    );
  }

  return true;
};

export const isAccountSuitabilityInformationComplete = (account?: Account) => {
  if (!account) {
    return false;
  }

  return !isEmpty(account?.primaryAccountHolder?.suitabilityInformation);
};

const isAssetsInformationComplete = (account?: Account) =>
  !isUndefined(account?.primaryAccountHolder?.financialInformation?.annualIncome);

const isPrimaryEmploymentComplete = (account?: Account) =>
  !isUndefined(account?.primaryAccountHolder?.financialInformation?.employmentStatus);

const isJointEmploymentComplete = (account?: Account) =>
  !isUndefined(account?.secondaryAccountHolder?.financialInformation?.employmentStatus);

const isPrimaryAccountHolderFinancialInformationComplete = (account?: Account) =>
  isAssetsInformationComplete(account) && isPrimaryEmploymentComplete(account);

const isJointAccountHolderFinancialEmploymentComplete = (account?: Account) => isJointEmploymentComplete(account);

export const isAccountFinancialInformationComplete = (account?: Account) => {
  if (account?.type.isJoint) {
    return (
      isPrimaryAccountHolderFinancialInformationComplete(account) &&
      isJointAccountHolderFinancialEmploymentComplete(account)
    );
  }

  return isPrimaryAccountHolderFinancialInformationComplete(account);
};

const isPrimaryDisclosureComplete = (account?: Account) =>
  !isUndefined(account?.primaryAccountHolder?.disclosure?.statementDelivery?.value);

const isJointDisclosureComplete = (account?: Account) =>
  !isUndefined(account?.secondaryAccountHolder?.disclosure?.statementDelivery?.value);

export const isAccountDisclosureComplete = (account?: Account) => {
  if (account?.type.isJoint) {
    return isPrimaryDisclosureComplete(account) && isJointDisclosureComplete(account);
  }

  return isPrimaryDisclosureComplete(account);
};

export const isAccountAgreementsComplete = (account?: Account) => {
  return 'true' === getLocalStorageService().find(`${AccountConstant.SIGN_APPLICATION_PREFIX_KEY}-${account?.id}`);
};

export const isAccountComplete = (account?: Account) => {
  if (!account) {
    return false;
  }

  return (
    isAccountPersonalInformationComplete(account) &&
    isAccountTrustedContactComplete(account) &&
    isAccountSuitabilityInformationComplete(account) &&
    isAccountFinancialInformationComplete(account) &&
    isAccountDisclosureComplete(account) &&
    isAccountAgreementsComplete(account)
  );
};

export const getAccountFundingInstructionsTitle = (account?: Account) => {
  if (!account?.accountNumber) {
    return `My IPO Account Number, Account Holder’s Name`;
  }

  if (account?.type.isJoint) {
    if (account.primaryAccountHolder?.lastName === account.secondaryAccountHolder?.lastName) {
      return `${account?.accountNumber}, ${account?.primaryAccountHolder?.lastName}, ${account?.primaryAccountHolder?.lastName} and ${account?.secondaryAccountHolder?.firstName}`;
    }

    return `${account?.accountNumber}, ${account?.primaryAccountHolder?.lastName}, ${account?.primaryAccountHolder?.firstName} and ${account?.secondaryAccountHolder?.lastName}, ${account?.secondaryAccountHolder?.firstName}`;
  }

  return `${account?.accountNumber}, ${account?.primaryAccountHolder?.lastName}, ${account?.primaryAccountHolder?.firstName}`;
};
