import { useEffect, useState } from 'react';

import { Col, Row, Select } from 'antd';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { OfferOrderConstant } from 'src/constants';
import { OfferOptionTypeDto } from 'src/dtos';
import { ClosedOffersTag, WhatsAccreditedInvestorModal } from 'src/lib';
import { Offer, OfferOption, OfferOrder } from 'src/models';
import { isOfferClosed } from 'src/utils';

import { doGetAllOfferOptions, getOfferList } from '../../../actions';
import { useOfferOrders } from '../../../hooks/useOfferOrders';
import { useOfferWatches } from '../../../hooks/useOfferWatches';
import { DropdownItemType } from '../../../lib/FormComponents/Dropdown/constants';
import Spinner from '../../../lib/Miscellaneous/Spinner';
import FeaturedOffer from '../components/FeaturedOfferCard';
import OfferCard from '../components/OfferCard';

import { ALL, filterOffers, orderByFilter, sortOffers } from './constants';
import * as Styles from './OfferListPage.styles';

const { Option } = Select;

export const OfferListPage = () => {
  const dispatch = useDispatch();
  const offersList: Offer | undefined = useSelector((state: any) => state.offers.offersList?.data);
  const loadingOffersList = _.isDate(useSelector((state: any) => state.offers.offersList?.__requested));

  const offerOptions: OfferOption[] | undefined = useSelector((state: any) => state.offers.options?.data);
  const isOfferOptionsLoading: boolean = useSelector((state: any) => state.offers.options?.isLoading);

  const [orderBy, setOrderBy] = useState<OfferOptionTypeDto>(orderByFilter[0].value);
  const [show, setShow] = useState<string>(ALL);
  const [featuredOffer, setFeaturedOffer] = useState<any>(undefined);
  const [isWhatsAccreditedInvestorOpen, setIsWhatsAccreditedInvestorOpen] = useState<boolean>(false);

  const { offerOrderList, isOfferOrderListLoading } = useOfferOrders();
  const { offerWatchList, isOfferWatchListLoading } = useOfferWatches();

  const pendingOfferOrders = offerOrderList.filter(anOfferOrder =>
    OfferOrderConstant.PENDING_STATUSES.includes(anOfferOrder.status.value),
  );

  const isOfferWatched = (offerId: string) => offerWatchList.find(anOfferWatch => anOfferWatch.offerId === offerId);

  const checkFeaturedOffers = () => {
    let featuredOffer = Array.isArray(offersList)
      ? offersList?.filter((offer: Offer) => offer.featured && !isOfferClosed(offer))[0]
      : null;
    if (!featuredOffer) return setFeaturedOffer(undefined);
    const hasPendingOfferOrders =
      pendingOfferOrders.filter((item: OfferOrder) => item.offerId === featuredOffer.id)?.length > 0;
    featuredOffer = {
      ...featuredOffer,
      hasPendingOfferOrders,
    };

    return setFeaturedOffer(featuredOffer);
  };

  useEffect(() => {
    if (offersList && !loadingOffersList && offerOrderList && !isOfferOrderListLoading) {
      checkFeaturedOffers();
    }
  }, [offersList, loadingOffersList, offerOrderList, isOfferOrderListLoading]);

  useEffect(() => {
    if (_.isEmpty(offersList) && !loadingOffersList && !Array.isArray(offersList)) {
      dispatch(getOfferList());
    }
  }, []);

  useEffect(() => {
    if (_.isEmpty(offerOptions) && !isOfferOptionsLoading) {
      dispatch(doGetAllOfferOptions());
    }
  }, []);

  if (loadingOffersList || isOfferOrderListLoading || isOfferWatchListLoading || isOfferOptionsLoading)
    return <Spinner />;

  return (
    <Row className={Styles.mainContainer} gutter={[, { xs: 16, sm: 20, md: 24 }]}>
      {featuredOffer && (
        <Col span={24}>
          <p className={Styles.sectionLabel}>FEATURED OFFER</p>
          <FeaturedOffer
            featuredOffer={featuredOffer}
            setIsWhatsAccreditedInvestorOpen={setIsWhatsAccreditedInvestorOpen}
          />
        </Col>
      )}
      <Col span={24}>
        <Row
          gutter={[
            { xs: 16, sm: 24, md: 32 },
            { xs: 16, sm: 24, md: 32 },
          ]}>
          <Col flex='5'>
            <span className={Styles.sectionLabel}>OFFERS</span>
          </Col>
          <Col flex={'auto'} className={Styles.dropDownContainer}>
            <span>Order By:</span>
            <Select
              getPopupContainer={trigger => trigger.parentElement}
              style={Styles.dropDownCustomStyles}
              defaultValue={orderBy}
              onChange={(value: OfferOptionTypeDto) => {
                setOrderBy(value);
                setShow(ALL);
              }}
              bordered={false}
              data-testid={'offers-select-order-by'}>
              {orderByFilter.map((option: DropdownItemType) => (
                <Option key={`orderBy_menu_item_${option.value}`} value={option.value}>
                  {option.text}
                </Option>
              ))}
            </Select>
          </Col>
          {Array.isArray(offerOptions) && (
            <Col className={Styles.dropDownContainer} flex={'auto'}>
              <span>Show:</span>
              <Select
                getPopupContainer={trigger => trigger.parentElement}
                style={Styles.dropDownCustomStyles}
                value={show}
                onChange={(value: string) => {
                  setShow(value);
                }}
                bordered={false}
                data-testid={'offers-select-order-by-types'}>
                {[
                  { value: ALL, text: 'All' },
                  ...offerOptions
                    ?.filter((anOption: OfferOption) => anOption.type === orderBy)
                    .map((anOption: OfferOption) => ({ value: anOption.name, text: anOption.name })),
                ].map((option: { value: string; text: string }) => (
                  <Option key={`show_menu_item_${option.value}`} value={option.value}>
                    {option.text}
                  </Option>
                ))}
              </Select>
            </Col>
          )}
        </Row>
      </Col>
      <Col span={24}>
        <Row
          gutter={[
            { xs: 6, sm: 8, md: 16 },
            { xs: 6, sm: 8, md: 16 },
          ]}
          data-testid={'offers-table'}>
          {offersList &&
            Array.isArray(offersList) &&
            offersList
              .filter((offer: Offer) => !offer.featured)
              .filter((offer: Offer) => filterOffers(offer, orderBy, show))
              .sort((offer1: Offer, offer2: Offer) => sortOffers(offer1, offer2, orderBy))
              .map((offer: Offer) => {
                const newOfferObject = {
                  ...offer,
                  hasPendingOfferOrders:
                    pendingOfferOrders?.filter((item: OfferOrder) => item.offerId === offer.id)?.length > 0,
                };

                return (
                  <Col key={newOfferObject.id} xs={24} sm={12} lg={8}>
                    <OfferCard
                      key={newOfferObject.id}
                      offer={newOfferObject}
                      offerWatch={isOfferWatched(newOfferObject.id)}
                      setIsWhatsAccreditedInvestorOpen={setIsWhatsAccreditedInvestorOpen}
                    />
                  </Col>
                );
              })}
        </Row>
      </Col>
      <Col span={24}>
        <ClosedOffersTag />
      </Col>
      <WhatsAccreditedInvestorModal
        isOpen={isWhatsAccreditedInvestorOpen}
        onClose={() => setIsWhatsAccreditedInvestorOpen(false)}
      />
    </Row>
  );
};
