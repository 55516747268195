import { EnvConstant } from '../configs/env.configs';

const { CC_API_BASE_URL } = EnvConstant;

/**
 * Authentication & Registration URLs
 *
 */
export const SIGN_UP = `${CC_API_BASE_URL}/users/sign-up`;
export const LOGIN = `${CC_API_BASE_URL}/users/login`;
export const GET_AUTHENTICATED_USER = `${CC_API_BASE_URL}/users/me`;
export const SAVE_ADDRESS = `${CC_API_BASE_URL}/users/address`;
export const ENABLE_MFA = `${CC_API_BASE_URL}/users/settings/mfa-preferences/enable`;
export const DISABLE_MFA = `${CC_API_BASE_URL}/users/settings/mfa-preferences/disable`;
export const MFA_CHALLENGE = `${CC_API_BASE_URL}/users/login/respond-challenge`;
export const FORGOT_PASSWORD = `${CC_API_BASE_URL}/users/forgot-password`;
export const RESET_PASSWORD = `${CC_API_BASE_URL}/users/reset-password`;
export const EMAIL_CHANGE = `${CC_API_BASE_URL}/users/settings/email`;
export const EMAIL_CONFIRM = `${EMAIL_CHANGE}/confirm-update`;
export const UPDATE_PHONE_NUMBER = `${CC_API_BASE_URL}/users/settings/phone-number`;

/**
 * Account Details
 */

export namespace AccountUrlConstant {
  const BASE_URL = `${CC_API_BASE_URL}/accounts`;

  export const CREATE = `${BASE_URL}`;
  export const RETRIEVE = `${BASE_URL}/{id}`;
  export const PATCH = `${BASE_URL}/{id}`;
  export const PATCH_JOINT_INTEREST = `${BASE_URL}/{id}/joint-interest`;
  export const PATCH_BENEFICIARIES = `${BASE_URL}/{id}/beneficiaries`;
  export const SWITCH = `${BASE_URL}/{id}/switch`;
  export const SUBMIT = `${BASE_URL}/{id}/submit`;
  export const DOWNLOAD_AGREEMENT = `${BASE_URL}/{id}/agreement`;
  export const DOWNLOAD_SIGNATURE = `${BASE_URL}/{id}/signatures`;
  export const SYNC_STATUS_LIST = `${BASE_URL}/sync-status`;
}

export namespace AccountHolderUrlConstant {
  const BASE_URL = `${CC_API_BASE_URL}/accounts/{id}/account-holders/{accountHolderId}`;
  const ACCOUNT_BASE_URL = `${CC_API_BASE_URL}/accounts/{id}`;

  export const PATCH = `${BASE_URL}`;
  export const PHYSICAL_ADDRESS = `${BASE_URL}/physical-address`;
  export const MAILING_ADDRESS = `${BASE_URL}/mailing-address`;
  export const TRUSTED_CONTACT = `${ACCOUNT_BASE_URL}/trusted-contact`;
  export const SUITABILITY = `${ACCOUNT_BASE_URL}/suitability-information`;
  export const FINANCIAL_ASSET = `${ACCOUNT_BASE_URL}/financial-asset`;
  export const FINANCIAL_EMPLOYMENT = `${BASE_URL}/financial-employment`;
  export const DISCLOSURE = `${BASE_URL}/disclosure`;

  export const LIST_UPDATE_REQUESTS = `${BASE_URL}/update-requests`;
  export const APPROVE_UPDATE_REQUEST = `${BASE_URL}/update-requests/{requestId}/approve`;
  export const REJECT_UPDDATE_REQUEST = `${BASE_URL}/update-requests/{requestId}/reject`;
}

// Balances
export const BALANCE_API = `${CC_API_BASE_URL}/balances`;
export const GET_ACCOUNT_BALANCE = `${BALANCE_API}/{accountId}`;
export const GET_ACCOUNT_BALANCE_LIST = `${BALANCE_API}`;

/**
 * Cashiering
 */
export const GET_ACH_RELATIONSHIPS_URL = `${CC_API_BASE_URL}/cashiering-ach/relationships/{accountId}`;
export const CREATE_ACH_RELATIONSHIPS_URL = `${CC_API_BASE_URL}/cashiering-ach/{accountId}/create`;
export const GET_PLAID_LINK_TOKEN_URL = `${CC_API_BASE_URL}/cashiering-plaid/{accountId}`;
export const UNLINK_BANK_ACCOUNT_URL = `${CC_API_BASE_URL}/cashiering-ach/{accountId}/cancel`;
export const EDIT_NICKNAME_URL = `${CC_API_BASE_URL}/cashiering-ach/{accountId}/update`;
export const DEPOSIT_FUNDS_URL = `${CC_API_BASE_URL}/cashiering-transfer/deposit/{accountId}`;
export const WITHDRAW_FUNDS_URL = `${CC_API_BASE_URL}/cashiering-transfer/withdrawal/{accountId}`;
export const GET_TRANSFERS_URL = `${CC_API_BASE_URL}/cashiering-transfer/{accountId}/transfers`;
export const GET_DEPOSITS_URL = `${CC_API_BASE_URL}/cashiering-transfer/{accountId}/deposits`;
export const GET_WITHDRAWALS_URL = `${CC_API_BASE_URL}/cashiering-transfer/{accountId}/withdrawals`;
export const GET_TRANSACTION_DETAILS_URL = `${CC_API_BASE_URL}/cashiering-transfer/{accountId}/transfer/{transactionId}`;
export const CANCEL_DEPOSIT_URL = `${CC_API_BASE_URL}/cashiering-transfer/{accountId}/transfer/{transactionId}/cancel`;
export const CANCEL_WITHDRAW_URL = `${CC_API_BASE_URL}/cashiering-transfer/{accountId}/transfer/{transactionId}/cancel`;

/**
 * Offers
 */
export const GET_OFFERS_URL = `${CC_API_BASE_URL}/offers`;
export const GET_OFFER_DETAILS_URL = `${CC_API_BASE_URL}/offers/{id}`;
export const GET_OFFER_OPTIONS = `${CC_API_BASE_URL}/offer-options/{type}`;

export const OFFER_WATCHES = `${CC_API_BASE_URL}/offer-watches`;
export const DELETE_OFFER_WATCH = `${OFFER_WATCHES}/{offerWatchId}`;

/**
 * Investigations
 */
export const INVESTIGATIONS_URL = `${CC_API_BASE_URL}/investigations/{accountId}`;

export namespace InvestigationUrlConstant {
  const BASE_URL = `${CC_API_BASE_URL}/investigations/{accountId}`;

  export const LIST = `${BASE_URL}`;
  export const APPEAL = `${BASE_URL}/investigation/appeal`;
  export const REJECT = `${BASE_URL}/investigation/reject`;
  export const SUBMIT_DOCUMENT = `${BASE_URL}/investigation/submit/{miscDocumentId}`;
  export const UPLOAD_DOCUMENT = `${BASE_URL}/investigation/{investigationId}/documents`;
  export const DOWNLOAD_DOCUMENT = `${BASE_URL}/investigation/{investigationId}/documents/{documentId}`;
  export const DELETE_DOCUMENT = `${BASE_URL}/investigation/{miscDocumentId}`;

  export const CONTINUE_APPLICATION = `${BASE_URL}/application/continue`;
  export const SUBMIT_APPLICATION_DOCUMENT = `${BASE_URL}/application/submit/{miscDocumentId}`;
  export const UPLOAD_APPLICATION_DOCUMENT = `${BASE_URL}/application/{accountHolderId}/documents`;
  export const DOWNLOAD_APPLICATION_DOCUMENT = `${BASE_URL}/application/{accountHolderId}/documents/{documentId}`;
  export const DELETE_APPLICATION_DOCUMENT = `${BASE_URL}/application/{miscDocumentId}`;
}

/**
 * Configs
 */
export const CONFIGS_URL = `${CC_API_BASE_URL}/configs`;

/**
 * Private Quotes
 */
const PRIVATE_QUOTES_BASE_URL = `${CC_API_BASE_URL}/private-quotes`;
export const GET_PRIVATE_QUOTES_LIST_URL = `${PRIVATE_QUOTES_BASE_URL}`;
export const GET_PRIVATE_QUOTES_SNAP_URL = `${PRIVATE_QUOTES_BASE_URL}/snap`;

/**
 * Quote Media
 */
export const QUOTE_MEDIA_URL = `${CC_API_BASE_URL}/quote-media`;
export const QUOTE_MEDIA_DATA_URL = `${QUOTE_MEDIA_URL}/snap-quotes/{symbol}`;
export const QUOTE_MEDIA_ENHANCED_DATA_URL = `${QUOTE_MEDIA_URL}/enhanced-snap-quotes/{symbol}`;
export const QUOTE_MEDIA_CHART_DATA_URL = `${QUOTE_MEDIA_URL}/enhanced-chart-data/{symbol}`;

/**
 * Orders
 */
export const ORDERS_BASE_URL = `${CC_API_BASE_URL}/orders`;
export const ORDERS_BY_ACCOUNT_URL = `${ORDERS_BASE_URL}/accounts/{accountId}`;
export const ORDERS_POSITIONS_URL = `${ORDERS_BY_ACCOUNT_URL}/positions`;
export const ORDER_URL = `${ORDERS_BASE_URL}/{id}/accounts/{accountId}`;
export const ORDER_CANCEL_URL = `${ORDER_URL}/cancel`;

/**
 * Offer Orders
 */
export const OFFER_ORDERS_BASE_URL = `${CC_API_BASE_URL}/offer-orders`;
export const OFFER_ORDERS_BY_ACCOUNT_URL = `${OFFER_ORDERS_BASE_URL}/accounts/{accountId}`;
export const OFFER_ORDERS_BY_ID_URL = `${OFFER_ORDERS_BASE_URL}/{id}`;
export const OFFER_ORDERS_STATUS_URL = `${OFFER_ORDERS_BASE_URL}/status`;
export const UPDATE_OFFER_ORDER = `${OFFER_ORDERS_BASE_URL}/{id}`;
export const DELETE_OFFER_ORDER = `${OFFER_ORDERS_BASE_URL}/{id}`;
export const CREATE_OFFER_ORDER_CARD_PAYMENT_INTENT_URL = `${OFFER_ORDERS_BASE_URL}/card-payment-intents`;
export const UPDATE_OFFER_ORDER_CARD_PAYMENT_INTENT_URL = `${OFFER_ORDERS_BASE_URL}/card-payment-intents/{id}`;

/**
 * Statements
 */
export const GET_TRADE_CONFIRMATION_LIST_URL = `${CC_API_BASE_URL}/statements/{accountId}/trade-confirmations`;
export const DOWNLOAD_TRADE_CONFIRMATION_URL = `${CC_API_BASE_URL}/statements/{accountId}/trade-confirmations/{date}`;
export const GET_MONTHLY_STATEMENT_URL = `${CC_API_BASE_URL}/statements/{accountId}/monthly-statements`;
export const DOWNLOAD_MONTHLY_STATEMENT_URL = `${CC_API_BASE_URL}/statements/{accountId}/monthly-statements/{date}`;
export const GET_TAX_DOCUMENTS_URL = `${CC_API_BASE_URL}/statements/{accountId}/tax-documents`;
export const DOWNLOAD_TAX_DOCUMENT_URL = `${CC_API_BASE_URL}/statements/{accountId}/tax-documents/{date}/forms/{form}`;

export namespace DocumentUriConstant {
  export const MY_IPO_FEE_SCHEDULE = '/static/docs/My_IPO_Fee_Schedule.pdf';
  export const IRA_BENEFICIARY_DESIGNATION_FORM = '/static/docs/IRA_Beneficiary_Designation_Form.pdf';
}
