import React from 'react';

import { Link } from 'react-router-dom';
import { DocumentUriConstant } from 'src/constants';
import { SecAndTafFeesDescription } from 'src/lib/SecAndTafFeesDescription/SecAndTafFeesDescription';

const faqAnswers = {
  thebasics: [
    {
      question: 'Who is My IPO?',
      answerComp: () => (
        <div>
          My IPO is the online offering platform division of&nbsp;
          <a href='https://www.tradingblock.com/' target='_blank' rel='noopener noreferrer'>
            AOS, Inc. which does business as TradingBlock and My IPO.
          </a>
          &nbsp; AOS, Inc. is a registered Broker/Dealer and member &nbsp;
          <a href='https://brokercheck.finra.org/firm/summary/128605' target='_blank' rel='noopener noreferrer'>
            FINRA
          </a>
          /
          <a href='https://www.sipc.org/' target='_blank' rel='noopener noreferrer'>
            SIPC
          </a>
          . All securities are only offered by AOS, Inc. dba TradingBlock and My IPO. You can check the background of
          our firm on &nbsp;
          <a href='https://brokercheck.finra.org/firm/summary/128605' target='_blank' rel='noopener noreferrer'>
            FINRA&#39;s BrokerCheck
          </a>
          . Our mission is to level the playing field by giving all investors access to some of the most exciting IPOs
          and other Offerings.
        </div>
      ),
    },
    {
      question: 'How do I contact My IPO?',
      answerComp: () => (
        <div>
          <div>
            If you have any questions please email us at&nbsp;
            <a href='mailto:clientservices@myipo.com' target='_top'>
              clientservices@myipo.com
            </a>
            &nbsp;or give us a call at the telephone number below. We&apos;re available to help from 7:30am-4pm MST,
            Monday through Friday.
            <br />
            <br />
            Our mailing address is:
            <br />
            My IPO
            <br />
            488 East Winchester Street, Suite 200
            <br />
            Salt Lake City, UT 84107
            <br />
            Toll Free: (844) 226-0640 <a href='.'>www.myipo.com</a>
          </div>
        </div>
      ),
    },
    {
      question: 'How do I sign-up for a My IPO account?',
      answerComp: () => (
        <div>
          Signing up for a My IPO account is easy. Simply travel to the&nbsp;
          <Link to='/register'>Sign Up</Link>
          &nbsp;page and follow the instructions on your screen. Once your account is created you can begin to browse
          available Offers and be one of the first to invest in available IPOs and other Offerings.
        </div>
      ),
    },
    {
      question: 'Is there a cost to use My IPO?',
      answerComp: () => (
        <div>
          My IPO sign up is free and there are no associated membership fees to maintain a non-retirement account. For
          all retirement accounts, we pass on an annual custodial fee of $45.00. You are not charged any fees when you
          invest in a My IPO offer. For publicly traded securities, should you buy shares in the open market (outside of
          an offering) or sell shares in the future, we charge a $10 commission if you enter a trade online, or 1% of
          trade value plus $10 commission if you call our Trade Desk to enter trade on your behalf. We also pass along
          certain regulatory fees, such as SEC and TAF fees. To view other fees, pease review the{' '}
          <a href={DocumentUriConstant.MY_IPO_FEE_SCHEDULE} target='_blank' rel='noopener noreferrer'>
            My IPO Fee Schedule
          </a>
          .
          <br />
          <br />
          IMPORTANT NOTE: For non-public securities there may be no liquidity, limited liquidity and redemption fees may
          apply if redeemed before maturity as outlined in each offering prospectus.
        </div>
      ),
    },
    {
      question: 'What are the SEC and TAF fees?',
      answerComp: () => (
        <div>
          <SecAndTafFeesDescription />
        </div>
      ),
    },
    {
      question: 'What is an IPO?',
      answerComp: () => (
        <div>
          An Initial Public Offering &#40;IPO&#41; is the first sale of stock by a private company to the public. If an
          IPO is successful, the shares of the company will be publicly traded on an exchange like the NYSE, NASDAQ or
          OTC.
        </div>
      ),
    },
    {
      question: 'What is Reg A+?',
      answerComp: () => (
        <div>
          Regulation A &#43;&#40;also known as Title IV of The JOBS Act or Reg A &#43;&#41; allows companies to raise up
          to $50 million from both accredited investors and the general public across all 50 states. This regulation is
          similar to a traditional initial public offering &#40;IPO&#41; in that a company solicits investments from the
          general public.
        </div>
      ),
    },
  ],
  accountinformation: [
    {
      question: 'Is there an account minimum?',
      answerComp: () => (
        <div>
          There is no minimum dollar amount to open or maintain an account with My IPO. There are minimum required
          investment amounts on IPOs and other Offerings, which can be found on the Offers section of the website.
        </div>
      ),
    },
    {
      question: 'What account types does My IPO offer?',
      answerComp: () => (
        <div>
          Currently, the My IPO platform allows Individual, Joint and Retirement Accounts (for US investors).
          <br />
          We are working to add other account types to the My IPO platform. In the meantime, if you wish to open an UGMA
          and Entity account enabled to participate in My IPO offerings please visit TradingBlock, our online brokerage
          platform by clicking{' '}
          <a href='https://application.tradingblock.com/?affiliate=MIPO' target='_blank' rel='noreferrer'>
            here
          </a>
          .
        </div>
      ),
    },
    {
      question: 'How do I fund my account?',
      answerComp: () => (
        <div>
          Login to your My IPO account and select <strong>Money Transfers & Preferences</strong> under the drop-down
          arrow in top right-hand corner. Select the method of deposit and follow the instructions given.
        </div>
      ),
    },
    {
      question: 'What if I forgot my password?',
      answerComp: () => (
        <div>
          If your account is not locked, you can reset your password directly from the&nbsp;
          <Link to='/login'>
            <strong>Login page</strong>
          </Link>
          .
          <br />
          <br />
          1. From the Login page, select <strong>Reset Password</strong>
          <br />
          2. Enter your username and select <strong>Continue</strong>
          <br />
          3. Verify your email address shown and select <strong>Reset Password</strong>
          &nbsp;which will send a link to that email address, or contact Customer Service at&nbsp;
          <a href='mailto:clientservices@myipo.com' target='_top'>
            clientservices@myipo.com
          </a>
        </div>
      ),
    },
    {
      question: 'How do I change or update my personal information?',
      answerComp: () => (
        <div>
          To change or update your personal information:
          <br />
          <br />
          a.&nbsp;Login to your My IPO account
          <br />
          b.&nbsp;Select <strong>My Account</strong> under the drop-down arrow in top right-hand corner
          <br />
          c.&nbsp;Select the field you would like to edit and double click on the information you would like to change
          <br />
          <br />
          For security reasons, the following information cannot be changed online:
          <br />
          <br />
          <strong>Name</strong>
          <br />
          To change the name on your account, mail us a letter explaining the reason for the change, along with a copy
          of a marriage certificate, divorce decree, or other court order &#40;as applicable&#41;.
          <br />
          <br />
          <strong>Date of Birth</strong>
          <br />
          To correct your date of birth, mail us a copy of your birth certificate.
          <br />
          <br />
          <strong>Social Security or Tax Identification Number</strong>
          <br />
          To correct your social security or tax identification number, mail us a letter explaining the reason for the
          change, along with a completed&nbsp;
          <a href='https://www.irs.gov/pub/irs-pdf/fw9.pdf' target='_blank' rel='noopener noreferrer'>
            IRS Form W&#8208;9
          </a>
          .
          <br />
          <br />
          <strong>Note:</strong>&nbsp;If you change your home address, we will not send any funds to the new address for
          &#40;15&#41; fifteen days. This allows us to confirm the address change and help protect your account from
          fraud. Contact us directly if you need funds sent to your new address within &#40;15&#41; fifteen days.
        </div>
      ),
    },
    {
      question: 'How will you communicate with me about my account? Will I receive notices or statements?',
      answerComp: () => (
        <div>
          All of our clients receive detailed monthly brokerage account statements. We may also have occasional mailings
          and emails on topics of interest. In addition, we provide investor update meetings and new product offering to
          our clients. Clients are welcome to contact us by telephone, fax, or e&#8208;mail to discuss their investments
          or ask questions, or to schedule an appointment to meet with us at our offices. All trade orders can be placed
          by logging into your account on My IPO.
        </div>
      ),
    },
    {
      question: 'Will my account information remain confidential?',
      answerComp: () => (
        <div>
          We believe that your wealth is an extremely private matter and your identity and financial records are kept
          strictly confidential. No mailing lists are sold, nor is any client information shared with outside firms
          unless directed by you. For more information, please see our&nbsp;
          <Link to='/static/docs/Privacy_Policy_Revision_2025.pdf' target='_blank' rel='noreferrer'>
            privacy policy
          </Link>
          .
        </div>
      ),
    },
    {
      question: 'How do I close an account?',
      answerComp: () => (
        <div>
          Please contact us at&nbsp;
          <a href='mailto:clientservices@myipo.com' target='_top'>
            clientservices@myipo.com
          </a>
          &nbsp;to close your My IPO account.
        </div>
      ),
    },
  ],
  forcompanies: [
    {
      question: 'How do I list my company on My IPO?',
      answerComp: () => (
        <div>
          We make the process simple for investors to participate in your offering. For more information on listing your
          Company on My IPO please contact&nbsp;
          <a href='mailto:clientservices@myipo.com' target='_top'>
            clientservices@myipo.com
          </a>
          .
        </div>
      ),
    },
  ],
  offerandtradinginformation: [
    {
      question: 'How do I cancel a pending transfer or order?',
      answerComp: () => (
        <div>
          To cancel a pending bank transfer or order:
          <br />
          <br />
          1. Login to your My IPO account
          <br />
          2. Select <strong>History</strong>
          <br />
          3. Select <strong>Cancel</strong> and follow the on screen instructions
        </div>
      ),
    },
    {
      question: 'How do I trade my shares?',
      answerComp: () => (
        <div>
          You may trade shares of publicly listed companies who have completed an IPO or secondary offering with My IPO
          by logging into your account and entering your trade through the Summary page next to your security
          position&#40;s&#41;. For non-public securities there may be no liquidity, limited liquidity and redemption
          fees may apply if redeemed before maturity as outlined in each offering prospectus.
        </div>
      ),
    },
    {
      question: 'Does My IPO offer margin trading?',
      answerComp: () => <div>My IPO currently does not offer margin trading.</div>,
    },
    {
      question: 'Does My IPO offer option trading?',
      answerComp: () => <div>My IPO currently does not offer option trading.</div>,
    },
    {
      question: 'Does My IPO allow short selling?',
      answerComp: () => <div>My IPO currently does not offer short selling.</div>,
    },
    {
      question: 'Does My IPO solicit trades?',
      answerComp: () => (
        <div>
          My IPO does not solicit trades or advise My IPO users of the suitability of any trade, IPO, or other offering.
          Investors who trade through the My IPO platform make their own trading and investment decisions. Investors
          seeking the assistance of one of our registered representatives will be required to provide additional
          information to determine a customer&apos;s suitability and investment objectives&nbsp;
          <strong>BEFORE</strong>&nbsp; any solicited trade is initiated. For additional information on suitability,
          please review the Suitability FAQ section.
        </div>
      ),
    },
  ],
  investmentinformation: [
    {
      question: 'What are the risks of investing?',
      answerComp: () => (
        <div>
          Company listings on My IPO apps and/or websites are only suitable for investors who are familiar with and
          willing to accept the high risk associated with private investments and early stage growth companies.
          Securities sold through private placements are not publicly traded and are intended for investors who do not
          have a need for a liquid investment. There can be no assurance the valuation is accurate or in agreement with
          the market or industry valuations.
          <br />
          <br />
          Additionally, investors may receive restricted stock that may be subject to holding period requirements.
          Companies seeking private placement investments tend to be in earlier stages of development and have not yet
          been fully tested in the public marketplace. Investing in private placements requires high risk tolerance, low
          liquidity concerns, and long-term commitments. Investors must be able to afford to lose their entire
          investment.
          <br />
          <br />
          My IPO encourages its Customers to invest carefully and to use the information available at the websites of
          the SEC at&nbsp;
          <a href='http://www.sec.gov' target='_blank' rel='noopener noreferrer'>
            http://www.sec.gov
          </a>
          &nbsp;and FINRA at&nbsp;
          <a href='http://FINRA.org' target='_blank' rel='noopener noreferrer'>
            http://FINRA.org
          </a>
          .
          <br />
          <br />
          For additional information on suitability, please review the Suitability FAQ section.
        </div>
      ),
    },
    {
      question: 'What is a dividend?',
      answerComp: () => (
        <div>Dividends are payments made by companies to its shareholders from the profits of their business.</div>
      ),
    },
    {
      question: 'What are the major differences between common and preferred stock?',
      answerComp: () => (
        <div>
          Preferred stockholders have greater claim to the company’s assets and earnings than common stockholders. Also,
          the preferred stockholders dividends are generally different and greater than the common shareholders.
        </div>
      ),
    },
    {
      question: 'What are fixed income securities?',
      answerComp: () => (
        <div>
          Securities that yield a regular or fixed return. Bonds, preferred stock and government issued instruments are
          all types of fixed income securities.
        </div>
      ),
    },
    {
      question: 'What is an ex-dividend and record date?',
      answerComp: () => (
        <div>
          The ex&#8208;dividend date is the day on which all shares bought or sold no longer have the right to pay a
          shareholder the most recently declared dividend. The record date is the day shareholders properly registered
          to receive their dividend. Shareholders who do not register as of this date will not receive the dividend.
        </div>
      ),
    },
    {
      question: 'What is an Accredited Investor?',
      answerComp: () => (
        <div>
          Accredited investors are persons who, due to their income and/or net worth, are deemed to have a level of
          financial sophistication such that they require a lesser level of protection under the federal securities
          laws. With regard to individuals, a person shall be deemed to be accredited if they meet at least one of the
          following categories:
          <ul>
            <li>
              Any director, executive officer, or general partner of the issuer of the securities being offered or sold,
              or any director, executive officer, or general partner of a general partner of that issuer;
            </li>
            <li>
              Any natural person whose individual net worth, or joint net worth with that person’s spouse, at the time
              of his purchase exceeds $1,000,000, exclusive of residence; or
            </li>
            <li>
              Any natural person who had an individual income in excess of $200,000 in each of the two most recent years
              or joint income with that person’s spouse in excess of $300,000 in each of those years and has a
              reasonable expectation of reaching the same income level in the current year.
            </li>
          </ul>
        </div>
      ),
    },
  ],
  generalofferinformation: [
    {
      question: "What is a conditional order? Why can't I buy shares?",
      answerComp: () => (
        <div>
          A conditional order is a type of order that only triggers if certain conditions are met. Some of our offers
          require that only conditional orders are placed for a certain period of time. Issuers looking to raise capital
          through Regulation A may utilize a conditional order to “test the waters” to gauge the interest of the crowd
          both and during the filing process with the SEC. During this period, investors may only indicate interest. No
          offer to buy the securities can be accepted and no part of the purchase price can be received until a Form 1-A
          offering statement is qualified pursuant to Regulation A of the Securities Act of 1933, as amended.
          <br />
          <br />
          Issuers looking to raise capital through a traditional IPO or secondary offering may utilize a conditional
          order to take indications of interest from prospective investors to gauge the interest of the offering after
          the filing process with the SEC. During this period, investors may only indicate interest. No offer to buy the
          securities can be accepted and no part of the purchase price can be received until the offering statement is
          effective pursuant to the Securities Act of 1933, as amended.
        </div>
      ),
    },
    {
      question: 'What is a REIT?',
      answerComp: () => (
        <div>
          A real estate investment trust, or REIT, is a corporation, trust or association that owns &#40;and might also
          manage&#41; income&#8208;producing real estate. REITs pool the capital of numerous investors to purchase a
          portfolio of properties &#8212;from office buildings and shopping centers to hotels and apartments, even
          timber&#8208;producing land&#8212;which the typical investor might not otherwise be able to purchase
          individually.
          <br />
          <br />
          REITs can offer tax advantages. For instance, qualified REITs that meet Internal Revenue Service requirements
          can deduct distributions paid to shareholders from corporate taxable income, avoiding double taxation. The
          REIT must also distribute at least 90 percent of its taxable income to shareholders annually. These
          distributions are taxable to the extent of any ordinary income and capital gains included in the distribution.
          <br />
          <br />
          There are two types of public REITS&#58; those that trade on a national securities exchange and those that do
          not. REITs in this latter category are generally referred to as publicly registered non&#8208;exchange traded,
          or simply non&#8208;traded REITS.
        </div>
      ),
    },
    {
      question: 'What is a PIPE, private placement or secondary offering?',
      answerComp: () => (
        <div>
          A PIPE, also known as a Private Investment in Public Equity, is a way for companies to raise capital in the
          public markets in timely and most cost&#8208;efficient manner. A private placement is an offering of
          securities that are not registered with the SEC. A secondary offering is the issuance of shares from a company
          that has already done its initial public offering &#40;IPO&#41;.
        </div>
      ),
    },
    {
      question: 'What is the difference between Reg D 506(b) and Reg D 506(c)?',
      answerComp: () => (
        <div>
          Rule 506&#40;c&#41; is a new exemption that originated from the JOBS Act that allows general solicitation and
          general advertising in connection with private placements of securities. Rule 506&#40;b&#41; is the exemption
          that companies have used for decades to raise capital from preexisting relationships without the use of any
          advertising or general solicitation. Companies can now choose between 506&#40;b&#41;&nbsp;or 506&#40;c&#41;.
          <br />
          <br />
          The main difference with 506&#40;c&#41; is the higher standard for ensuring that every investor is accredited.
          For both 506&#40;b&#41; and 506&#40;c&#41;, you should have a &quot;reasonable belief&quot; that an investor
          is accredited before accepting their investment. With 506&#40;b&#41;, founders often take the investors own
          word, and take relatively few steps toward verifying it. That standard isn&apos;t good enough for
          506&#40;c&#41;; you must also take &quot;reasonable steps&quot; to verify that your investors are accredited
          which include review of financial statements, tax returns or professional letters from lawyers, CPAs,
          registered investment advisers or brokers. If the rules of 506&#40;c&#41; are not properly followed, the
          violation can lead to a one&#8208;year hold on your fundraising efforts and a return of capital to investors.
          <br />
          <br />
          For more, visit&nbsp;
          <a
            href='http://www.sec.gov/info/smallbus/secg/general-solicitation-small-entity-compliance-guide.htm'
            target='_blank'
            rel='noopener noreferrer'
            style={{ wordWrap: 'break-word' }}>
            http://www.sec.gov/info/smallbus/secg/general-solicitation-small-entity-compliance-guide.htm
          </a>
        </div>
      ),
    },
  ],
  regulatoryinformation: [
    {
      question: 'Who is your clearing firm?',
      answerComp: () => (
        <div>
          AOS, Inc. dba My IPO has selected Apex Clearing Corporation as its clearing agent to act as custodian of
          customer assets and provide a variety of back-office services to the firm and its clients. Apex will provide
          all of the standard reports such as customer statements, trade confirmations and tax forms. Apex Clearing
          Corporation is a member of&nbsp;
          <a href='http://FINRA.org' target='_blank' rel='noopener noreferrer'>
            FINRA
          </a>
          &nbsp;and&nbsp;
          <a href='http://www.sipc.org' target='_blank' rel='noopener noreferrer'>
            SIPC
          </a>
          .
        </div>
      ),
    },
    {
      question: 'How is My IPO compensated?',
      answerComp: () => (
        <div>
          My IPO is compensated directly by each company we raise capital for. This allows us to charge no commissions
          to investors when they purchase offerings. The compensation brokerage firms receive is disclosed in each
          company offering document that can be found under &quot;Offering Documents&quot; on My IPO. For publicly
          traded securities there is a $10 trade fee charged should you buy shares in the open market (outside of an
          offering) or sell shares in the future, plus we pass along regulatory fees such as SEC and TAF fees as part of
          the &quot;Commission/Service Fees&quot;. The SEC and TAF fees or &quot;Service Fees&quot; are very minimal
          charges.
        </div>
      ),
    },
    {
      question: 'What is SIPC or Supplemental Insurance?',
      answerComp: () => (
        <div>
          AOS, Inc. dba My IPO is a member of SIPC, which protects securities customers of its members up to $500,000
          (including $200,000 for claims for cash). Explanatory brochure is available upon request or at{' '}
          <a href='http://www.sipc.org' target='_blank' rel='noopener noreferrer'>
            www.sipc.org
          </a>
          .
          <br />
          <br />
          AOS, Inc. dba My IPO clears its brokerage business through Apex Clearing Corporation. In addition to SIPC
          coverage, our clearing firm, Apex Clearing Corporations, has purchased an additional insurance policy to
          supplement SIPC protection. This additional insurance policy, widely known as &quot;excess SIPC,&quot; becomes
          available to customers in the event that SIPC limits are exhausted. This additional insurance provides
          protection for securities and cash up to an aggregate limit of $150 million, subject to sub-limits for any one
          customer of $37.5 million for securities and $900,000 for cash. Neither SIPC nor the insurance coverage
          protect against losses resulting from a decline in the market value of securities.
        </div>
      ),
    },
    {
      question: 'What is the JOBS Act?',
      answerComp: () => (
        <div>
          Written with the intent to encourage funding of small businesses during the initial public offering process,
          the Jumpstart our Business Startups Act &#40; the &quot;JOBS Act&quot;&#41;, as signed by President Obama on
          April 5, 2012, reduces the regulatory burden on small companies seeking to raise capital in the U.S. through
          revisions to the Securities Act of 1933 &#40;the &quot;Securities Act&quot;&#41; and the Securities Exchange
          Act of 1934 &#40;the &quot;Exchange Act&quot;&#41;.
          <br />
          <br />
          As a result, smaller issuers can now take advantage of improved opportunity for public financing and
          development during their initial years as a public company. Private companies also benefit from the JOBS Act
          as a result of several changes to the Securities Act, including the ability to generally solicit and advertise
          in connection with private placements of securities.
        </div>
      ),
    },
    {
      question: 'What is Suitability?',
      answerComp: () => (
        <div>
          Suitability is the determination that a particular investment strategy meets the objectives and means of an
          investor. It goes beyond just the financial resources of an investor to participate as an accredited investor
          in, for example, a private placement, but also encompasses the appropriate knowledge and understanding of
          risk. Investing in the stock market is speculative and investors should understand the risks associated with
          the investment and what is suitable for their investment objectives.
          <br />
          <br />
          All trades, IPOs and other Offerings, with exception of customers who receive advice from one of our
          registered representatives, are not solicited on the My IPO platform. Investors who trade through the My IPO
          platform make their own trading and investment decisions. If one of our registered representatives provides
          investment advice, then additional suitability questions will be asked to determine a customer’s suitability
          and investment objectives&nbsp;
          <strong>BEFORE</strong>&nbsp;any solicited trade is initiated.
          <br />
          <br />
          Before making any investment in a My IPO offering, an investor should consider, for each investment, the
          product or strategy&apos;s investment objectives, characteristics, liquidity risks and potential benefits,
          volatility and likely performance in a variety of market and economic conditions.
        </div>
      ),
    },
    {
      question: 'What is a Registration Statement?',
      answerComp: () => (
        <div>
          A Registration Statement is a set of documents that discloses important financial information regarding the
          registration of securities, including a prospectus, which is filed the SEC so the shares can be sold under
          certain conditions in the public markets.
        </div>
      ),
    },
  ],
};

export default faqAnswers;
