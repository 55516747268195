import { css } from '@emotion/css';

import {
  AlignItems,
  ColorConstant,
  Display,
  FlexDirection,
  FlexWrap,
  Font,
  FontWeight,
  JustifyContent,
  Spacing,
} from '../../../styles';

export const mainContainer = css`
  ${Spacing.mt32}
  ${AlignItems.center}
`;

export const offerCardContainer = css`
  ${Display.flex}
  ${JustifyContent.center}
  ${AlignItems.center}
`;

export const standOfferList = css`
  ${Display.flex}
  ${FlexDirection.row}
  ${FlexWrap.wrap}
  ${Spacing.my32};
  ${Spacing.mx0}
`;

export const sectionLabel = css`
  ${FontWeight.semibold}
  ${Font.h4}
  ${AlignItems.center}
  color: ${ColorConstant.GRAY8};
`;

export const dropDownContainer = css`
  ${Display.flex}
  ${AlignItems.center}
  ${JustifyContent.end}
`;

export const dropDownCustomStyles = { width: 200, marginLeft: 18 };
