import { useEffect, useState } from 'react';

import { cx } from '@emotion/css';
import { Row } from 'antd';
import { isUndefined } from 'lodash';
import { useAccountSelector } from 'src/hooks';
import { MFormSection } from 'src/lib';
import { Heading, Spacing } from 'src/styles';

import { FinancialInformationEmploymentForm } from './FinancialInformationEmploymentForm';
import { FinancialInformationEmploymentView } from './FinancialInformationEmploymentView';

export const AccountHolderFinancialEmploymentSection = () => {
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const { account } = useAccountSelector();

  const onEdit = () => {
    setIsEditMode(true);
  };

  const onCancel = () => {
    setIsEditMode(false);
  };

  const isFinancialInformationEmoloymentAlreadySaved = () =>
    !isUndefined(account?.primaryAccountHolder?.financialInformation?.employmentStatus);

  useEffect(() => {
    if (isFinancialInformationEmoloymentAlreadySaved()) {
      setIsEditable(true);
    }
  }, [account?.primaryAccountHolder?.financialInformation]);

  return (
    <>
      {account?.type?.isJoint && <Row className={cx(Heading.title, Spacing.my32)}>Primary Account Holder</Row>}
      <MFormSection
        title='Employment Information'
        onEdit={onEdit}
        isEditable={isEditable}
        isEditMode={isEditMode}
        testId={'primary-employment-information'}>
        {isFinancialInformationEmoloymentAlreadySaved() && !isEditMode ? (
          <FinancialInformationEmploymentView accountHolder={account?.primaryAccountHolder} testId={'primary'} />
        ) : (
          <FinancialInformationEmploymentForm
            accountHolder={account?.primaryAccountHolder}
            accountUuid={account?.id}
            onCancel={onCancel}
            testId={'primary'}
          />
        )}
      </MFormSection>
    </>
  );
};
