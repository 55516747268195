import { AccountHolderUrlConstant } from 'src/constants';
import {
  GetAccountHolderUpdateRequestListDto,
  ApproveAccountHolderUpdateRequestDto,
  RejectAccountHolderUpdateRequestDto,
  AccountHolderUpdateRequestDto,
  PatchAccountHolderDto,
  CreateAccountHolderPhysicalAddressDto,
  PatchAccountHolderPhysicalAddressDto,
  CreateAccountHolderMailingAddressDto,
  PatchAccountHolderMailingAddressDto,
  CreateAccountHolderTrustedContactDto,
  AccountHolderTrustedContactDto,
  PatchAccountHolderTrustedContactDto,
  DeleteAccountHolderTrustedContactDto,
  CreateAccountHolderSuitabilityInformationDto,
  AccountHolderSuitabilityInformationDto,
  PatchAccountHolderSuitabilityInformationDto,
  CreateAccountHolderFinancialAssetDto,
  AccountHolderFinancialInformationDto,
  PatchAccountHolderFinancialAssetDto,
  CreateAccountHolderFinancialEmploymentDto,
  PatchAccountHolderFinancialEmploymentDto,
  CreateAccountholderDisclosureDto,
  PatchAccountHolderDisclosureDto,
  AccountDto,
} from 'src/dtos';
import { ConfigService } from 'src/services';
import { httpRequest, replacePlaceholders, WithAuthToken } from 'src/utils';

export class AccountHolderApi {
  private _configService: ConfigService;
  constructor(configService: ConfigService) {
    this._configService = configService;
  }

  async patch({ params, body, authToken }: WithAuthToken<PatchAccountHolderDto>): Promise<AccountDto> {
    const url = replacePlaceholders(AccountHolderUrlConstant.PATCH, params);

    return httpRequest<AccountDto>({
      method: 'PATCH',
      url,
      body,
      authToken,
    });
  }

  async createPhysicalAddress({
    params,
    body,
    authToken,
  }: WithAuthToken<CreateAccountHolderPhysicalAddressDto>): Promise<AccountDto> {
    const url = replacePlaceholders(AccountHolderUrlConstant.PHYSICAL_ADDRESS, params);

    return httpRequest<AccountDto>({
      method: 'POST',
      url,
      body,
      authToken,
    });
  }

  async patchPhysicalAddress({
    params,
    body,
    authToken,
  }: WithAuthToken<PatchAccountHolderPhysicalAddressDto>): Promise<AccountDto> {
    const url = replacePlaceholders(AccountHolderUrlConstant.PHYSICAL_ADDRESS, params);

    return httpRequest<AccountDto>({
      method: 'PATCH',
      url,
      body,
      authToken,
    });
  }

  async createMailingAddress({
    params,
    body,
    authToken,
  }: WithAuthToken<CreateAccountHolderMailingAddressDto>): Promise<AccountDto> {
    const url = replacePlaceholders(AccountHolderUrlConstant.MAILING_ADDRESS, params);

    return httpRequest<AccountDto>({
      method: 'POST',
      url,
      body,
      authToken,
    });
  }

  async patchMailingAddress({
    params,
    body,
    authToken,
  }: WithAuthToken<PatchAccountHolderMailingAddressDto>): Promise<AccountDto> {
    const url = replacePlaceholders(AccountHolderUrlConstant.MAILING_ADDRESS, params);

    return httpRequest<AccountDto>({
      method: 'PATCH',
      url,
      body,
      authToken,
    });
  }

  async createTrustedContact({
    params,
    body,
    authToken,
  }: WithAuthToken<CreateAccountHolderTrustedContactDto>): Promise<AccountHolderTrustedContactDto> {
    const url = replacePlaceholders(AccountHolderUrlConstant.TRUSTED_CONTACT, params);

    return httpRequest<AccountHolderTrustedContactDto>({
      method: 'POST',
      url,
      body,
      authToken,
    });
  }

  async patchTrustedContact({
    params,
    body,
    authToken,
  }: WithAuthToken<PatchAccountHolderTrustedContactDto>): Promise<AccountHolderTrustedContactDto> {
    const url = replacePlaceholders(AccountHolderUrlConstant.TRUSTED_CONTACT, params);

    return httpRequest<AccountHolderTrustedContactDto>({
      method: 'PATCH',
      url,
      body,
      authToken,
    });
  }

  async deleteTrustedContact({
    params,
    authToken,
  }: WithAuthToken<DeleteAccountHolderTrustedContactDto>): Promise<void> {
    const url = replacePlaceholders(AccountHolderUrlConstant.TRUSTED_CONTACT, params);

    return httpRequest<void>({
      method: 'DELETE',
      url,
      authToken,
    });
  }

  async createSuitability({
    params,
    body,
    authToken,
  }: WithAuthToken<CreateAccountHolderSuitabilityInformationDto>): Promise<AccountHolderSuitabilityInformationDto> {
    const url = replacePlaceholders(AccountHolderUrlConstant.SUITABILITY, params);

    return httpRequest<AccountHolderSuitabilityInformationDto>({
      method: 'POST',
      url,
      body,
      authToken,
    });
  }

  async patchSuitability({
    params,
    body,
    authToken,
  }: WithAuthToken<PatchAccountHolderSuitabilityInformationDto>): Promise<AccountHolderSuitabilityInformationDto> {
    const url = replacePlaceholders(AccountHolderUrlConstant.SUITABILITY, params);

    return httpRequest<AccountHolderSuitabilityInformationDto>({
      method: 'PATCH',
      url,
      body,
      authToken,
    });
  }

  async createFinancialAsset({
    params,
    body,
    authToken,
  }: WithAuthToken<CreateAccountHolderFinancialAssetDto>): Promise<AccountHolderFinancialInformationDto> {
    const url = replacePlaceholders(AccountHolderUrlConstant.FINANCIAL_ASSET, params);

    return httpRequest<AccountHolderFinancialInformationDto>({
      method: 'POST',
      url,
      body,
      authToken,
    });
  }

  async patchFinancialAsset({
    params,
    body,
    authToken,
  }: WithAuthToken<PatchAccountHolderFinancialAssetDto>): Promise<AccountHolderFinancialInformationDto> {
    const url = replacePlaceholders(AccountHolderUrlConstant.FINANCIAL_ASSET, params);

    return httpRequest<AccountHolderFinancialInformationDto>({
      method: 'PATCH',
      url,
      body,
      authToken,
    });
  }

  async createFinancialEmployment({
    params,
    body,
    authToken,
  }: WithAuthToken<CreateAccountHolderFinancialEmploymentDto>): Promise<AccountDto> {
    const url = replacePlaceholders(AccountHolderUrlConstant.FINANCIAL_EMPLOYMENT, params);

    return httpRequest<AccountDto>({
      method: 'POST',
      url,
      body,
      authToken,
    });
  }

  async patchFinancialEmployment({
    params,
    body,
    authToken,
  }: WithAuthToken<PatchAccountHolderFinancialEmploymentDto>): Promise<AccountDto> {
    const url = replacePlaceholders(AccountHolderUrlConstant.FINANCIAL_EMPLOYMENT, params);

    return httpRequest<AccountDto>({
      method: 'PATCH',
      url,
      body,
      authToken,
    });
  }

  async createDislosure({
    params,
    body,
    authToken,
  }: WithAuthToken<CreateAccountholderDisclosureDto>): Promise<AccountDto> {
    const url = replacePlaceholders(AccountHolderUrlConstant.DISCLOSURE, params);

    return httpRequest<AccountDto>({
      method: 'POST',
      url,
      body,
      authToken,
    });
  }

  async patchDisclosure({
    params,
    body,
    authToken,
  }: WithAuthToken<PatchAccountHolderDisclosureDto>): Promise<AccountDto> {
    const url = replacePlaceholders(AccountHolderUrlConstant.DISCLOSURE, params);

    return httpRequest<AccountDto>({
      method: 'PATCH',
      url,
      body,
      authToken,
    });
  }

  async listUpdateRequests({ params }: GetAccountHolderUpdateRequestListDto): Promise<AccountHolderUpdateRequestDto[]> {
    const url = replacePlaceholders(AccountHolderUrlConstant.LIST_UPDATE_REQUESTS, params);

    return httpRequest<AccountHolderUpdateRequestDto[]>({
      method: 'GET',
      url,
      authToken: this._configService.authToken,
    });
  }

  async approveUpdateRequest({ params }: ApproveAccountHolderUpdateRequestDto): Promise<void> {
    const url = replacePlaceholders(AccountHolderUrlConstant.APPROVE_UPDATE_REQUEST, params);

    return httpRequest<void>({
      method: 'PUT',
      url,
      authToken: this._configService.authToken,
    });
  }

  async rejectUpdateRequest({ params }: RejectAccountHolderUpdateRequestDto): Promise<void> {
    const url = replacePlaceholders(AccountHolderUrlConstant.REJECT_UPDDATE_REQUEST, params);

    return httpRequest<void>({
      method: 'PUT',
      url,
      authToken: this._configService.authToken,
    });
  }
}
