import { css } from '@emotion/css';
import { AlignItems, BorderRadius, ColorConstant, Display, Font, FontWeight, Spacing } from 'src/styles';

export const closedOfferBadge = css`
  ${Font.lg}
  color: ${ColorConstant.GRAY8};
  ${Spacing.mb32}
  background: ${ColorConstant.RED1};
  ${Spacing.p16}
  ${BorderRadius.xs}
`;

export const closedOfferTitle = css`
  ${FontWeight.semibold}
  ${Font.h2}
  ${Display.flex}
  ${AlignItems.center}
  color: ${ColorConstant.RED7};
`;
