import { useEffect } from 'react';

import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import { useFindMinimalDepositAmountForInvestment } from 'src/hooks';
import { MFormCurrencyField } from 'src/lib';
import CardComponent from 'src/lib/Miscellaneous/Card';
import { OfferOrderIntent } from 'src/models';
import { Heading } from 'src/styles';

import * as Styles from './WirePayment.styles';

interface AcatPaymentProps {
  isValid: (value: boolean) => void;
}

export const AcatPayment = ({ isValid }: AcatPaymentProps) => {
  const offerOrderIntent: OfferOrderIntent = useSelector((state: any) => state.offerOrders.intent);
  const minimalDepositAmount = useFindMinimalDepositAmountForInvestment();

  useEffect(() => {
    if (offerOrderIntent.allowAcatTransfers) {
      isValid(true);

      return;
    }

    isValid(false);
  }, []);

  return (
    <div className={Styles.container} data-testid={'confirm-order-modal-wire'}>
      <CardComponent className={Styles.card}>
        <Row>
          <Col span={24} className={Heading.text1}>
            <p>
              Once you Place Order, My IPO will email the{' '}
              <a
                href='https://legacy.tradingblock.com/Admin/Forms/Apex/Account_Transfer/ACAT_Transfer.pdf'
                target='_blank'
                rel='noreferrer'>
                ACAT Form
              </a>{' '}
              for you to complete, sign and return with a copy of your most recent IRA statement from the financial firm
              you would like to transfer funds.
            </p>
            <p>
              A transfer is a non-reportable movement of funds between two retirement accounts of the same type, such as
              transferring money from one Traditional IRA into another Traditional IRA. This type of transfer doesn’t
              generate a tax form and isn&apos;t reported to the IRS.
            </p>
            <p>
              <b>Example:</b> If you transfer a Traditional IRA with XYZ Financial to your Traditional IRA at My IPO.
            </p>
          </Col>
          <Col span={24}>
            <MFormCurrencyField label='Transfer Amount' value={minimalDepositAmount} testId={'acat-transfer-amount'} />
          </Col>
        </Row>
      </CardComponent>
    </div>
  );
};
