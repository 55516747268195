import { useEffect, useState } from 'react';

import { cx } from '@emotion/css';
import { Row } from 'antd';
import { isUndefined } from 'lodash';
import { useAccountSelector } from 'src/hooks';
import { MFormSection } from 'src/lib';
import { Heading, Spacing } from 'src/styles';

import { FinancialInformationEmploymentForm } from './FinancialInformationEmploymentForm';
import { FinancialInformationEmploymentView } from './FinancialInformationEmploymentView';

export const JointAccountHolderFinancialEmploymentSection = () => {
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const { account } = useAccountSelector();

  const onEdit = () => {
    setIsEditMode(true);
  };

  const onCancel = () => {
    setIsEditMode(false);
  };

  const isFinancialInformationEmoloymentAlreadySaved = () =>
    !isUndefined(account?.secondaryAccountHolder?.financialInformation?.employmentStatus);

  useEffect(() => {
    if (isFinancialInformationEmoloymentAlreadySaved()) {
      setIsEditable(true);
    }
  }, [account?.secondaryAccountHolder?.financialInformation]);

  if (!account?.type.isJoint) {
    return null;
  }

  return (
    <>
      <Row className={cx(Heading.title, Spacing.my32)}>Joint Account Holder</Row>
      <MFormSection
        title='Employment Information'
        onEdit={onEdit}
        isEditable={isEditable}
        isEditMode={isEditMode}
        testId={'joint-account-employment-information'}>
        {isFinancialInformationEmoloymentAlreadySaved() && !isEditMode ? (
          <FinancialInformationEmploymentView accountHolder={account?.secondaryAccountHolder} testId={'joint'} />
        ) : (
          <FinancialInformationEmploymentForm
            accountHolder={account?.secondaryAccountHolder}
            accountUuid={account?.id}
            onCancel={onCancel}
            testId={'joint'}
          />
        )}
      </MFormSection>
    </>
  );
};
