import { Col } from 'antd';
import { MFormCheckboxGroupField, MFormRadioGroupField, MFormTextField } from 'src/lib';
import { Account } from 'src/models';
import { Spacing } from 'src/styles';

import { LiquidityNeedsTooltipContent } from './LiquidityNeedsTooltipContent';
import { INVESTMENT_OBJECTIVES_OPTION_LIST, TIME_HORIZON_OPTION_LIST } from './optionList';

export interface SuitabilityInformationViewProps {
  account?: Account;
}

export const SuitabilityInformationView = ({ account }: SuitabilityInformationViewProps) => {
  const getInvestmentExperienceGroups = () => {
    const result = [];

    if (account?.primaryAccountHolder?.suitabilityInformation?.financialInvestmentExperience?.margins) {
      const { tradesPerYear, yearsOfExperience } =
        account.primaryAccountHolder.suitabilityInformation.financialInvestmentExperience.margins;
      result.push({
        title: 'Margin',
        options: [
          { label: 'Trades/Year', value: tradesPerYear.label },
          { label: 'Years Of Experience', value: yearsOfExperience.label },
        ],
      });
    }

    if (account?.primaryAccountHolder?.suitabilityInformation?.financialInvestmentExperience?.stocks) {
      const { tradesPerYear, yearsOfExperience } =
        account?.primaryAccountHolder?.suitabilityInformation?.financialInvestmentExperience?.stocks;
      result.push({
        title: 'Stocks / ETFs',
        options: [
          { label: 'Trades/Year', value: tradesPerYear.label },
          { label: 'Years Of Experience', value: yearsOfExperience.label },
        ],
      });
    }

    if (account?.primaryAccountHolder?.suitabilityInformation?.financialInvestmentExperience?.options) {
      const { tradesPerYear, yearsOfExperience } =
        account?.primaryAccountHolder?.suitabilityInformation?.financialInvestmentExperience?.options;
      result.push({
        title: 'Options Experience',
        options: [
          { label: 'Trades/Year', value: tradesPerYear.label },
          { label: 'Years Of Experience', value: yearsOfExperience.label },
        ],
      });
    }

    if (account?.primaryAccountHolder?.suitabilityInformation?.financialInvestmentExperience?.commodities) {
      const { tradesPerYear, yearsOfExperience } =
        account?.primaryAccountHolder?.suitabilityInformation?.financialInvestmentExperience?.commodities;
      result.push({
        title: 'Commodities Experience',
        options: [
          { label: 'Trades/Year', value: tradesPerYear.label },
          { label: 'Years Of Experience', value: yearsOfExperience.label },
        ],
      });
    }

    if (account?.primaryAccountHolder?.suitabilityInformation?.financialInvestmentExperience?.bonds) {
      const { tradesPerYear, yearsOfExperience } =
        account?.primaryAccountHolder?.suitabilityInformation?.financialInvestmentExperience?.bonds;
      result.push({
        title: 'Bonds Experience',
        options: [
          { label: 'Trades/Year', value: tradesPerYear.label },
          { label: 'Years Of Experience', value: yearsOfExperience.label },
        ],
      });
    }

    return result;
  };

  return (
    <>
      <Col span={24} className={Spacing.mb16}>
        <MFormTextField
          label='Liquidity Needs'
          value={account?.primaryAccountHolder?.suitabilityInformation?.liquidityNeeds?.label}
          tooltip={{
            type: 'modal',
            title: 'Liquidity Needs',
            content: <LiquidityNeedsTooltipContent />,
            icon: 'info',
          }}
          testId={'liquidity-needs'}
        />
      </Col>
      <Col span={24} className={Spacing.mb16}>
        <MFormRadioGroupField
          label='Investment Objectives'
          value={account?.primaryAccountHolder?.suitabilityInformation?.investmentObjectives?.label}
          alignment='vertical'
          description={
            INVESTMENT_OBJECTIVES_OPTION_LIST.find(
              anOption =>
                anOption.value === account?.primaryAccountHolder?.suitabilityInformation?.investmentObjectives?.value,
            )?.description
          }
          testId={'investment-objectives'}
        />
      </Col>
      <Col span={24} className={Spacing.mb16}>
        <MFormRadioGroupField
          label='Time Horizon'
          value={account?.primaryAccountHolder?.suitabilityInformation?.timeHorizon?.label}
          alignment='vertical'
          description={
            TIME_HORIZON_OPTION_LIST.find(
              anOption => anOption.value === account?.primaryAccountHolder?.suitabilityInformation?.timeHorizon?.value,
            )?.description
          }
          testId={'time-horizon'}
        />
      </Col>
      <Col span={24} className={Spacing.mb16}>
        <MFormCheckboxGroupField
          label='Financial Investment Experience'
          tooltip={{
            type: 'popover',
            content:
              'Asset types you are currently trading or have traded. This may include investments and transactions performed by you or on your behalf.',
          }}
          groups={getInvestmentExperienceGroups()}
          testId={'financial-investment-experience'}
        />
      </Col>
    </>
  );
};
