import { MFormItem } from '../MFormItem/MFormItem';
import { MInputPassword, MInputPasswordProps } from '../MInput/MInputPassword';

interface MFormInputPasswordProps extends MInputPasswordProps {
  label: string;
  error?: string;
  tooltip?: {
    type: 'modal' | 'popover';
    title: string;
    content: string | JSX.Element;
  };
  testId?: string;
}

export const MFormInputPassword = ({
  label,
  placeholder,
  value,
  defaultValue,
  error,
  onChange,
  disabled,
  tooltip,
  testId,
}: MFormInputPasswordProps) => {
  return (
    <MFormItem label={label} tooltip={tooltip} testId={testId}>
      <MInputPassword
        placeholder={placeholder}
        value={value}
        defaultValue={defaultValue}
        error={error}
        onChange={onChange}
        disabled={disabled}
        testId={testId}
      />
    </MFormItem>
  );
};
