import { useEffect, useRef, useState } from 'react';

import { Col } from 'antd';
import { Formik, FormikProps } from 'formik';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  doClearCreateAccountHolderMailingAddress,
  doClearPatchAccountHolderMailingAddress,
  doCreateAccountHolderMailingAddress,
  doPatchAccountHolderMailingAddress,
  toastMessagesAdd,
  toastMessagesRemove,
} from 'src/actions';
import { US_COUNTRY_LABEL, US_COUNTRY_STATE_LIST, US_COUNTRY_VALUE } from 'src/constants';
import { MFormSaveButton, MFormInput, MFormSelect, MFormCheckbox } from 'src/lib';
import { AccountHolder } from 'src/models';
import { Spacing } from 'src/styles';
import { SeverityEnum } from 'src/typings/commonTypes';
import { assertNonNullable, formatValidationSchemaError, renderAddressMismatchErrorMessage } from 'src/utils';
import { v4 as uuid } from 'uuid';

import { upsertAccountHolderMailingAddressValidation } from './validations';

export const countryOptions = [{ label: US_COUNTRY_LABEL, value: US_COUNTRY_VALUE }];

interface AccountHolderMailingAddressFormValues {
  address1?: string;
  address2?: string;
  country?: string;
  city?: string;
  state?: string;
  postalCode?: string;
}

export interface AccountHolderMailingAddressFormProps {
  accountUuid?: string;
  accountHolder?: AccountHolder;
  isPrimary: boolean;
  onCancel?: () => void;
  onSave?: () => void;
}

export const citizenshipOptions = [
  {
    label: 'U.S. Citizen',
    value: 'USA',
  },
];

export const MailingAddressForm = ({
  accountHolder,
  accountUuid,
  isPrimary,
  onCancel,
  onSave,
}: AccountHolderMailingAddressFormProps) => {
  const dispatch = useDispatch();

  const isCreateMailingAddressLoading = useSelector((state: any) =>
    Boolean(state.accountHolders.createMailingAddress.__requested),
  );
  const succeededCreateMailingAddress = useSelector(
    (state: any) => state.accountHolders.createMailingAddress?.__succeeded,
  );
  const failedCreateMailingAddress = useSelector((state: any) =>
    Boolean(state.accountHolders.createMailingAddress.__failed),
  );
  const failedCreateMailingAddressMessage = useSelector(
    (state: any) => state.accountHolders.createMailingAddress?.message,
  );

  const isPatchMailingAddressLoading = useSelector((state: any) =>
    Boolean(state.accountHolders.patchMailingAddress.__requested),
  );
  const succeededPatchMailingAddress = useSelector(
    (state: any) => state.accountHolders.patchMailingAddress?.__succeeded,
  );
  const failedPatchMailingAddress = useSelector((state: any) =>
    Boolean(state.accountHolders.patchMailingAddress.__failed),
  );
  const failedPatchMailingAddressMessage = useSelector(
    (state: any) => state.accountHolders.patchMailingAddress?.message,
  );

  const createRequestData = useSelector((state: any) => state.accountHolders.createMailingAddress?.data);
  const patchRequestData = useSelector((state: any) => state.accountHolders.patchMailingAddress?.data);

  const [shouldPatch, setShouldPatch] = useState<boolean>(false);
  const [isSameAsPhysical, setIsSameAsPhysical] = useState<boolean>(
    Boolean(accountHolder?.mailingAddress?.sameAsPhysical),
  );
  const mailingAddressFormRef = useRef<FormikProps<AccountHolderMailingAddressFormValues> | null>(null);
  const [failedUpsertMailingAddressToastId, setFailedUpsertMailingAddressToastId] = useState<string | null>(null);

  const isMailingAddressAlreadySaved = () => !isEmpty(accountHolder?.mailingAddress);

  const isPhysicalAddressAlreadySaved = () => !isEmpty(accountHolder?.physicalAddress);

  const isRequestMatched = () =>
    accountHolder?.id === createRequestData?.params?.accountHolderId ||
    accountHolder?.id === patchRequestData?.params?.accountHolderId;

  const isUpsertLoading = (isCreateMailingAddressLoading || isPatchMailingAddressLoading) && isRequestMatched();

  const removeUpsertMailingAddressToastMessageIfFound = () => {
    if (failedUpsertMailingAddressToastId) {
      dispatch(toastMessagesRemove({ key: failedUpsertMailingAddressToastId }));
    }
  };

  const onPopulateMailingAddress = () => {
    if (mailingAddressFormRef.current && failedCreateMailingAddressMessage?.error === 'UspsAddressMismatchError') {
      mailingAddressFormRef.current.setFieldValue('address1', failedCreateMailingAddressMessage.address1);
      mailingAddressFormRef.current.setFieldValue('address2', failedCreateMailingAddressMessage.address2);
      mailingAddressFormRef.current.setFieldValue('city', failedCreateMailingAddressMessage.city);
      mailingAddressFormRef.current.setFieldValue('state', failedCreateMailingAddressMessage.state);
      mailingAddressFormRef.current.setFieldValue('postalCode', failedCreateMailingAddressMessage.postalCode);
    }

    if (mailingAddressFormRef.current && failedPatchMailingAddressMessage?.error === 'UspsAddressMismatchError') {
      mailingAddressFormRef.current.setFieldValue('address1', failedPatchMailingAddressMessage.address1);
      mailingAddressFormRef.current.setFieldValue('address2', failedPatchMailingAddressMessage.address2);
      mailingAddressFormRef.current.setFieldValue('city', failedPatchMailingAddressMessage.city);
      mailingAddressFormRef.current.setFieldValue('state', failedPatchMailingAddressMessage.state);
      mailingAddressFormRef.current.setFieldValue('postalCode', failedPatchMailingAddressMessage.postalCode);
    }
    removeUpsertMailingAddressToastMessageIfFound();
  };

  const _onCancel = (form: FormikProps<AccountHolderMailingAddressFormValues>) => {
    form.resetForm();

    if (onCancel) {
      onCancel();
    }
  };

  const _onSave = (form: FormikProps<AccountHolderMailingAddressFormValues>) => {
    form.submitForm();

    if (onSave) {
      onSave();
    }
  };

  useEffect(() => {
    if (isMailingAddressAlreadySaved()) {
      setShouldPatch(true);
    }
  }, [accountHolder]);

  useEffect(() => {
    if ((succeededCreateMailingAddress || succeededPatchMailingAddress) && isRequestMatched()) {
      if (onCancel) {
        onCancel();
      }
    }
  }, [succeededCreateMailingAddress, succeededPatchMailingAddress]);

  useEffect(() => {
    if (failedCreateMailingAddressMessage?.error === 'UspsAddressMismatchError' && isRequestMatched()) {
      removeUpsertMailingAddressToastMessageIfFound();
      const toastId = uuid();
      dispatch(
        toastMessagesAdd({
          key: toastId,
          severity: SeverityEnum.Error,
          isClearable: true,
          autoClose: false,
          message: renderAddressMismatchErrorMessage({
            dto: failedCreateMailingAddressMessage,
            onClick: onPopulateMailingAddress,
            section: 'mailing',
          }),
        }),
      );
      setFailedUpsertMailingAddressToastId(toastId);
    }
  }, [failedCreateMailingAddress]);

  useEffect(() => {
    if (failedPatchMailingAddressMessage?.error === 'UspsAddressMismatchError' && isRequestMatched()) {
      removeUpsertMailingAddressToastMessageIfFound();
      const toastId = uuid();
      dispatch(
        toastMessagesAdd({
          key: uuid(),
          severity: SeverityEnum.Error,
          isClearable: true,
          autoClose: false,
          message: renderAddressMismatchErrorMessage({
            dto: failedPatchMailingAddressMessage,
            onClick: onPopulateMailingAddress,
            section: 'mailing',
          }),
        }),
      );
      setFailedUpsertMailingAddressToastId(toastId);
    }
  }, [failedPatchMailingAddress]);

  useEffect(() => {
    return () => {
      dispatch(doClearCreateAccountHolderMailingAddress());
      dispatch(doClearPatchAccountHolderMailingAddress());
    };
  }, []);

  return (
    <Formik<AccountHolderMailingAddressFormValues>
      enableReinitialize
      validateOnChange
      initialValues={{
        address1: accountHolder?.mailingAddress?.address1,
        address2: accountHolder?.mailingAddress?.address2,
        city: accountHolder?.mailingAddress?.city,
        country: accountHolder?.mailingAddress?.country?.value ?? US_COUNTRY_VALUE,
        state: accountHolder?.mailingAddress?.state?.value,
        postalCode: accountHolder?.mailingAddress?.postalCode,
      }}
      innerRef={ref => (mailingAddressFormRef.current = ref)}
      validate={async values => {
        try {
          if (isSameAsPhysical) {
            return {};
          }
          await upsertAccountHolderMailingAddressValidation.validate(values);
        } catch (error: any) {
          return formatValidationSchemaError(error);
        }
      }}
      onSubmit={values => {
        assertNonNullable(accountHolder, 'AccountHolder');
        assertNonNullable(accountUuid, 'accountUuid');

        if (shouldPatch) {
          dispatch(
            doPatchAccountHolderMailingAddress({
              params: {
                id: accountUuid,
                accountHolderId: accountHolder.id,
              },
              body: {
                ...values,
                sameAsPhysical: isSameAsPhysical,
              },
            }),
          );

          return;
        }

        dispatch(
          doCreateAccountHolderMailingAddress({
            params: {
              id: accountUuid,
              accountHolderId: accountHolder.id,
            },
            body: {
              ...values,
              sameAsPhysical: isSameAsPhysical,
            },
          }),
        );
      }}>
      {form => {
        return (
          <>
            <Col span={24}>
              <MFormCheckbox
                label={
                  isPrimary
                    ? 'Mailing address is the same as my physical address'
                    : "Mailing address is the same as Joint Account Holder's physical address"
                }
                disabled={!shouldPatch && !isPhysicalAddressAlreadySaved()}
                checked={isSameAsPhysical}
                defaultChecked={isSameAsPhysical}
                onChange={async value => {
                  if (value.checked) {
                    form.setFieldValue('address1', accountHolder?.physicalAddress?.address1);
                    form.setFieldValue('address2', accountHolder?.physicalAddress?.address2);
                    form.setFieldValue('city', accountHolder?.physicalAddress?.city);
                    form.setFieldValue('state', accountHolder?.physicalAddress?.state?.value);
                    form.setFieldValue('postalCode', accountHolder?.physicalAddress?.postalCode);
                    form.setFieldValue('country', accountHolder?.physicalAddress?.country?.value);
                  } else {
                    form.resetForm();
                  }
                  setIsSameAsPhysical(value.checked);
                }}
                className={Spacing.my16}
                testId={`${isPrimary ? 'primary' : 'joint'}-account-mailing-as-physical`}
              />
            </Col>

            {!isSameAsPhysical && (
              <>
                <Col span={24}>
                  <MFormInput
                    label='Address Line 1'
                    placeholder='Enter'
                    value={form.values.address1}
                    defaultValue={accountHolder?.mailingAddress?.address1}
                    error={form.errors.address1}
                    onChange={value => {
                      form.setFieldValue('address1', value);
                    }}
                    testId={`${isPrimary ? 'primary' : 'joint'}-account-mailing-address1`}
                  />
                </Col>

                <Col span={24}>
                  <MFormInput
                    label='Address Line 2 (Opt.)'
                    placeholder='Enter'
                    value={form.values.address2}
                    defaultValue={accountHolder?.mailingAddress?.address2}
                    error={form.errors.address2}
                    onChange={value => {
                      form.setFieldValue('address2', value);
                    }}
                    testId={`${isPrimary ? 'primary' : 'joint'}-account-mailing-address2`}
                  />
                </Col>

                <Col span={24}>
                  <MFormSelect
                    label='Country'
                    placeholder='Select'
                    defaultValue={accountHolder?.mailingAddress?.country?.value ?? US_COUNTRY_VALUE}
                    options={countryOptions}
                    error={form.errors.country}
                    onChange={value => {
                      form.setFieldValue('country', value);
                    }}
                    testId={`${isPrimary ? 'primary' : 'joint'}-account-mailing-country`}
                  />
                </Col>

                <Col span={24}>
                  <MFormInput
                    label='City'
                    placeholder='Enter'
                    value={form.values.city}
                    defaultValue={accountHolder?.mailingAddress?.city}
                    error={form.errors.city}
                    onChange={value => {
                      form.setFieldValue('city', value);
                    }}
                    testId={`${isPrimary ? 'primary' : 'joint'}-account-mailing-city`}
                  />
                </Col>

                <Col span={24}>
                  <MFormSelect
                    label='State'
                    placeholder='Select'
                    defaultValue={accountHolder?.mailingAddress?.state?.value}
                    value={form.values.state}
                    options={US_COUNTRY_STATE_LIST}
                    error={form.errors.state}
                    onChange={value => {
                      form.setFieldValue('state', value);
                    }}
                    testId={`${isPrimary ? 'primary' : 'joint'}-account-mailing-state`}
                  />
                </Col>

                <Col span={24}>
                  <MFormInput
                    label='Postal Code'
                    placeholder='Enter'
                    value={form.values.postalCode}
                    defaultValue={accountHolder?.mailingAddress?.postalCode}
                    error={form.errors.postalCode}
                    onChange={value => {
                      form.setFieldValue('postalCode', value);
                    }}
                    testId={`${isPrimary ? 'primary' : 'joint'}-account-mailing-postal-code`}
                  />
                </Col>
              </>
            )}

            <Col span={24}>
              <MFormSaveButton<AccountHolderMailingAddressFormValues>
                loading={isUpsertLoading}
                onCancel={_onCancel}
                onSave={_onSave}
                isEditMode={shouldPatch}
                form={form}
                testId={`${isPrimary ? 'primary' : 'joint'}-account-mailing-address`}
              />
            </Col>
          </>
        );
      }}
    </Formik>
  );
};
