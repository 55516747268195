import { MFormDateField, MFormTextField, PhoneNumbersField } from 'src/lib';
import { AccountHolder } from 'src/models';

import { SocialSecurityNumberTooltipContent } from './SocialSecurityNumberTooltipContent';

export interface PersonalInformationViewProps {
  accountHolder?: AccountHolder;
  isPrimary: boolean;
}

export const PersonalInformationView = ({ accountHolder, isPrimary }: PersonalInformationViewProps) => {
  const formatSsid = () => {
    const ssid = accountHolder?.maskedSsid;

    if (ssid) {
      return ssid.substring(0, 3) + ' - ' + ssid.substring(3, 5) + ' - ' + ssid.substring(5, 9);
    }

    return ssid;
  };

  return (
    <>
      <MFormTextField
        label='First Name'
        value={accountHolder?.firstName}
        testId={`${isPrimary ? 'primary' : 'joint'}-account-first-name`}
      />
      <MFormTextField
        label='Middle Initial or Middle Name'
        value={accountHolder?.middleName}
        testId={`${isPrimary ? 'primary' : 'joint'}-account-middle-name`}
      />
      <MFormTextField
        label='Last Name'
        value={accountHolder?.lastName}
        testId={`${isPrimary ? 'primary' : 'joint'}-account-last-name`}
      />
      <MFormTextField
        label='Suffix'
        value={accountHolder?.suffix}
        testId={`${isPrimary ? 'primary' : 'joint'}-account-suffix`}
      />
      <MFormTextField
        label='Social Security Number'
        value={formatSsid()}
        tooltip={{
          type: 'modal',
          title: 'Why do we need this?',
          content: <SocialSecurityNumberTooltipContent />,
        }}
        testId={`${isPrimary ? 'primary' : 'joint'}-account-ssn`}
      />
      <MFormTextField
        label='Email'
        value={accountHolder?.email}
        testId={`${isPrimary ? 'primary' : 'joint'}-account-email`}
      />
      {accountHolder?.phones && (
        <MFormTextField
          label='Phone Number(s)'
          value={<PhoneNumbersField value={accountHolder.phones} />}
          testId={`${isPrimary ? 'primary' : 'joint'}-account-phone-number`}
        />
      )}
      <MFormDateField
        label='Date of Birth'
        value={accountHolder?.dateOfBirth}
        testId={`${isPrimary ? 'primary' : 'joint'}-account-dob`}
      />
      <MFormTextField
        label='Marital Status'
        value={accountHolder?.maritalStatus?.label}
        testId={`${isPrimary ? 'primary' : 'joint'}-account-marital-status`}
      />
      <MFormTextField
        label='Number of Dependents'
        value={accountHolder?.numberOfDependents}
        testId={`${isPrimary ? 'primary' : 'joint'}-account-number-of-dependents`}
      />
    </>
  );
};
