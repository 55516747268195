import React, { useEffect } from 'react';

import { cx } from '@emotion/css';
import { Card, Col, Row, Space } from 'antd';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addOfferOrderIntent, doDownloadAccountSignature } from 'src/actions';
import { DownloadAccountSignatureResponseDto, OfferOrderSecurityDeliveryOptionDto } from 'src/dtos';
import { MCheckbox, MFormTextField, MButton } from 'src/lib';
import { OfferOrderIntent, OfferDetails } from 'src/models';
import { BaseStyle, Images, Spacing } from 'src/styles';
import { Maybe, isRegDTypeCOffer } from 'src/utils';
import * as Yup from 'yup';

import { NewOfferOrderLayout } from '../../../../../lib/Layout/NewOfferOrderLayout/NewOfferOrderLayout';
import { ThisOfferOrderWillDisclaimer } from '../../../../../lib/ThisOfferOrderWillDisclaimer/ThisOfferOrderWillDisclaimer';
import { DOCUMENT_TYPES } from '../../../../Offers/components/OfferDocumentsTab/constants';
import { DETAILS_PATH } from '../NewOfferOrderConfirmStep/NewOfferOrderConfirmStep';
import { SECURITY_DELIVERY_INFORMATION } from '../NewOfferOrderDeliveryInformationStep/NewOfferOrderSecurityDeliveryInformationStep';
import * as Styles from '../NewOfferOrders.styles';

export const DOCUMENTS_PATH = 'documents';

export const formValidationSchema = Yup.object().shape({
  areDocumentsReviewed: Yup.bool().oneOf([true], 'Field must be checked'),
});

interface NewOfferOrderDocumentsFormValues {
  areDocumentsReviewed: boolean;
}

export const NewOfferOrderDocumentsStep = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const offerDetails: OfferDetails = useSelector((state: any) => state.offers.offerDetails?.data);
  const signature: DownloadAccountSignatureResponseDto = useSelector(
    (state: any) => state.accounts.downloadSignature.data,
  );
  const offerOrderIntent: OfferOrderIntent = useSelector((state: any) => state.offerOrders.intent);

  const findDeliveryOptionIfFound = (): Maybe<OfferOrderSecurityDeliveryOptionDto> => {
    if (offerOrderIntent.hasManySecurityDeliveryOptions) {
      return;
    }
    const deliveryOption = offerDetails?.externalDetails?.securityDeliveryOptions?.[0];

    if (deliveryOption) {
      return {
        label: deliveryOption.label,
        tooltip: deliveryOption.tooltip,
      };
    }
  };

  const getAreDocumentsReviewedDisclaimer = () => {
    if (isRegDTypeCOffer(offerDetails)) {
      return (
        'By clicking “Continue” I have reviewed the above offering documents and understand there are risks when investing. ' +
        'Once your order is placed, a representative from My IPO will contact you with instructions on how to complete ' +
        'the subscription agreement and any other documents needed to complete your Offer Order.'
      );
    }

    return offerDetails.subscriptionAgreementId
      ? 'I have reviewed the above offering documents and understand there are risks when investing. By clicking "Continue" I am electronically signing the offering documents.'
      : 'By clicking “Continue” I have reviewed the above offering documents and understand there are risks when investing.';
  };

  const shouldDisplaySignature = () => !isRegDTypeCOffer(offerDetails);

  useEffect(() => {
    dispatch(
      doDownloadAccountSignature({
        params: {
          id: offerOrderIntent.account.id,
        },
      }),
    );
  }, [dispatch]);

  const onSubmit = () => {
    dispatch(
      addOfferOrderIntent({
        ...offerOrderIntent,
        areDocumentsReviewed: true,
        securityDeliveryOption: findDeliveryOptionIfFound(),
      }),
    );

    window.gtag('event', 'offer_order_review_documents_complete', {
      offer_id: offerDetails.id,
      offer_name: offerDetails.name,
      account_id: offerOrderIntent.account.accountId,
    });

    const nextPage =
      offerDetails.isExternalOffer && offerOrderIntent.hasManySecurityDeliveryOptions
        ? `../${SECURITY_DELIVERY_INFORMATION}`
        : `../${DETAILS_PATH}`;
    navigate(`${nextPage}`);
  };

  return (
    <NewOfferOrderLayout
      step='documents'
      description='Please review the information below before completing the order process.'
      hasManySecurityDeliveryOptions={offerOrderIntent.hasManySecurityDeliveryOptions}
      offerId={offerDetails.id}>
      <Formik<NewOfferOrderDocumentsFormValues>
        enableReinitialize
        initialValues={{ areDocumentsReviewed: offerOrderIntent.areDocumentsReviewed }}
        onSubmit={() => onSubmit()}
        validationSchema={formValidationSchema}>
        {form => {
          return (
            <>
              <Col span={24} className={cx(BaseStyle.level, Spacing.mb40)}>
                <img
                  className={Styles.offerLogo}
                  src={offerDetails.logoUrl || Images.DefaultOffering}
                  alt={`${offerDetails.name}_logo`}
                />
                <span className={Styles.offerName}>{offerDetails.name}</span>
              </Col>
              <Row className={Spacing.mb24}>
                {offerDetails?.documents.map(document => (
                  <Col span={24} key={document.id}>
                    <MFormTextField
                      label={DOCUMENT_TYPES[document.type].label}
                      value={
                        <MButton type='secondary' href={document.url} target='_blank'>
                          View
                        </MButton>
                      }
                    />
                  </Col>
                ))}
                {offerOrderIntent.isConditional && (
                  <ThisOfferOrderWillDisclaimer
                    min={offerDetails.minSharePrice}
                    max={offerDetails.maxSharePrice}
                    className={Spacing.mb12}
                  />
                )}
                <Col span={24}>
                  <MCheckbox
                    checked={form.values.areDocumentsReviewed}
                    onChange={value => form.setFieldValue('areDocumentsReviewed', value.checked)}
                    error={form.errors.areDocumentsReviewed}
                    testId={'offer-order-check-sign-documents'}>
                    {getAreDocumentsReviewedDisclaimer()}
                  </MCheckbox>
                </Col>
                {shouldDisplaySignature() && (
                  <>
                    <Col span={24} data-testid={'offer-order-documents-primary-signature-text-and-image'}>
                      <div className={Styles.sectionTitle}>
                        {offerOrderIntent.account.type.isJoint ? 'Primary Account Holder Signature' : 'Signature'}
                      </div>
                      {signature && (
                        <Card className={Styles.signatureWrapper}>
                          <img src={signature.primaryAccountHolder} alt='signature' className={Styles.signature} />
                        </Card>
                      )}
                    </Col>
                    {offerOrderIntent.account.type.isJoint && (
                      <Col span={24} data-testid={'offer-order-documents-joint-signature-text-and-image'}>
                        <div className={Styles.sectionTitle}>Joint Account Holder Signature</div>
                        {signature && (
                          <Card className={Styles.signatureWrapper}>
                            <img src={signature.secondaryAccountHolder} alt='signature' className={Styles.signature} />
                          </Card>
                        )}
                      </Col>
                    )}
                  </>
                )}
                <Col
                  span={24}
                  className={cx(BaseStyle.level, Spacing.mt24)}
                  data-testid={'offer-documents-disclosures-row-btns'}>
                  <Space direction='horizontal'>
                    <MButton type='tertiary' onClick={() => navigate(-1)}>
                      Back
                    </MButton>
                    <MButton onClick={() => form.submitForm()}>Continue</MButton>
                  </Space>
                </Col>
              </Row>
            </>
          );
        }}
      </Formik>
    </NewOfferOrderLayout>
  );
};
