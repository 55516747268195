import React, { useState } from 'react';

import { Col } from 'antd';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toastMessagesAdd } from 'src/actions';
import { AccountConstant } from 'src/constants';
import { useAccountSelector } from 'src/hooks';
import { MFormCheckbox, MFormSaveButton, MModal } from 'src/lib';
import { getLocalStorageService } from 'src/services';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import { SeverityEnum } from '../../../typings/commonTypes';

import * as Styles from './AccountAgreements.styles';
import { AccountAgreementsList } from './AccountAgreementsList';

const localStorageService = getLocalStorageService();

export const accountAgreementsValidation = Yup.object().shape({
  isAccepted: Yup.bool()
    .oneOf([true], 'Account agreements consent is required')
    .required('Account agreements consent is required'),
});

interface AccountAgreementsFormValues {
  isAccepted?: boolean;
}

export const AccountAgreementsForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { account } = useAccountSelector();

  const onSubmit = async () => {
    dispatch(
      toastMessagesAdd({
        key: uuidv4(),
        severity: SeverityEnum.Success,
        message: 'Account Agreement was successfully saved',
      }),
    );
    window.dispatchEvent(new Event('storage'));
    window.gtag('event', 'account_agreements_complete');
    navigate(`/accounts/sign-application`);
  };

  return (
    <>
      <Formik<AccountAgreementsFormValues>
        initialValues={{
          isAccepted: false,
        }}
        validationSchema={accountAgreementsValidation}
        onSubmit={value => {
          localStorageService.add({
            key: `${AccountConstant.SIGN_APPLICATION_PREFIX_KEY}-${account?.id}`,
            value: value.isAccepted?.toString() ?? 'false',
          });

          onSubmit();
        }}>
        {form => {
          return (
            <>
              <Col span={24}>
                <MFormCheckbox
                  label='I agree to use Electronic Consent for agreements.'
                  defaultChecked={false}
                  checked={form.values.isAccepted}
                  error={form.errors.isAccepted}
                  onChange={value => {
                    form.setFieldValue('isAccepted', value.checked);
                  }}
                  testId={'account-agreement-to-use-electronic-consent'}
                />
              </Col>
              <Col span={24}>
                <div className={Styles.agreementAcceptText}>
                  By clicking the “Accept” button below, you agree that you have received, read and understand each of
                  the agreements below, and you agree to all the provisions in each document.
                </div>
              </Col>
              <Col span={24} className={Styles.linksContainer}>
                <AccountAgreementsList />
              </Col>

              <Col span={24}>
                <MFormSaveButton<AccountAgreementsFormValues>
                  cancelButtonText='Decline'
                  onCancel={() => {
                    setIsModalVisible(true);
                  }}
                  saveButtonText='Accept'
                  onSave={() => {
                    form.submitForm();
                  }}
                  isEditMode
                  form={form}
                  testId={'account-agreement'}
                />
              </Col>
            </>
          );
        }}
      </Formik>
      <MModal
        visible={isModalVisible}
        title='Account Agreeements'
        primaryButtonText='Decline'
        tertiaryButtonText='Close'
        onPrimaryButtonClick={async () => {
          setIsModalVisible(false);
          navigate('/summary');
        }}
        onTertiaryButtonClick={() => {
          setIsModalVisible(false);
        }}
        onClose={() => {
          setIsModalVisible(false);
        }}>
        If you decide to decline Account Agreements you cannot create the account and you will not be able to invest on
        MY IPO.
      </MModal>
    </>
  );
};
