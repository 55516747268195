import { Col } from 'antd';
import { AccountHolderEmploymentStatusDto } from 'src/dtos';
import { MFormTextField } from 'src/lib';
import { AccountHolder } from 'src/models';

import * as Styles from './FinancialInformation.styles';

export interface FinancialInformationEmploymentViewProps {
  accountHolder?: AccountHolder;
  testId?: string;
}

export const FinancialInformationEmploymentView = ({
  accountHolder,
  testId,
}: FinancialInformationEmploymentViewProps) => {
  const isEmployed = () =>
    accountHolder?.financialInformation?.employmentStatus?.value === AccountHolderEmploymentStatusDto.Employed;

  return (
    <>
      <MFormTextField
        label='Employment Status'
        value={accountHolder?.financialInformation?.employmentStatus?.label}
        testId={`${testId}-account-employment-status`}
      />
      {isEmployed() && (
        <>
          <MFormTextField
            label='Employer Name'
            value={accountHolder?.financialInformation?.employerName}
            testId={`${testId}-account-employer-name`}
          />
          <MFormTextField
            label='Job Title'
            value={accountHolder?.financialInformation?.jobTitle}
            testId={`${testId}-account-job-title`}
          />
          <MFormTextField
            label='Years Employed'
            value={accountHolder?.financialInformation?.yearsEmployed?.label}
            testId={`${testId}-account-years-employed`}
          />
          <Col span={24} className={Styles.title}>
            Employment Address
          </Col>
          <MFormTextField
            label='Address Line 1'
            value={accountHolder?.financialInformation?.employerAddress?.address1}
            testId={`${testId}-account-mailing-address1`}
          />
          <MFormTextField
            label='Address Line 2 (Opt.)'
            value={accountHolder?.financialInformation?.employerAddress?.address2}
            testId={`${testId}-account-mailing-address2`}
          />
          <MFormTextField
            label='Country'
            value={accountHolder?.financialInformation?.employerAddress?.country?.label}
            testId={`${testId}-account-mailing-country`}
          />
          <MFormTextField
            label='City'
            value={accountHolder?.financialInformation?.employerAddress?.city}
            testId={`${testId}-account-mailing-city`}
          />
          <MFormTextField
            label='State'
            value={accountHolder?.financialInformation?.employerAddress?.state?.label}
            testId={`${testId}-account-mailing-state`}
          />
          <MFormTextField
            label='Postal Code'
            value={accountHolder?.financialInformation?.employerAddress?.postalCode}
            testId={`${testId}-account-mailing-postal-code`}
          />
        </>
      )}
    </>
  );
};
