import { useEffect, useState } from 'react';

import { cx } from '@emotion/css';
import { Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addOfferOrderIntent, offerOrdersCreateRequested } from 'src/actions';
import { DefinitionConstant } from 'src/constants';
import { OfferOrderPaymentTypeDto, OfferOrderRetirementAccountFundingTypeDto } from 'src/dtos';
import { useAccountSelector, useAccountBalance } from 'src/hooks';
import {
  MFormCurrencyField,
  MFormCurrencyRangeField,
  MFormDateField,
  MFormTextField,
  SecurityDeliveryOption,
} from 'src/lib';
import { OfferDetails, OfferOrder, OfferOrderIntent, OfferOrderPaymentType } from 'src/models';
import { Maybe } from 'src/utils';

import { MAIN_PATH } from '../../../';
import { MButton } from '../../../../../lib/FormComponents/MButton/MButton';
import { NewOfferOrderLayout } from '../../../../../lib/Layout/NewOfferOrderLayout/NewOfferOrderLayout';
import CurrencyField from '../../../../../lib/Miscellaneous/FormattedFields/CurrencyField/CurrencyField';
import Spinner from '../../../../../lib/Miscellaneous/Spinner';
import { ThisOfferOrderWillDisclaimer } from '../../../../../lib/ThisOfferOrderWillDisclaimer/ThisOfferOrderWillDisclaimer';
import { BaseStyle, Images, Spacing } from '../../../../../styles';
import { SUMMARY_PATH } from '../../Summary/Summary';
import * as Styles from '../NewOfferOrders.styles';

import { PaymentTypeSelector } from './components/PaymentTypeSelector';
import { TermsAndConditionsModal } from './components/TermsAndConditionsModal';

export const DETAILS_PATH = 'details';

export const NewOfferOrderConfirmStep = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const offerDetails: OfferDetails = useSelector((state: any) => state.offers.offerDetails?.data);

  const offerOrderIntent: OfferOrderIntent = useSelector((state: any) => state.offerOrders.intent);

  const offerOrder: OfferOrder | undefined = useSelector((state: any) => state.offerOrders.create.data);
  const isCreateOfferOrderLoading = useSelector((state: any) => Boolean(state.offerOrders.create.__requested));
  const createOfferOrderSucceeded = useSelector((state: any) => Boolean(state.offerOrders.create.__succeeded));
  const createOfferOrderFailed = useSelector((state: any) => Boolean(state.offerOrders.create.__failed));

  const isConfirmCardLoading = useSelector((state: any) => Boolean(state.offerOrders.confirmCardPayment.__requested));

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedPaymentType, setSelectedPaymentType] = useState<
    Maybe<OfferOrderPaymentTypeDto | OfferOrderRetirementAccountFundingTypeDto>
  >(offerOrderIntent.paymentType?.value);
  const [isSelectedPaymentTypeValid, setIsSelectedPaymentTypeValid] = useState<boolean>(true);

  const { account } = useAccountSelector();
  const { cashAvailable, isAccountBalanceLoading } = useAccountBalance();

  const hasEnoughMoney = () => offerOrderIntent.totalInvestment <= cashAvailable;

  const onIsSelectedPaymentTypeValid = (value: boolean) => {
    setIsSelectedPaymentTypeValid(value);
  };

  const onSelectedPaymentTypeChange = (
    value?: OfferOrderPaymentTypeDto | OfferOrderRetirementAccountFundingTypeDto,
    meta?: {
      iraContributionPaymentType?: OfferOrderPaymentTypeDto;
    },
  ) => {
    setSelectedPaymentType(value);

    if (value === OfferOrderPaymentTypeDto.Ach) {
      dispatch(addOfferOrderIntent({ ...offerOrderIntent, paymentType: new OfferOrderPaymentType(value) }));
    } else {
      dispatch(
        addOfferOrderIntent({
          ...offerOrderIntent,
          paymentType:
            value === OfferOrderRetirementAccountFundingTypeDto.IraContribution
              ? meta?.iraContributionPaymentType
                ? new OfferOrderPaymentType(meta.iraContributionPaymentType)
                : offerOrderIntent.paymentType
              : new OfferOrderPaymentType(value),
          retirementAccountFundingType:
            value === OfferOrderRetirementAccountFundingTypeDto.IraContribution ? value : undefined,
          achDeposit: undefined,
        }),
      );
    }
  };

  const submitOfferOrder = async () => {
    dispatch(offerOrdersCreateRequested(offerOrderIntent));
  };

  useEffect(() => {
    if (createOfferOrderSucceeded && offerOrder && !offerOrderIntent.paymentType?.isCard) {
      navigate(`../${offerOrder.id}`);
    }
  }, [createOfferOrderSucceeded]);

  useEffect(() => {
    if (createOfferOrderFailed) {
      navigate(`../failed`);
    }
  }, [createOfferOrderFailed]);

  useEffect(() => {
    if (hasEnoughMoney() && !offerOrderIntent.isExternal) {
      dispatch(addOfferOrderIntent({ ...offerOrderIntent, paymentType: undefined, achDeposit: undefined }));
    }
  }, [offerOrderIntent.isExternal, cashAvailable]);

  if (isAccountBalanceLoading) return <Spinner />;

  return (
    <NewOfferOrderLayout
      step='confirm'
      hasManySecurityDeliveryOptions={offerOrderIntent.hasManySecurityDeliveryOptions}
      offerId={offerDetails.id}>
      <>
        <Col span={24} className={cx(BaseStyle.level, Spacing.mb40)}>
          <img
            className={Styles.offerLogo}
            src={offerDetails.logoUrl || Images.DefaultOffering}
            alt={`${offerDetails.name}_logo`}
          />
          <span className={Styles.offerName}>{offerDetails.name}</span>
        </Col>
        <Col span={24}>
          <MFormTextField
            label='Investor Name'
            value={`${account?.primaryAccountHolder?.firstName} ${account?.primaryAccountHolder?.lastName}`}
            testId={'confirm-order-investor-name'}
          />
        </Col>
        <Col span={24}>
          <MFormTextField
            label='Address'
            value={`${account?.primaryAccountHolder?.physicalAddress?.address1}, ${account?.primaryAccountHolder?.physicalAddress?.city}, ${account?.primaryAccountHolder?.physicalAddress?.postalCode}, ${account?.primaryAccountHolder?.physicalAddress?.country?.value}`}
            testId={'confirm-order-investor-address'}
          />
        </Col>
        <Col span={24}>
          <MFormDateField label='Date' value={new Date().toISOString()} testId={'confirm-order-investor-date'} />
        </Col>
        <Col span={24}>
          {offerOrderIntent.isConditional ? (
            <MFormCurrencyRangeField
              label='Share Range'
              min={offerOrderIntent.minSharePrice}
              max={offerOrderIntent.maxSharePrice}
              testId={'confirm-order-investor-currency-range'}
            />
          ) : (
            <>
              <MFormCurrencyField
                label='Share Price'
                value={offerOrderIntent.price}
                testId={'confirm-order-investor-share-price'}
              />
              <MFormTextField
                label='Share Quantity'
                value={offerOrderIntent.quantity}
                testId={'confirm-order-investor-share-quantity'}
              />
            </>
          )}
        </Col>
        {hasEnoughMoney() && !offerOrderIntent.isExternal && (
          <Col span={24}>
            <MFormCurrencyField
              label='Cash Available'
              value={cashAvailable}
              tooltip={{ type: 'popover', content: DefinitionConstant.CASH_AVAILABLE, icon: 'question' }}
              testId={'confirm-order-investor-cash-available'}
            />
          </Col>
        )}
        {offerOrderIntent.isExternal && offerOrderIntent.securityDeliveryOption && (
          <Col span={24}>
            <MFormTextField
              label='Security Delivery Option'
              value={<SecurityDeliveryOption {...offerOrderIntent.securityDeliveryOption} />}
              testId={'confirm-order-investor-security-delivery-option'}
            />
          </Col>
        )}

        <div className={Styles.totalInvestment} data-testid={'confirm-order-total-invest-label'}>
          Total Investment
          <CurrencyField
            value={offerOrderIntent.totalInvestment}
            className={Styles.totalInvestmentValue}
            testId={'confirm-order-total-invest-value'}
          />
        </div>

        {offerOrderIntent.isConditional && (
          <ThisOfferOrderWillDisclaimer
            min={offerOrderIntent.minSharePrice}
            max={offerOrderIntent.maxSharePrice}
            className={cx(Spacing.mb12, Spacing.mt0)}
          />
        )}

        {(!hasEnoughMoney() || offerOrderIntent.isExternal) && (
          <PaymentTypeSelector
            value={selectedPaymentType}
            isValid={onIsSelectedPaymentTypeValid}
            onChange={onSelectedPaymentTypeChange}
          />
        )}

        <Col span={24} className={Styles.policy} data-testid={'confirm-order-policy'}>
          POLICY
          <br />
          Once you place your offer order, you will be able to cancel it in the time span that the order is in a pending
          state. Once the order goes through, you can track it through the{' '}
          <span onClick={() => navigate(`${MAIN_PATH}/${SUMMARY_PATH}`)} className={Styles.linkStyle}>
            Summary Page
          </span>
          .
          <br />
          <br />
          {account?.type.isRetirement && (
            <>
              Contents provided are for informational purposes only and don’t constitute tax or investment advice. For
              specific questions, you should consult a tax professional. My IPO does not provide tax advice. Links to
              third party sites are being provided for informational purposes only.
            </>
          )}
        </Col>

        <Col span={24} className={cx(BaseStyle.level, Spacing.mt24)}>
          <MButton type='tertiary' onClick={() => navigate(-1)} className={Spacing.mx4} testId={'offer-order-btn-back'}>
            Back
          </MButton>
          <MButton
            onClick={() => submitOfferOrder()}
            disabled={!isSelectedPaymentTypeValid || isCreateOfferOrderLoading || isConfirmCardLoading}
            loading={isCreateOfferOrderLoading || isConfirmCardLoading}
            className={Spacing.mx4}
            testId={'confirm-order-btn-placeorder'}>
            Place Order
          </MButton>
        </Col>
        <div className={Styles.termsAndConditions} data-testid={'confirm-order-terms-conditions'}>
          By clicking “Place Order”, I acknowledge the&nbsp;
          <span onClick={() => setIsModalVisible(true)} className={Styles.linkStyle}>
            terms and conditions
          </span>
        </div>
      </>
      <TermsAndConditionsModal
        isOpen={isModalVisible}
        onClose={() => {
          setIsModalVisible(false);
        }}
      />
    </NewOfferOrderLayout>
  );
};
