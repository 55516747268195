import { Col, Row } from 'antd';
import { AccountHolderStatementDeliveryDto } from 'src/dtos';
import { MFormCheckboxField, MFormRadioGroupField, MFormTextField } from 'src/lib';
import { AccountHolder } from 'src/models';
import { Spacing } from 'src/styles';

import * as Styles from './Disclosures.styles';

export interface DisclosuresViewProps {
  accountHolder?: AccountHolder;
  isPrimary: boolean;
}

export const DisclosuresView = ({ accountHolder, isPrimary }: DisclosuresViewProps) => {
  const renderIndividualRepresentations = () => {
    return (
      <Row>
        <Col span={24} className={Styles.titleViewContainer}>
          <p
            className={Styles.title}
            data-testid={`${isPrimary ? 'primary' : 'joint'}-account-individual-representation-title`}>
            Individual Representations
          </p>
          <p
            className={Styles.subtitle}
            data-testid={`${isPrimary ? 'primary' : 'joint'}-account-individual-representation-subtitle`}>
            (Please attest as to whether any of the following apply to you.)
          </p>
        </Col>
        <Col span={24}>
          <MFormCheckboxField
            label='Are you a 10% shareholder, director and/or policy maker of a publicly-owned company?'
            testId={`${isPrimary ? 'primary' : 'joint'}-account-stakeholder`}>
            <MFormTextField
              label='Company Ticker Symbol(s)'
              value={
                accountHolder?.disclosure?.stakeholder === false
                  ? ''
                  : accountHolder?.disclosure?.stakeholder.tickerSymbol
              }
              className={Spacing.py0}
              testId={`${isPrimary ? 'primary' : 'joint'}-account-stakeholder-company-ticker-symbol`}
            />
          </MFormCheckboxField>
        </Col>
        <Col span={24}>
          <MFormCheckboxField
            label='Are you employed by or a member of a registered broker-dealer, securities or futures exchange, or securities
            industry regulatory body (e.g., FINRA, NFA)?'
            testId={`${isPrimary ? 'primary' : 'joint'}-account-finra`}>
            <MFormTextField
              label='Firm Name'
              value={
                accountHolder?.disclosure?.industryEmployed === false
                  ? ''
                  : accountHolder?.disclosure?.industryEmployed.firmName
              }
              className={Spacing.py0}
              testId={`${isPrimary ? 'primary' : 'joint'}-account-finra-firm-name`}
            />
          </MFormCheckboxField>
        </Col>
      </Row>
    );
  };

  return (
    <>
      {isPrimary && (
        <Col span={24} className={Spacing.mb16}>
          <MFormRadioGroupField
            label='Statement Delivery'
            value={accountHolder?.disclosure?.statementDelivery?.label}
            tooltip={
              accountHolder?.disclosure?.statementDelivery.value === AccountHolderStatementDeliveryDto.Paper
                ? {
                    type: 'popover',
                    content:
                      'Trade Confirmations: $2.00 per confirm Monthly Statement: $5.00 per statement Tax Document: $5.00 per document',
                  }
                : undefined
            }
            alignment='vertical'
            testId={'primary-statement-delivery'}
          />
        </Col>
      )}
      {renderIndividualRepresentations()}
    </>
  );
};
