import { Col } from 'antd';
import { MFormCheckbox } from 'src/lib';

import * as Styles from './AccountAgreements.styles';
import { AccountAgreementsList } from './AccountAgreementsList';

export const AccountAgreementsView = () => {
  return (
    <>
      <Col span={24}>
        <MFormCheckbox
          label='I agree to use Electronic Consent for agreements.'
          disabled
          defaultChecked
          checked
          testId={'account-agreement-to-use-electronic-consent'}
        />
      </Col>
      <Col span={24} className={Styles.linksContainer}>
        <AccountAgreementsList />
      </Col>
    </>
  );
};
