import { MFormTextField } from 'src/lib';
import { Account } from 'src/models';

interface JointAccountInterestViewProps {
  account: Account;
}

export const JointAccountInterestView = ({ account }: JointAccountInterestViewProps) => {
  return (
    <>
      <MFormTextField
        label={
          account.primaryAccountHolder?.firstName && account.primaryAccountHolder?.lastName
            ? `${account.primaryAccountHolder.firstName} ${account.primaryAccountHolder?.lastName}`
            : 'Primary Account Holder'
        }
        value={`${account.primaryAccountHolder?.jointTenantsInCommonInterestPercent}%`}
        testId={'primary-account-interest'}
      />
      <MFormTextField
        label={
          account.secondaryAccountHolder?.firstName && account.secondaryAccountHolder?.lastName
            ? `${account.secondaryAccountHolder.firstName} ${account.secondaryAccountHolder?.lastName}`
            : 'Joint Account Holder'
        }
        value={`${account.secondaryAccountHolder?.jointTenantsInCommonInterestPercent}%`}
        testId={'joint-account-interest'}
      />
    </>
  );
};
