import { useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import { useAccountSelector } from 'src/hooks';
import { MFormSection } from 'src/lib';

import { PhysicalAddressForm } from './PhysicalAddressForm';
import { PhysicalAddressView } from './PhysicalAddressView';

export const JointAccountHolderPhysicalAddressSection = () => {
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const { account } = useAccountSelector();

  const onEdit = () => {
    setIsEditMode(true);
  };

  const onCancel = () => {
    setIsEditMode(false);
  };

  const isPhysicalAddressAlreadySaved = () => !isEmpty(account?.secondaryAccountHolder?.physicalAddress);

  useEffect(() => {
    if (isPhysicalAddressAlreadySaved()) {
      setIsEditable(true);
    }
  }, [account?.secondaryAccountHolder?.physicalAddress]);

  if (!account) {
    return null;
  }

  if (!account.type.isJoint) {
    return null;
  }

  return (
    <MFormSection
      title='Physical Address'
      onEdit={onEdit}
      isEditable={isEditable}
      isEditMode={isEditMode}
      testId={'physical-address'}>
      {isPhysicalAddressAlreadySaved() && !isEditMode ? (
        <PhysicalAddressView accountHolder={account?.secondaryAccountHolder} testId={'joint'} />
      ) : (
        <PhysicalAddressForm
          accountHolder={account?.secondaryAccountHolder}
          accountUuid={account?.id}
          isPrimary={false}
          primaryAccountHolderAddress={account?.primaryAccountHolder?.physicalAddress}
          onCancel={onCancel}
        />
      )}
    </MFormSection>
  );
};
