import { AccountUrlConstant } from 'src/constants';
import {
  AccountDto,
  CreateAccountDto,
  DownloadAccountSignatureDto,
  DownloadAccountSignatureResponseDto,
  GetAccountDto,
  PatchAccountBeneficiariesDto,
  PatchAccountDto,
  PatchJointAccountInterestDto,
  SubmitAccountDto,
  SwitchAccountDto,
} from 'src/dtos';
import { ConfigService } from 'src/services';
import { httpRequest, replacePlaceholders, WithAuthToken } from 'src/utils';

export class AccountApi {
  private _configService: ConfigService;
  constructor(configService: ConfigService) {
    this._configService = configService;
  }

  async create({ body, authToken }: WithAuthToken<CreateAccountDto>): Promise<AccountDto> {
    return httpRequest<AccountDto>({
      method: 'POST',
      url: AccountUrlConstant.CREATE,
      body,
      authToken,
    });
  }

  async retrieve({ params, authToken }: WithAuthToken<GetAccountDto>): Promise<AccountDto> {
    const url = replacePlaceholders(AccountUrlConstant.RETRIEVE, params);

    return httpRequest<AccountDto>({
      method: 'GET',
      url,
      authToken,
    });
  }

  async patch({ params, body, authToken }: WithAuthToken<PatchAccountDto>): Promise<AccountDto> {
    const url = replacePlaceholders(AccountUrlConstant.PATCH, params);

    return httpRequest<AccountDto>({
      method: 'PATCH',
      url,
      body,
      authToken,
    });
  }

  async patchJointInterest({
    params,
    body,
    authToken,
  }: WithAuthToken<PatchJointAccountInterestDto>): Promise<AccountDto> {
    const url = replacePlaceholders(AccountUrlConstant.PATCH_JOINT_INTEREST, params);

    return httpRequest<AccountDto>({
      method: 'PATCH',
      url,
      body,
      authToken,
    });
  }

  async patchBeneficiaries({
    params,
    body,
    authToken,
  }: WithAuthToken<PatchAccountBeneficiariesDto>): Promise<AccountDto> {
    const url = replacePlaceholders(AccountUrlConstant.PATCH_BENEFICIARIES, params);

    return httpRequest<AccountDto>({
      method: 'PATCH',
      url,
      body,
      authToken,
    });
  }

  async submit({ params, body, authToken }: WithAuthToken<SubmitAccountDto>): Promise<AccountDto> {
    const url = replacePlaceholders(AccountUrlConstant.SUBMIT, params);

    return httpRequest<AccountDto>({
      method: 'PUT',
      url,
      body,
      authToken,
    });
  }

  async switch({ params, authToken }: WithAuthToken<SwitchAccountDto>): Promise<AccountDto> {
    const url = replacePlaceholders(AccountUrlConstant.SWITCH, params);

    return httpRequest<AccountDto>({
      method: 'PUT',
      url,
      authToken,
    });
  }

  async downloadSignature({
    params,
    authToken,
  }: WithAuthToken<DownloadAccountSignatureDto>): Promise<DownloadAccountSignatureResponseDto> {
    const url = replacePlaceholders(AccountUrlConstant.DOWNLOAD_SIGNATURE, params);

    return httpRequest<DownloadAccountSignatureResponseDto>({
      method: 'GET',
      url,
      authToken,
    });
  }

  async downloadAgreement(id: string, authToken: string | null): Promise<Blob> {
    const url = replacePlaceholders(AccountUrlConstant.DOWNLOAD_AGREEMENT, { id });

    return httpRequest<Blob>({
      method: 'GET',
      url,
      authToken,
      responseType: 'blob',
    });
  }

  async syncStatusList(ids?: string[]): Promise<void> {
    return httpRequest<void>({
      method: 'PUT',
      url: AccountUrlConstant.SYNC_STATUS_LIST,
      authToken: this._configService.authToken,
      queryParams: { ids },
    });
  }
}
