import { AccountHolderDto, AccountStatusDto, AddressDto } from './accountHolders.dtos';
import { PhoneNumberDto } from './common.dtos';

export enum AccountTypeDto {
  Individual = 'individual',
  Joint = 'joint',
  Retirement = 'retirement',
}

export enum JointAccountTypeDto {
  Community = 'Community',
  Survivor = 'Survivor',
  TenantsInCommon = 'TenantsInCommon',
  TenantsInEntirety = 'TenantsInEntirety',
}

export enum RetirementAccountTypeDto {
  Traditional = 'Traditional',
  Simple = 'Simple',
  SEP = 'SEP',
  Roth = 'Roth',
  Rollover = 'Rollover',
  RothBeneficiary = 'RothBeneficiary',
  CoverDell = 'CoverDell',
  Beneficiary = 'Beneficiary',
}

export enum BeneficiaryTypeDto {
  Individual = 'Individual',
  Trust = 'Trust',
}

export enum BeneficiaryAccountTypeDto {
  Primary = 'Primary',
  Contingent = 'Contingent',
}

export enum BeneficiraryRelationshipDto {
  Spouse = 'Spouse',
  Child = 'Child',
  Parent = 'Parent',
  Other = 'Other',
}

export interface AccountBeneficiaryDto {
  firstName: string;
  middleName?: string;
  lastName: string;
  suffix?: string;
  email: string;
  phones: Array<PhoneNumberDto>;
  dateOfBirth: string;
  ssid: string;
  maskedSsid: string;
  percent: number;
  address: AddressDto;
  relationship: BeneficiraryRelationshipDto;
  beneficiaryAccountType: BeneficiaryAccountTypeDto;
  beneficiaryType: BeneficiaryTypeDto;
}

export interface AccountDto {
  id: string;
  accountId?: number;
  accountNumber?: string;
  status?: AccountStatusDto;
  type: AccountTypeDto;
  primaryAccountHolder?: AccountHolderDto;
  secondaryAccountHolder?: AccountHolderDto;
  jointAccountType?: JointAccountTypeDto;
  jointCommunityPropertyState?: string;
  retirementAccountType?: RetirementAccountTypeDto;
  simpleIraPlanName?: string;
  beneficiaryIraDecedentName?: string;
  beneficiaries?: Array<AccountBeneficiaryDto>;
}

export interface BaseAccountParamsDto {
  id: string;
}

export interface CreateAccountBodyDto {
  type: AccountTypeDto;
  jointAccountType?: JointAccountTypeDto;
  jointCommunityPropertyState?: string;
  retirementAccountType?: RetirementAccountTypeDto;
  simpleIraPlanName?: string;
  beneficiaryIraDecedentName?: string;
}

export interface CreateAccountDto {
  body: CreateAccountBodyDto;
}

export interface GetAccountDto {
  params: BaseAccountParamsDto;
}

export interface PatchAccountBodyDto extends CreateAccountBodyDto {}

export interface PatchAccountDto {
  params: BaseAccountParamsDto;
  body: PatchAccountBodyDto;
}

export interface PatchJointAccountInterestBodyDto {
  primaryAccountHolder: {
    percent: number;
  };
  secondaryAccountHolder: {
    percent: number;
  };
}

export interface PatchJointAccountInterestDto {
  params: BaseAccountParamsDto;
  body: PatchJointAccountInterestBodyDto;
}

export interface BaseAccountBeneficiaryDto extends Omit<AccountBeneficiaryDto, 'maskedSsid'> {}

export type PatchAccountBeneficiariesBodyDto = BaseAccountBeneficiaryDto[];

export interface PatchAccountBeneficiariesDto {
  params: BaseAccountParamsDto;
  body: PatchAccountBeneficiariesBodyDto;
}

export interface SubmitAccountBodyDto {
  signature: {
    primaryAccountHolder: string;
    secondaryAccountHolder?: string;
  };
}

export interface SubmitAccountDto {
  params: BaseAccountParamsDto;
  body: SubmitAccountBodyDto;
}

export interface SwitchAccountDto {
  params: BaseAccountParamsDto;
}

export interface DownloadAccountSignatureResponseDto {
  primaryAccountHolder?: string;
  secondaryAccountHolder?: string;
}

export interface DownloadAccountSignatureDto {
  params: BaseAccountParamsDto;
}
