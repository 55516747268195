import { useAccountSelector } from 'src/hooks';
import { Account, JointAccountType, RetirementAccountType } from 'src/models';
import { Maybe } from 'src/utils';

import * as Styles from './AccountAgreements.styles';

const accountAgreementLinks = [
  {
    label: 'Customer Agreement',
    url: 'https://legacy.tradingblock.com/Docs/Agreements/Customer_Agreement_v5.6_20200424.pdf',
  },
  {
    label: 'Electronic Consent Agreement',
    url: 'https://legacy.tradingblock.com/Docs/Agreements/Electronic_Consent_Agreement_v1.3_20140713.pdf',
  },
  {
    label: 'Online Services Agreement',
    url: 'https://legacy.tradingblock.com/Docs/Agreements/Online_Services_Agreement_v1.4_20140713.pdf',
  },
  {
    label: 'AOS Form CRS',
    url: 'https://legacy.tradingblock.com/Docs/Agreements/tb/AOS_MyIPO_Form_CRS.pdff',
  },
  {
    label: 'Privacy Policy',
    url: '/static/docs/Privacy_Policy_Revision_2025.pdf',
  },
  {
    label: 'Business Continuity Plan',
    url: 'https://legacy.tradingblock.com/Docs/Agreements/tb/MyIPO_Business_Continuity_Plan_Disclosure.pdf',
  },
  {
    label: 'Anti-money Laundering Policy',
    url: 'https://legacy.tradingblock.com/Docs/Agreements/Anti%20Money%20Laundering%20Policy_v1_20090130.pdf',
  },
  {
    label: 'Regulation Best Interest (Reg BI) - An Overview',
    url: 'https://legacy.tradingblock.com/Docs/Agreements/RegBI_Overview.pdf',
  },
];

const getJointAccountAgreement = (jointAccountType?: JointAccountType): Maybe<{ label: string; url: string }> => {
  if (jointAccountType?.isCommunity) {
    return {
      label: 'Community Property Agreement',
      url: 'https://legacy.tradingblock.com/Docs/Agreements/Joint_Agreement_Community%20Property_20180719.pdf',
    };
  }

  if (jointAccountType?.isSurvivor) {
    return {
      label: 'Rights of Survivorship Agreement',
      url: 'https://legacy.tradingblock.com/Docs/Agreements/Joint_Agreement_Rights_of_Survivorship_20180719.pdf',
    };
  }

  if (jointAccountType?.isTenantsInCommon) {
    return {
      label: 'Tenants in Common Agreement',
      url: 'https://legacy.tradingblock.com/Docs/Agreements/Joint_Agreement_Tenants_in_Common_20180719.pdf',
    };
  }

  if (jointAccountType?.isTenantsInEntirety) {
    return {
      label: 'Tenants by Entirety Agreement',
      url: 'https://legacy.tradingblock.com/Docs/Agreements/Joint_Agreement_Tenants_by_Entirety_20180719.pdf',
    };
  }

  return;
};

const getRetirementAccountAgreement = (
  retirementAccountType?: RetirementAccountType,
): Maybe<{ label: string; url: string }> => {
  if (retirementAccountType?.isSimple) {
    return {
      label: 'Simple IRA Agreement',
      url: 'https://legacy.tradingblock.com/Docs/Agreements/Simple_IRA_Custodial_Agreement_20191217.pdf',
    };
  }

  if (retirementAccountType?.isRoth || retirementAccountType?.isRothBeneficiary) {
    return {
      label: 'Roth IRA Agreement',
      url: 'https://legacy.tradingblock.com/Docs/Agreements/Roth_IRA_Custodial_Agreement_20191217.pdf',
    };
  }

  return {
    label: 'IRA Agreement',
    url: 'https://legacy.tradingblock.com/Docs/Agreements/IRA_Custodial_Agreement_20191217.pdf',
  };
};

const getAgreementByType = (account?: Account): Maybe<{ label: string; url: string }> => {
  if (account?.type.isJoint) {
    return getJointAccountAgreement(account?.jointAccountType);
  }

  if (account?.type.isRetirement) {
    return getRetirementAccountAgreement(account?.retirementAccountType);
  }

  return;
};

export const AccountAgreementsList = () => {
  const { account } = useAccountSelector();

  const typeAgreement = getAgreementByType(account);

  const allAgreements = (): { label: string; url: string }[] => {
    if (typeAgreement) {
      return [typeAgreement].concat(accountAgreementLinks);
    }

    return accountAgreementLinks;
  };

  return (
    <>
      {allAgreements().map(agreement => (
        <div key={`${agreement.label}_key`} className={Styles.agreementLink}>
          <i className='ri-download-2-fill' />
          <a className={Styles.link} href={agreement.url} target='_blank' rel='noreferrer'>
            {agreement.label}
          </a>
        </div>
      ))}
    </>
  );
};
